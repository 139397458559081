import { Component, OnInit } from '@angular/core'
import { RepositorioService } from 'src/app/service/repositorio-service'
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service'
import { AlertConfirmService } from 'src/app/service/alert-confirm.service'
import { GrupoDeServico } from 'src/app/domain/engenharia/composicao/grupoDeServico'

@Component({
  selector: 'app-grupo-servico',
  templateUrl: './grupo-servico.component.html',
  styleUrls: ['./grupo-servico.component.css']
})
export class GrupoServicoComponent implements OnInit {

  _code = 'app-grupo-servico'
  model = new GrupoDeServico()
  subModel = new GrupoDeServico()
  listModel = new Array<GrupoDeServico>()
  subListModel = new Array<GrupoDeServico>()
  _subBusca = ''
  _buscar = ''

  constructor(private context: RepositorioService, public userLoggedService: UserLoggedInService, private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser()
    this.context.path = 'grupo-de-servico'
  }

  ngOnInit() {
    this.GetAll();
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }

  trClick(model: GrupoDeServico) {
    this.model = model
    this._subBusca = ''
    this._buscar = ''
    this.listModel.forEach(c => c._selected = false)
    this.GetAll(model.id)
    model._selected = true
  }

  GetAll(id = 0) {    
    this.subListModel = new Array<GrupoDeServico>()
    if(!id) 
      this._buscar = ''
    this._subBusca = ''    
    this.context.getAll('hierarquia=' + id).subscribe(
      d => {         
        (id) ? this.subListModel = d : this.listModel = d 
      },
      e => { },
      () => { })
  }

  Insert(buscar, id = 0) {
    var model = new GrupoDeServico()
    model.descricao = buscar
    model.hierarquia = id
    model.status = 1
    if (id) {
      if (this.subListModel.length > 0) {
        var ultimoCodigo = this.subListModel[this.subListModel.length - 1].codigo
        model.codigo = this.model.codigo + '.' + String(parseInt(ultimoCodigo.split('.')[1]) + 1);
      } else
        model.codigo = this.model.codigo + '.1'
    } else if (this.listModel.length > 0)
      model.codigo = String(parseInt(this.listModel[this.listModel.length - 1].codigo) + 1)
    else
      model.codigo = '1'

    this.InsertOrUpdate(model)
  }

  InsertOrUpdate(item: GrupoDeServico) {
    setTimeout(() => {
      var data = GrupoDeServico.valida(item)
      if (data.id)
        this.context.put(data).subscribe()
      else
        this.context.post(data).subscribe(d => { if(d){ this.GetAll(item.hierarquia) } })
    }, 1)
  }
  
  confirmDelete(model: GrupoDeServico): void {
    this.subModel = model
    this.confirmService._code = this._code
    this.confirmService._title = "Excluir Item"
    this.confirmService._message = "Deseja excluir o item <b>" + model.descricao + "</b>?"
    this.confirmService.Show()
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return
    this.context.delete(this.subModel.id).subscribe(
      d => { },
      e => { },
      () => { this.GetAll(this.subModel.hierarquia) }
    )
  }
}

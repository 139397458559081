export const GlobalVariable= Object.freeze({
  //BASE_API_URL: "http://api.paineldaengenharia.com/", http://192.168.0.10:3000/", 
  BASE_API_URL:  (window.location.hostname == "localhost")? "http://localhost:3000/"
  :"https://apiteste.paineldaengenharia.com/",
  HOST: (window.location.hostname == "localhost")? "http://localhost:4200/"
  :"https://apigestao.paineldaengenharia.com/",
  BASE_LOGIN: (window.location.hostname == "localhost"),
  TIME_RENEW_ACCESS: 30
});


import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { UserService } from 'src/app/service/user/user.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { PerfilUsuarioService } from 'src/app/service/configuracao/perfil-usuario.service';
declare var $: any;
@Component({
  selector: 'app-usuarios-empresa',
  templateUrl: './usuarios-empresa.component.html',
  styleUrls: ['./usuarios-empresa.component.css']
})
export class UsuariosEmpresaComponent implements OnInit {

  @Output() resposta_usuario: EventEmitter<Usuario> = new EventEmitter();
  @Output() resposta_usuario_permissao: EventEmitter<boolean> = new EventEmitter();
  private _code = "app-usuarios-empresa";
  _buscar = '';
  public usuarioSelecionado: Usuario = new Usuario();
  public usuarios: Usuario[];
  public usuario: Usuario;
  public usuario_id_selecionado: number;

  constructor(private userService: UserService, private confirmService: AlertConfirmService, private perfilUsuarioService: PerfilUsuarioService) {

   }

  ngOnInit() {
    this.GetUsersCNPJ();
    this.confirmService.confirmEmitter$.subscribe(c => this.DeleteUser(c));
  }

  GetUsersCNPJ(): void{
    this.userService.getAllCNPJ().subscribe(
      d => { this.usuarios = d; },
      e => {},
      () => { this.SetFunctions(); }
    );
  }

  EditPermissionUser(e, usuario: Usuario): void{
    e.preventDefault();
    this.perfilUsuarioService.EmitirEvento(usuario);
    this.resposta_usuario_permissao.emit(true);
  }

  EditUser(e, usuario: Usuario): void{
    e.preventDefault();
    usuario._load = true;
    this.usuario_id_selecionado = usuario.id;
    var cpf = usuario._cpf;
    if(!cpf.match("-"))
      usuario.cpf = cpf.substr(0,3)+"."+cpf.substr(3,3)+"."+cpf.substr(6,3)+"-"+cpf.substr(9,2);
    this.resposta_usuario.emit(usuario);
    this.resposta_usuario_permissao.emit(false);
  }

  DeleteUser(confirm: boolean){
    if(this.confirmService._code != this._code)
      return;
    this.userService.SetDeleteUserProvider(this.usuario).subscribe(
      d => { this.GetUsersCNPJ(); },
      e => { },
      () => {}
    );
  }

  ClickDeleteUser(e, usuario: Usuario): void{
    e.preventDefault(); 
    this.usuario = usuario;
    this.confirmService._code = this._code;
    this.confirmService._title = "Exclusão de Usuário";
    this.confirmService._message = "Deseja excluir o usuário, "+usuario.nome+"?";
    this.confirmService.Show(); 
  }

  
  SetFunctions() {
    setTimeout(() => {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      })
    }, 500);
  }
}

import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Profissional } from 'src/app/domain/administracao/profissional';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any

@Component({
  selector: 'app-profissional',
  templateUrl: './profissional.component.html',
  styleUrls: ['./profissional.component.css']
})
export class ProfissionalComponent implements OnInit {
  _msgParams = { titulo: '', descricao: '' }
  _code = 'app-profissional'
  listModel = new Array<Profissional>()
  model = new Profissional()

  constructor(private context: RepositorioService, private router: Router, private route: ActivatedRoute, public userLoggedService: UserLoggedInService, private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser();
    this.context.path = 'profissional'
  }

  ngOnInit() {
    this.get();
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }

  get() {
    this.context.getAll().subscribe(d => {
      this.listModel = d
      Profissional.set(this.listModel)
    })
  }

  gerenciar(model: any) { this.router.navigate(['/profissional/form/', model.id]) }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code) return;
    this.context.delete(this.model.id).subscribe(d => this.listModel.splice(this.model._index - 1, 1));
  }

  confirmDelete(model: Profissional): void {
    this.model = model;
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Profissional";
    this.confirmService._message = "Deseja excluir profissional: <b>" + model.nome + "</b>?";
    this.confirmService.Show();
  }
}
import { Component, OnInit, Input } from '@angular/core';
import { RelacaoComposicao, EnumItemTipo } from 'src/app/domain/engenharia/composicao/relacaoComposicao';
import { UseFullService } from 'src/app/service/usefull/usefull.service';


declare var $: any
@Component({
  selector: 'app-view-item-detailed',
  templateUrl: './view-item-detailed.component.html',
  styleUrls: ['./view-item-detailed.component.css']
})
export class ViewItemDetailedComponent implements OnInit {

  @Input() _viewItemDetailed: []
  listItem = []
  tableHtml = String()
  teste = 1

  space = 0
  constructor() {

  }

  ngOnInit() {
    setTimeout(() => {      
      this.listItem = this._viewItemDetailed
      this.setTable(this.listItem)
    }, 1);
  }

  setTable(list: any[]) {

    let html = '<table class="table table-hover table-striped">' +
      '<tr class="thead-light">' +
      '<th class="text-center collumn-codigo">Código</th>' +
      '<th class="text-center  collumn-codigo"></th>' +
      '<th class="">Descrição </th>' +
      '<th class="text-center  collumn-unidade">Unidade</th>' +
      '<th class="text-center  collumn-quantidade">Coeficiente</th>' +
      '<th class="text-center  collumn-valor">Valor</th>' +
      '<th class="text-center  collumn-valor">Valor Total</th>' +
      '</tr>' +
      '<tbody>'

    html += this.setRowTable(list, false)

    html += '</tbody>' +
      '</table>'

    this.tableHtml = html
  }

  setRowTable(list: any[], innerHtml = true): string {
    let html = String()
    let x = 0
    list.forEach(e => {
      let item = String()
      let itemSpace = this.space
      if (e._relacaoComposicao) {
        item = RelacaoComposicao.getTipoItemName(EnumItemTipo.composicao)
      } else {
        if (!x)
          itemSpace = this.space + 20
        item = RelacaoComposicao.getTipoItemName(e.itemTipo)
      }

      const valorTotal = parseFloat(e.quantidade) * parseFloat(e._valor)
      if(innerHtml){
      html += '<tr class="type-row-' + UseFullService.removeAccent(item) + '">' +
        '<td class="text-center collumn-codigo">' + e.codigo + '</td>' +
        '<td class="text-left padding-left-' + itemSpace + ' collumn-item">' + item + '</td>' +
        '<td class="">' + e.descricao + '</td>' +
        '<td class="text-center collumn-unidade">' + e._unidadeMedida + '</td>' +
        '<td class="text-center collumn-quantidade">' + UseFullService.ToFixed(e.quantidade) + '</td>' +
        '<td class="collumn-valor text-right">R$ ' + UseFullService.ToFixed(e._valor) + '</td>' +
        '<td class="collumn-valor text-right">R$ ' + UseFullService.ToFixed(valorTotal) + '</td>' +
        '</tr>'
      }
      if (e._relacaoComposicao && e._relacaoComposicao.length > 0) {
        html += this.setRowTable(e._relacaoComposicao)
      } else if (e.itemTipo && e.itemTipo === 3) {
        if (!x)
          this.space = this.space + 20
        html += this.setRowTable(e._composicao._relacaoComposicao)

        x++
      }

    });

    return html
  }

}

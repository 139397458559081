import { Component } from '@angular/core';
import { DiarioObraFormComponent } from './diario-obra-form.component';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var html2pdf: any;
declare var $: any
@Component({
  selector: 'app-diario-obra-view',
  templateUrl: './diario-obra-view.component.html',
  styleUrls: ['./diario-obra-view.component.css']
})
export class DiarioObraViewComponent extends DiarioObraFormComponent {

  downloadPDF(){
    window.open(GlobalVariable.BASE_API_URL + 'relatorio?url=diario-obra/relatorio&footer=1&bottom=30mm&id='+this._model.id+'&token='+btoa(localStorage.getItem('currentUser')), '_blank');
  }


  public downloadPDsF() {
    var element = document.getElementById('containers')
    var opt = {
      margin: 5,
      filename: 'Orcamentaria.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    setTimeout(() => {
      html2pdf(element).set(opt).save();
    }, 100);

  }

  /*
  var reader = new FileReader();
  reader.onload = function (e) {
    var target = e.target as any;
    $("#image-logo").attr("src", target.result);
    $("#image-logo").show();
  };
  reader.readAsDataURL(input.files[0]);
*/

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  setImage() {      
    this._model.listImage.forEach(c => {
      const src = this.host + 'diario-obra/imagem/file?diarioObraId=' + c.diarioObraId + '&nome=' + c.nome
      this.toDataURL(src, function (dataUrl) {
        c._small = dataUrl
      })
    })
  }


}

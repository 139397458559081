import { Component } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { PlanilhaOrcamentariaFormComponent } from '../../engenharia/planilha-orcamentaria/planilha-orcamentaria-form/planilha-orcamentaria-form.component';
import { EnumItemTipo } from 'src/app/domain/engenharia/composicao/relacaoComposicao';
import { ExcelOrcamentoAnalitico } from './excel/ExcelOrcamentoAnalitico';
import { RelacaoPlanilhaOrcamentariaItem } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';

@Component({
  selector: 'app-relatorio-orcamento-analitico',
  templateUrl: './relatorio-orcamento-analitico.component.html',
  styleUrls: ['./relatorio-curva-abc-insumo.component.css']
})
export class RelatorioOrcamentoAnaliticoComponent extends PlanilhaOrcamentariaFormComponent {

  list = new Array<any>()
  _planilha = new PlanilhaOrcamentaria()
  _obra = new Obra()


  ngOnInit() {
    //this.user.SetCurrentUser()   
    this.getUnidadesDeMedida()
    this.getPlanilha()
  }

  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => {
      this.unidadesDeMedida = d;
      this.getAllItens()
    })
  }

  getAllItens() {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.getAll("planilhaOrcamentariaId=" + this.id).subscribe(d => {
      this.listRelacaoPlanilha = d;
      this.atualizarPlanilha();
      this.getDataAnalitico()
    })
  }

  getPlanilha() {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(this.id).subscribe(d => {
      this._planilha = d;
      this.getObraPlanlinha(this._planilha.obraId)
    })
  }

  dataRel = ''
  getObraPlanlinha(id) {
    this.context.path = 'obra'
    this.context.get(id).subscribe(d => {
      this._obra = d;
      this._obra._dataInicio = UseFullService.formatDate(new Date(this._obra.dataInicio))
      this._obra._dataFim = UseFullService.formatDate(new Date(this._obra.dataFim))
      this.dataRel = UseFullService.formatDate(new Date())
    })
  }

  getDataAnalitico() {
    const list = this.listRelacaoPlanilha.filter(c => { return (c.itemTipo === EnumItemTipo.composicao) })
    for (let i = list.length - 1; i >= 0; i--) {
      this.getDetalhesComposicao(list[i], i)
    }
  }

  total = '0,00'
  totalBDI = '0,00'  
  getDetalhesComposicao(c: RelacaoPlanilhaOrcamentariaItem, totalRow) {
    this.context.path = 'planilha-orcamentaria/relacao/all/composicao'
    this.context.get(c.id).subscribe(d => {
      c._detalheComposicao = d
      if (totalRow === 0) {
        const data = new ExcelOrcamentoAnalitico()
        this.list = data.getList(this.listRelacaoPlanilha)
        for (let i = 0; i < this.list.length; i++) {
          const c = this.list[i];
          if (c[1] === 3 && c[10] === 'itemDeComposicao')
            c[1] = 'composição aux. ' + c[0]
          else
            c[1] = (c[1] === 1) ? 'insumo' : (c[1] === 2) ? 'mão de obra' : (c[1] === 3) ? 'composição' : ''

          if (c.slice(-1)[0] === 'principal'){
            this.list.splice(i, 0, [0])
            i++
          }
        }
        this.total = UseFullService.ToFixedString(data.total)
        this.totalBDI = UseFullService.ToFixedString(data.totalBDI)
      }
    })
  }
}

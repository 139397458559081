import { Component, OnInit } from '@angular/core';
import { RelacaoComposicao, EnumItemTipo, ValorItemComposicaoEstado, EnumStatusValorEstado } from 'src/app/domain/engenharia/composicao/relacaoComposicao';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ResizedEvent } from 'angular-resize-event';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { Composicao, ValorComposicaoEstado } from 'src/app/domain/engenharia/composicao/composicao';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { GrupoDeServico } from 'src/app/domain/engenharia/composicao/grupoDeServico';
import { Estado } from 'src/app/domain/enum/estado';
import { table } from 'src/app/domain/common/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MaoDeObra } from 'src/app/domain/engenharia/composicao/MaoDeObra';
import { Insumo } from 'src/app/domain/engenharia/composicao/Insumo';
import { ValorInsumoEstado } from 'src/app/domain/engenharia/composicao/valorInsumoEstado';


declare var $: any
@Component({
  selector: 'app-composicao-form',
  templateUrl: './composicao-form.component.html',
  styleUrls: ['./composicao-form.component.css']
})
export class ComposicaoFormComponent implements OnInit {

  _code = 'app-relacao-composicao-form'
  _estado = ''
  listRelacaoComposicao = new Array<RelacaoComposicao>()
  relacaoComposicao = new RelacaoComposicao();
  model = new Composicao()
  unidadesDeMedida = new Array<UnidadeMedida>()
  listGrupoServico = new Array<GrupoDeServico>()
  listSubGrupoServico = new Array<GrupoDeServico>()
  listEstado = new Array<Estado>()
  id = this.route.snapshot.params['id'];

  constructor(private router: Router, private route: ActivatedRoute, private context: RepositorioService, public userLoggedService: UserLoggedInService, private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this._estado = this.userLoggedService.uf
    if (this.id)
      this.get()
    this.getUnidadesDeMedida()
    this.grupoServico()
    this.getEstados()
    UseFullService.StartTooltip()
    UseFullService.StartTooltip()
    this.onResize(this)
    
    this.confirmService.confirmEmitter$.subscribe(c => { this.delete(c) })
  }

  get() {
    this.context.path = 'composicao'
    this.context.get(this.id).subscribe(d => {
      this.model = d;
      this.model._valorComposicaoEstado = new ValorComposicaoEstado();
      this.getValorTotal()
      this.onResize(event)
      this.subGrupoServico()
    })
  }

  getValorTotal() {
    this.context.path = 'composicao-valor'
    this.context.get(this.model.id, this._estado).subscribe(
      d => {
        this.model._valorComposicaoEstado = (d) ? d : new ValorComposicaoEstado();
      }, e => { }, () => {
        this.getAllRelacoes(this.model.id)
      })
  }

  getAllRelacoes(id) {
    this.context.path = 'relacao-composicao'
    this.context.getAll("composicaoId=" + id).subscribe(d => {
      this.listRelacaoComposicao = d;
      this.carregarPlanilha();
      if (this.listRelacaoComposicao.length > 1)
        this.getAllValores(this._estado);
    })
  }

  getAllValores(estado = this._estado) {
    this.context.path = 'relacao-composicao-valor'
    const params = { uf: estado, ids: [] }
    this.listRelacaoComposicao.forEach(c => {
      if (c.id)
        params.ids.push(c.id)
    })
    setTimeout(() => {
      this.context.getAll(JSON.stringify(params)).subscribe(
        async d => {
          if (d && d.length > 0) {
            await RelacaoComposicao.recalcularRelacaoComposicao(this.listRelacaoComposicao, d)
            const valorComposicaoEstado = await ValorComposicaoEstado.getValorTotal(this.listRelacaoComposicao)
            await this.VerificaValorComposicao(valorComposicaoEstado)
          }
        })
    }, 50);
  }

  changeValor(item: RelacaoComposicao) {
    if (item.itemTipo != EnumItemTipo.composicao) {
      this.tempItemRelacaoComposicao = item
      this.confirmeAddValor()
    }
  }

  tempItemRelacaoComposicao = new RelacaoComposicao();
  addValor(item: RelacaoComposicao) {
    if (item.itemTipo != EnumItemTipo.composicao) {
      this.tempItemRelacaoComposicao = item
      UseFullService.startButtonPopOver(true)
      $('[data-toggle="popover"].valor-item-' + item.id).popover('show')
      const _this = this
      setTimeout(() => {
        $('.popover a').click(function (e) {
          e.preventDefault();
          _this.tempItemRelacaoComposicao._paramsEstado.status = $(e.target).attr('href')
          _this.confirmeAddValor()
        })
      }, 500);
    }
  }

  confirmeAddValor() {
    const item = this.tempItemRelacaoComposicao
    this.context.path = 'relacao-composicao-valor'
    setTimeout(() => {
      if (item.id)
        this.context.put(item).subscribe(d => { this.getAllValores(this._estado) })
      else
        this.context.post(item).subscribe(d => {
          if (d) { this.getAllValores(this._estado) }
        })
      UseFullService.startButtonPopOver(false)
    }, 1)
  }

  VerificaValorComposicao(valorComposicaoEstado: ValorComposicaoEstado) {
    const valorIgual = UseFullService.DecimalEquals(this.model._valorComposicaoEstado.valor, valorComposicaoEstado.valor)
    if (!valorIgual || valorComposicaoEstado.estado != this.model._valorComposicaoEstado.estado) {
      if (this.model._valorComposicaoEstado.id && valorComposicaoEstado.estado == this.model._valorComposicaoEstado.estado) {
        this.model._valorComposicaoEstado.valor = valorComposicaoEstado.valor
        this.insertValorComposicao(this.model._valorComposicaoEstado)
      } else if (valorComposicaoEstado.estado != this.model._valorComposicaoEstado.estado) {
        this.context.path = 'composicao-valor'
        this.context.get(this.model.id, this._estado).subscribe(
          d => {
            this.model._valorComposicaoEstado = (d) ? d : new ValorComposicaoEstado();
            if (d)
              this.VerificaValorComposicao(valorComposicaoEstado)
            else
              this.insertValorComposicao(valorComposicaoEstado)
          })
      } else
        this.insertValorComposicao(valorComposicaoEstado)
    }
    this.model._valorComposicaoEstado._valor = UseFullService.ToFixed(valorComposicaoEstado.valor)
  }

  insertValorComposicao(item: ValorComposicaoEstado) {
    this.context.path = 'composicao-valor'
    setTimeout(() => {
      if (item.id)
        this.context.put(item).subscribe()
      else
        this.context.post(item).subscribe(d => {
          if (d) {
            this.model._valorComposicaoEstado = d
            this.model._valorComposicaoEstado._valor = UseFullService.ToFixed(this.model._valorComposicaoEstado.valor)
          }
        })
    }, 1)
  }

  onResize(event) {
    setTimeout(() => {
      $("th.descricao").width($("td.descricao").width() - 12)
    }, 50);
  }

  onChangeEstado() {
    this.userLoggedService.setUf(this._estado)
    this.getAllValores(this._estado)
  }

  trClick(data: RelacaoComposicao) {
    this.listRelacaoComposicao.forEach(c => {
      if (data == c) {
        c._selected = true
        c._active_buscar = c._active_buscar
        //$('input#descricao-' + c._index).focus()   
      } else {
        c._selected = false
        c._active_buscar = false
      }
    })
  }

  mouseLeave(data: RelacaoComposicao) {
    data._selected_hover = false
    UseFullService.StartTooltip()
  }

  carregarPlanilha() {
    const relacaoComposicao = new RelacaoComposicao()
    relacaoComposicao.itemTipo = EnumItemTipo.insumo
    relacaoComposicao.setTextInputItemTipo()
    this.listRelacaoComposicao.push(relacaoComposicao)
    var x = 1
    this.listRelacaoComposicao.forEach(c => {
      if (!c._paramsEstado)
        c._paramsEstado = new ValorItemComposicaoEstado()
      c._index = x++
      c._unidadeMedida = UnidadeMedida.getName(this.unidadesDeMedida, c.unidadeMedidaId)
      c._itemTipo = RelacaoComposicao.getTipoItemName(c.itemTipo)
    })
  }

  onChange(data: RelacaoComposicao) {
    setTimeout(() => {
      if (!data.id && data.descricao.length >= 2) {
        if (!data.itemTipo)
          data.itemTipo = 1
        data._active_buscar = true
        this.buscarItem(data)
      } else if (data.id) {
        this.insertRelacaoComposicao(data)
      }
    }, 1)
  }

  onChangeQuantidade(data: RelacaoComposicao) {
    setTimeout(() => {
      this.insertRelacaoComposicao(data)
    }, 1)
  }

  buscarItem(data: RelacaoComposicao) {
    if (data.itemTipo == EnumItemTipo.insumo)
      this.getAllInsumo(data.descricao)
    else if (data.itemTipo == EnumItemTipo.maodeobra)
      this.getAllMaoDeObra(data.descricao)
    else if (data.itemTipo == EnumItemTipo.composicao)
      this.getAllComposicao(data.descricao)
    data._selected_hover = false
  }

  SelectItemInsert(data: RelacaoComposicao, itemTipo: EnumItemTipo) {
    $('input#descricao-' + data._index).focus()
    data.itemTipo = itemTipo
    data.setTextInputItemTipo()
    this.buscarItem(data)
  }

  /***** enuns ******/
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => { this.unidadesDeMedida = d })
  }

  grupoServico() {
    this.context.path = 'grupo-de-servico'
    this.context.getAll("hierarquia=0").subscribe(d => {
      this.listGrupoServico = d
    })
  }

  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => { this.listEstado = d })
  }

  subGrupoServico() {
    this.context.path = 'grupo-de-servico'
    this.context.getAll("hierarquia=" + this.model.grupoId).subscribe(d => { this.listSubGrupoServico = d })
  }

  onChangeGrupo() {
    this.subGrupoServico()
  }

  novaComposicao() {
    this.router.navigateByUrl("composicao/form");
  }

  insert() {
    var data = Composicao.valida(this.model)
    this.insertOrUpdate(data)
  }

  insertOrUpdate(item: Composicao) {
    this.context.path = 'composicao'
    setTimeout(() => {
      if (item.id)
        this.context.put(item).subscribe()
      else
        this.context.post(item).subscribe(d => {
          if (d) {
            this.router.navigateByUrl("composicao/form/" + d.id)
          }
        })
    }, 1)
  }

  insertRelacaoComposicao(data) {
    var tempData = RelacaoComposicao.valida(data)
    this.insertOrUpdateRelacaoComposicao(tempData)
  }

  insertOrUpdateRelacaoComposicao(item: RelacaoComposicao) {
    this.context.path = 'relacao-composicao'
    setTimeout(() => {
      if (item.id)
        this.context.put(item).subscribe(d => { this.getAllValores(this._estado) })
      else
        this.context.post(item).subscribe(d => { if (d) { if (d) item.id = d.id; this.getAllValores(this._estado); } })
    }, 1)
  }

  confirmDelete(model: RelacaoComposicao): void {
    this.relacaoComposicao = model
    this.confirmService._code = this._code
    this.confirmService._title = "Excluir Item"
    this.confirmService._message = "Deseja excluir o item <b>" + model.descricao + "</b>?"
    this.confirmService.Show()
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return
    this.context.path = 'relacao-composicao'
    this.context.delete(this.relacaoComposicao.id).subscribe(
      d => { },
      e => { },
      () => { this.getAllRelacoes(this.model.id) }
    )
  }


  /*** Insumo ***/
  listInsumo = new Array<Insumo>()
  getAllInsumo(busca: string) {
    this.context.path = 'insumo'
    var like = " and descricao LIKE '%" + busca + "%'"
    this.context.getAll('empresaId=' + this.userLoggedService.id + like).subscribe(
      d => { this.listInsumo = d },
      e => { },
      () => {
        UnidadeMedida.setModel(this.listInsumo, this.unidadesDeMedida)
        this.getValorPorEstado(this.listInsumo)
      })
  }

  /*** mão de obra ***/
  listMaoDeObra = new Array<MaoDeObra>()
  getAllMaoDeObra(busca: string) {
    this.context.path = 'maodeobra'
    var like = " and descricao LIKE '%" + busca + "%'"
    this.context.getAll('empresaId=' + this.userLoggedService.id + like).subscribe(
      d => { this.listMaoDeObra = d },
      e => { },
      () => {
        UnidadeMedida.setModel(this.listMaoDeObra, this.unidadesDeMedida)
        this.getValorPorEstado(this.listMaoDeObra, 'maodeobra/estado/valor', 'maoDeObraId')
      })
  }

  /*** Composicao ***/
  listComposicao = new Array<Composicao>()
  getAllComposicao(busca: string) {
    this.context.path = 'composicao'
    const like = { descricao: busca, codigo: busca }
    this.context.getAll(JSON.stringify(like)).subscribe(
      d => { this.listComposicao = d },
      e => { },
      () => {
        UnidadeMedida.setModel(this.listComposicao, this.unidadesDeMedida)
        this.getValorComposicaoPorEstado(this.listComposicao)
      })
  }

  getValorComposicaoPorEstado(list: any[]) {
    this.context.path = 'composicao-valor'
    list.forEach(async c => {
      if (c.id > 0) {
        this.context.get(c.id, this._estado).subscribe(
          d => { c._preco = (d) ? UseFullService.ToFixed(d.valor) : '0,00'; }
        );
      }
    })
  }

  getValorPorEstado(list: any[], path = 'insumo/estado/valor', column = 'insumoId') {
    this.context.path = path
    list.forEach(async c => {
      if (c.id > 0) {
        this.context.getAll(column + "=" + c.id + " and estado='" + this._estado + "'").subscribe(
          d => { c._preco = (d && d.length > 0) ? UseFullService.ToFixed(d[0].valor) : '0,00'; }
        );
      }
    })
  }

  insertTrItem(item: RelacaoComposicao, data: any, tipo: EnumItemTipo) {
    item.codigo = data.codigo
    item.descricao = data.descricao
    item.unidadeMedidaId = data.unidadeMedidaId
    item._unidadeMedida = data._unidadeMedida
    item.itemId = data.id
    item._paramsEstado.valor = UseFullService.ToDecimalString(data._preco)
    item._paramsEstado.estado = this._estado
    item._paramsEstado.itemId = item.itemId
    item._paramsEstado.itemTipo = item.itemTipo
    item._paramsEstado.status = EnumStatusValorEstado.Aberto
    item.composicaoId = this.model.id
    $('input#quantidade-' + item._index).focus()
    item._active_buscar = false
    item._selected_hover = false
    this.carregarPlanilha();
  }

}

import { Component } from '@angular/core';
import { MedicaoPlanilhaOrcamentariaComponent } from './medicao-planilha-orcamentaria.component';

declare var $: any;
@Component({
  selector: 'app-medicao-planilha-orcamentaria-view',
  templateUrl: './medicao-planilha-orcamentaria-view.component.html',
  styleUrls: ['./medicao-planilha-orcamentaria.component.css']
})

export class MedicaoPlanilhaOrcamentariaViewComponent extends MedicaoPlanilhaOrcamentariaComponent {

  _code = 'app-medicao-planilha-orcamentaria-view'
}
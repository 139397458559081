import { UsuarioConfiguracao } from '../../usuario/usuarioConfiguracao'

export class ValorMaoObraEstado {
  public id: number
  public maoDeObraId: number
  public estado: string
  public valor: number

  static getAll(usuarioConfiguracao: UsuarioConfiguracao, maoDeObraId: number = 0): Array<ValorMaoObraEstado> {
    const listEstados = usuarioConfiguracao.sequenciaEstadoMaoDeObra.split(':')
    const listModel = new Array<ValorMaoObraEstado>()
    listEstados.forEach(c => {
      const model = new ValorMaoObraEstado()
      model.maoDeObraId = maoDeObraId
      model.estado = c
      listModel.push(model)
    });
    return listModel
  }

  public validar() {
    if (this.maoDeObraId < 1)
      throw ('Empresa não encontrada!')
    if (!this.estado && this.estado.length > 2)
      throw ('Descrição não encontrada!')
  }
}

import { UseFullService } from 'src/app/service/usefull/usefull.service';

export class AlertData {

    public _countOrcamentoAbertos: number
    public _countVendasAbertas: number
    public _countEntregasAbertas: number
    public _countInclusaoUsuario: number
    public _countTotal: number

    static SetData(model: AlertData) {
        try {
            model._countTotal = (model._countEntregasAbertas?model._countEntregasAbertas:0) +
                                (model._countInclusaoUsuario?model._countInclusaoUsuario:0) +
                                (model._countOrcamentoAbertos?model._countOrcamentoAbertos:0) +
                                (model._countVendasAbertas?model._countVendasAbertas:0)
        } catch (error) {
            console.log(error)
        }
    }
}
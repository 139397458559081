import { Injectable, EventEmitter } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AlertConfirmService {

  public _confirm: boolean;
  public _code: string;
  public _title: string;
  public _message: string;

  unsubscribe() {   
    this.confirmEmitter$.unsubscribe()
    if(this.confirmEmitter$.closed)  
      this.confirmEmitter$ = new EventEmitter() 
  }

  public confirmEmitter$: EventEmitter<boolean>;

  constructor() {
    this.confirmEmitter$ = new EventEmitter();
  }

  Set(title: string, message: string, code: string) {
    this._confirm = false;
    this._code = code;
    this._title = title;
    this._message = message;
  }

  Reset() {
    this._confirm = false;
    this._code = "";
    this._title = "";
    this._message = "";
  }

  Show() {
    $('#global-modal').modal('show');
  }

  ConfirmEmit(confirm: boolean): void {
    if (confirm) {
      this.confirmEmitter$.emit(confirm);
    }
    $('#global-modal').modal('hide');
    this.Reset();
  }

}

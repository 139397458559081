import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario, TipoAssinaturaUsuario } from 'src/app/domain/usuario/Usuario';
import { Router } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { table } from 'src/app/domain/common/table';
import { HistoricoFinanceiro } from 'src/app/domain/licitacao/historicoFinanceiro';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.css']
})
export class MinhaContaComponent implements OnInit {

  public _title: string;
  public _hidden: boolean;
  public grid_use_detalhes: string;
  public _cpf: string;
  listHistorico = new Array<HistoricoFinanceiro>()
  historicoVirgente = new HistoricoFinanceiro()

  constructor(private confirm: AlertConfirmService, private userService: UserService, private context: RepositorioService, private router: Router, private userLoggedIn: UserLoggedInService) {

  }

  public usuario: Usuario;

  ngOnInit() {
    this.userLoggedIn.SetProperties()
    this.usuario = new Usuario();
    this._title = "Minha Conta";
    this.GetUser();
    this.confirm.confirmEmitter$.subscribe(c => this.save(c));
  }

  avaliacao = ''
  statusPerido = false
  dataAtivacao = ''
  dataExpiracao = ''
  _tempCPF = ''
  GetUser() {
    this.userService.get(this.userLoggedIn.id).subscribe(
      d => {
        this.usuario = d;

        const cpf = this.usuario.cpf
        this.usuario._cpf = '000000000' + cpf.slice(9, 11)
        this._tempCPF = this.usuario._cpf

        this.getHistoy();
      },
      r => { },
      () => { UseFullService.SetMask(500) }
    );
  }

  code = 'minha-conta'
  atualizarConta() {
    this.confirm._code = this.code;
    this.confirm._title = "Alterar Dados";
    this.confirm._message = "Deseja alterar dados da sua conta de usuário ?";
    this.confirm.Show();
  }
  
  save(confirm: boolean): void {
    if (confirm && this.confirm._code != this.code)
      return;

    if (this.usuario._cpf !== this._tempCPF)
      this.usuario.cpf = this.usuario._cpf
    this.userService.SetEditStatusProvider(this.usuario).subscribe(d => { console.log(d) })
  }

  getHistoy() {
    this.context.path = 'financeiro'
    this.context.getAll('&id=' + this.userLoggedIn.id).subscribe(
      d => {
        this.listHistorico = d
        this.listHistorico.forEach(c => { HistoricoFinanceiro.format(c) })
      },
      r => { },
      () => {

        if (this.usuario.tipoConta != TipoAssinaturaUsuario.gratuita && (this.listHistorico && this.listHistorico.length > 0)) {

          const historicoVirgente = this.listHistorico.filter(o => { return (o._status) })
          if (historicoVirgente && historicoVirgente.length > 0) {
            const count = historicoVirgente.length - 1
            this.dataAtivacao = UseFullService.formatDate(new Date(historicoVirgente[count]._inicio))
            this.dataExpiracao = UseFullService.formatDate(new Date(historicoVirgente[count]._fim))
          } else {
            this.dataAtivacao = UseFullService.formatDate(new Date(this.listHistorico[0]._inicio))
            this.dataExpiracao = UseFullService.formatDate(new Date(this.listHistorico[0]._fim))
          }

        } else if (this.usuario.tipoConta == TipoAssinaturaUsuario.gratuita) {
          const dTemp = new Date(this.usuario.dataStatus)
          const d1 = new Date(dTemp.setDate(dTemp.getDate() + this.usuario.diasGratis))
          this.statusPerido = (new Date() < d1)
          this.avaliacao = (this.statusPerido) ? 'Em período de avaliação' : 'Período de avaliação expirou'

          this.dataAtivacao = UseFullService.formatDate(new Date(this.usuario.dataStatus))
          this.dataExpiracao = UseFullService.formatDate(d1)
        }

      }
    );
  }

}

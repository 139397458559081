import { Workbook, PaperSize } from 'exceljs';
import * as fs from 'file-saver';
import { RelacaoPlanilhaOrcamentariaItem } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { ɵConsole } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EnumItemTipo } from 'src/app/domain/engenharia/composicao/relacaoComposicao';

export class ExcelOrcamentoSintetico {
  _total = 0
  _totalBDI = 0

  generateExcel(cabecalho: any, listRelacaoPlanilha: RelacaoPlanilhaOrcamentariaItem[]) {

    const header = [
      ["Empresa", "Painel da engenharia", "", "", "CPNJ:", "00.665.045/0001-85", "N° 4568", ""],
      ["Obra", cabecalho.obra.nome, "", "", "Início", "05/04/2020", "Fim", "06/05/2021"],
      ["Endereço", "Endereçõ de teste", "", "", "", "", "Emissão", "02/06/2020"],
      ["Planilha", cabecalho.planilha.descricao, "", "", "Cliente", "Nome do cliente", "", ""]
    ]
    const tableHeader = ["Código", "Descrição", "Unid", "Qtd", "R$ Unt", "R$ Subtotal", "% BDI", "R$ Total"]


    const data = []
    listRelacaoPlanilha.forEach(c => {
      if (c.codigo) {

        if (c.itemTipo <= EnumItemTipo.composicao) {
          this._total += UseFullService.ConvertFloat(c._valorSubTotal)
          this._totalBDI += UseFullService.ConvertFloat(c._valorTotal)
        }
        data.push([c.itemTipo, c.codigo, c.descricao, c._unidadeMedida, UseFullService.ToFixedString(c.quantidade, 2), c._valor, c._valorSubTotal, UseFullService.ToFixedString(c.bdi, 2), c._valorTotal])
      }

    })
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Teste Planilha');

    //Add Row and formatting

    const title = ['', 'Planilha Orçamentária'];

    let titleRow = worksheet.addRow(title);
    titleRow.font = { family: 4, size: 16, underline: 'none', bold: true }
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' }
    titleRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    titleRow.getCell(2).border = titleRow.getCell(1).border
    const img64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAYAAABxLuKEAAAACXBIWXMAAAsSAAALEgHS3X78AAACYUlEQVR42u2c0XGDMAyGGSHvvdx5BEZgBEZgBEbwBh6BERjBIzACI3gECj31juaaxBDJkmx897+0aUk+jPRLtlNV17jGNa6Rybjfv26rmlV21bDKr5pXLf9ogt9vr+u3v8sNhoEPNj0BcFQj/L+bViAdIoxXkBpNQGZiII/artdKBdIyAHnUFpNqSQF1ZAbyKMsNZcswQRiUfVYzXLFkEa6QNDiDt1gUqbugcMBRDIUOjpKYEqMaO/ssmSigZCvwKSEjMD9GEAPMmBmUz00g2PwlY5mzYObMwfgzUCzym3Ang35P/Dg33AHXIiQByzpriDyLRbIONcFNq2MvPkluBRDAGWIvKr5HgjyrQ8wFHRGYAYJptBJnzZbjMfrEvrtEmXN4t9Qh0W+4BDXcrNLpJrqRJpWpIwUD75ne7MHyQ8kzxmoDkyLGPC9bhDaVbEJr4TnAHPUxNUP1zwIG2/n2yQpKLWAIyxa9YMBrhQvM3wzkWTp6UsDstqS1kHkm1lYn8R2xCryT41gq0QDGctRKGsA0HNW1BjC3V0GvVDAzVwdPOhjHYbU1gHnb8zUFggmxBmsqDIyLBdMVBsYcqU3mQsAMR4u2rhAw9ZmKFnPWjE86dZOa2ZLppkTcTYqEa9nc6jF2bea25WyU3mNlqYlQjw1msjM8kBz0ygAO3REdxcE4ydGcTtnjk+4gKcAJCgJt+sOjkK0moVA866F18DlO2KPTV1JGopXCmHrMVBIH00l9r+mrDBriBbwAe25MpXFADOoAUkDIMoPY73FAyGQdrHr6ncLuw//+bITXtWpnRm7jG5/krNRqjtglAAAAAElFTkSuQmCC"


    let logo = workbook.addImage({
      base64: img64,
      extension: 'png',
    });

    worksheet.addImage(logo, 'A1:A4');
    worksheet.mergeCells('A1:A4');
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(5).width = 14;
    worksheet.getColumn(6).width = 18;
    worksheet.getColumn(7).width = 10;
    worksheet.getColumn(8).width = 14;

    worksheet.mergeCells(`B${titleRow.number}:H4`);

    /************ start Cabeçalho ***************/
    let headerRow = []
    for (let i = 0; i < header.length; i++) {
      headerRow.push(worksheet.addRow(header[i]))
      for (let x = 0; x < header[i].length; x++) {
        if (x % 2 == 0) {
          let cell = headerRow[i].getCell(x + 1)
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'dddddd' }, bgColor: { argb: '000000' } }
          cell.font = { bold: true }
        }
        headerRow[i].getCell(x + 1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      }
    }
    headerRow[0].getCell(7).alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells(`B${headerRow[0].number}:D${headerRow[0].number}`);
    worksheet.mergeCells(`G${headerRow[0].number}:H${headerRow[0].number}`);
    worksheet.mergeCells(`B${headerRow[1].number}:D${headerRow[1].number}`);
    worksheet.mergeCells(`B${headerRow[2].number}:F${headerRow[2].number}`);
    worksheet.mergeCells(`B${headerRow[3].number}:D${headerRow[3].number}`);
    worksheet.addRow([]);
    /************** end Cabeçalho **************/

    //start th
    let tableHeaderRow = worksheet.addRow(tableHeader);
    tableHeaderRow.eachCell((cell, number) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'dddddd' }, bgColor: { argb: '000000' } }
      cell.font = { bold: true }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    //end th
    //start td
    data.forEach(d => {
      const itemTipo = d[0]
      d.splice(0, 1)
      let textBold = (itemTipo === EnumItemTipo.grupo || itemTipo === EnumItemTipo.subgrupo || itemTipo === EnumItemTipo.subgrupoFilho)
      let row = worksheet.addRow(d);
      for (let i = 0; i < d.length; i++) {
        let cell = row.getCell(i + 1)
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffff' }, bgColor: { argb: '000000' } }
      }

      if (textBold) {
        row.font = { bold: true }
        row.getCell(4).value = ''
        row.getCell(7).value = ''
      }


      row.getCell(2).alignment = { wrapText: true }
      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' }
      row.getCell(3).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(4).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(5).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(6).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(7).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(8).alignment = { vertical: 'middle', horizontal: 'right' }
    });
    //end td

    //start footer
    let footerRow = worksheet.addRow(['Valor Total']);
    footerRow.font = { bold: true }
    footerRow.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CCCCCC' } }
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    footerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'right' }

    footerRow.getCell(6).fill = footerRow.getCell(1).fill
    footerRow.getCell(6).value = UseFullService.ToFixedString(this._totalBDI)
    footerRow.getCell(6).border = footerRow.getCell(1).border
    footerRow.getCell(6).alignment = { vertical: 'middle', horizontal: 'right' }

    footerRow.getCell(7).fill = footerRow.getCell(1).fill
    footerRow.getCell(7).value = UseFullService.ToFixedString(this._totalBDI)
    footerRow.getCell(7).border = footerRow.getCell(1).border
    footerRow.getCell(7).alignment = { vertical: 'middle', horizontal: 'right' }

    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);
    worksheet.mergeCells(`G${footerRow.number}:H${footerRow.number}`);
    // end footer

    workbook.model.sheets[0].pageSetup.paperSize = PaperSize.A4
    workbook.model.sheets[0].pageSetup.scale = 76
    workbook.model.sheets[0].pageSetup.margins = { top: 0.24, left: 0.24, bottom: 0.24, right: 0.24, header: 0.24, footer: 0.24 }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'planilha.xlsx');
    })
  }

}

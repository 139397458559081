import { Injectable } from '@angular/core';
import { EventProperties } from '../core/Interceptor/loader-interceptor';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public _type: string;
  public _hidden: boolean;
  public _message: string;

  constructor() { }

  SetMessageError(msg: string): void {
    msg = msg.charAt(msg.length - 1) != '!' ? "Erro inesperado! Por favor tente novamente." : msg;
    this._type = "alert-danger";
    this.SetMessage(msg);
  }

  SetMsgError(msg: string): void {
    msg = typeof (msg) != "string" ? "Erro inesperado! Por favor atualize a página e tente novamente." : msg;
    this._type = "alert-danger";
    this.SetMessage(msg);
  }

  SetMessage(msg: string): void {
    this._message = msg;
    this._hidden = true;
    this.HiddenMessage();
  }

  HiddenMessage(): void {
    setTimeout(() => {
      this._hidden = false;
      this._message = '';
    }, 8000);
  }

  ChecksMessageSuccess(event: EventProperties) {
    if (event.status == 200) 
      this.SetMessageSuccess("Operação realizada com sucesso!");
  }

  SetMessageSuccess(msg: string) {
    this._type = "alert-success";
    this.SetMessage(msg);
  }
}

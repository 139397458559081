import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';
import { TypeUser } from 'src/app/domain/usuario/Usuario';

@Component({
  selector: 'app-login-site',
  templateUrl: './login-site.component.html',
  styleUrls: ['./login-site.component.css']
})
export class LoginSiteComponent implements OnInit {

  public _percentage: number;
  public _token: string;
  constructor(private httpAuthenticate: AuthenticationService, private router: Router, private route: ActivatedRoute) {
    this._percentage = 0;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((c: UrlParams) => {
      this._token = c.token;
    });
    setTimeout(() => {
      this.PageLoading(10);
      this.Entrar();
    }, 10);
  }

  Entrar() {
    this.PageLoading(40);
    this.httpAuthenticate.loginSite(this._token).subscribe(
      d => { this.PageLoading(80); },
      e => {
        this.PageLoading(100);
        setTimeout(() => { this.httpAuthenticate.logout(); }, 2000);
      },
      () => { this.PageLoading(100); this.ConfirmLogin(); }
    );
  }

  ConfirmLogin(): void {
    setTimeout(() => {
      if (this.httpAuthenticate.isLoggedIn()) {
        if (this.httpAuthenticate._currentUser.type === TypeUser.licitacao)
          this.router.navigateByUrl('/licitacao');
        else
          this.router.navigateByUrl('/home');
      }
    }, 1500);
  }

  PageLoading(limit: number = 10): void {
    setTimeout(() => {
      if (this._percentage < limit) {
        this._percentage++;
        this.PageLoading(limit);
      }
    }, 10);
  }
}

export class UrlParams {
  token: string;
}
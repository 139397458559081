export class FuncaoSistema {
    public id: number;
    public rota: string;
    public descricao: string;
    public operacao: string;
    public _relacao_id: string;
    public _restricao: string;

    public static AddOperacao(funcaoSistema: FuncaoSistema, codigo: number) {
        if (!funcaoSistema.operacao)
            funcaoSistema.operacao = codigo + ":";
        else if (!funcaoSistema.operacao.match(codigo + ':'))
            funcaoSistema.operacao += codigo + ":";
    }

    public static RemoveOperacao(funcaoSistema: FuncaoSistema, codigo: number) {
        if (funcaoSistema.operacao && funcaoSistema.operacao.match(codigo + ':'))
            funcaoSistema.operacao = funcaoSistema.operacao.replace(codigo + ":", '');
    }

    public _operacoes: Array<OperacoesFuncao>;
    public static SetOperacoes(funcaoSistema: FuncaoSistema) {
        funcaoSistema._operacoes = new Array<OperacoesFuncao>();

        for (let i = 0; i < this.keys().length; i++) {
            var data = new OperacoesFuncao();
            data.id = i;
            data.descricao = this.keys()[i];
            if (funcaoSistema.operacao && funcaoSistema.operacao.match(i + ':')) {
                if (!funcaoSistema._restricao || (funcaoSistema._restricao && !funcaoSistema._restricao.match(i + ':')))
                    data.checked = true;    
                data.selected = true;
            }
            funcaoSistema._operacoes.push(data);
        }
    }

    public static SetOperacoesVinculadas(funcaoSistema: FuncaoSistema) {
        funcaoSistema._operacoes = new Array<OperacoesFuncao>();

        for (let i = 0; i < this.keys().length; i++) {
            var data = new OperacoesFuncao();
            data.id = i;
            data.descricao = this.keys()[i];
            if (funcaoSistema.operacao && funcaoSistema.operacao.match(i + ':'))
                funcaoSistema._operacoes.push(data);
        }
    }

    public static keys(): Array<string> {
        var keys = Object.keys(EnumOperacoesFuncao);
        return keys.slice(keys.length / 2);
    }
}

export class RelacaoUsuarioFuncao {
    public id: number;
    public usuario_id: number;
    public funcao_id: number;
    public operacao: string;
}

export class RelacaoPerfilFuncao {
    public id: number;
    public perfil_id: number;
    public funcao_id: number;
    public operacao: string;

    public static AddOperacao(data: RelacaoPerfilFuncao, codigo: number) {
        if (!data.operacao)
            data.operacao = codigo + ":";
        else if (!data.operacao.match(codigo + ':'))
            data.operacao += codigo + ":";
    }

    public static RemoveOperacao(data: RelacaoPerfilFuncao, codigo: number) {
        if (data.operacao && data.operacao.match(codigo + ':'))
            data.operacao = data.operacao.replace(codigo + ":", '');
    }

    public static GetPorPerfilFuncao(data: RelacaoPerfilFuncao[], perfil_id: number, funcao_id: number): RelacaoPerfilFuncao {
        function filtrar(obj) {
            return (obj.funcao_id == funcao_id && obj.perfil_id == perfil_id);
        }
        var model = data.filter(filtrar);
        return model[0];
    }

    public static SetOperacoes(funcaoSistema: FuncaoSistema, operacao: string) {
        funcaoSistema._operacoes.forEach(c => {
            c.selected = false;
            if (operacao) {
                var operacoes = operacao.split(':');
                for (let i = 0; i < operacoes.length; i++) {
                    if (c.id == parseInt(operacoes[i]))
                        c.selected = true;
                }
            }
        });
    }
}

export class RestricaoUsuarioPerfilFuncao {
    public id: number;
    public usuario_id: number;
    public funcao_id: number;
    public operacao: string;

    public static AddOperacao(data: RestricaoUsuarioPerfilFuncao, codigo: number) {
        if (!data.operacao)
            data.operacao = codigo + ":";
        else if (!data.operacao.match(codigo + ':'))
            data.operacao += codigo + ":";
    }

    public static RemoveOperacao(data: RestricaoUsuarioPerfilFuncao, codigo: number) {
        if (data.operacao && data.operacao.match(codigo + ':'))
            data.operacao = data.operacao.replace(codigo + ":", '');
    }

    public static GetPorPerfilFuncao(data: RestricaoUsuarioPerfilFuncao[], perfil_id: number, funcao_id: number): RestricaoUsuarioPerfilFuncao {
        function filtrar(obj) {
            return (obj.funcao_id == funcao_id && obj.perfil_id == perfil_id);
        }
        var model = data.filter(filtrar);
        return model[0];
    }

    public static SetOperacoes(funcaoSistema: FuncaoSistema, operacao: string) {
        funcaoSistema._operacoes.forEach(c => {
            c.selected = false;
            if (operacao) {
                var operacoes = operacao.split(':');
                for (let i = 0; i < operacoes.length; i++) {
                    if (c.id == parseInt(operacoes[i]))
                        c.selected = true;
                }
            }
        });
    }
}

export enum EnumOperacoesFuncao {
    Visualizar = 0,
    Inserir = 1,
    Editar = 2,
    Excluir = 3
}

export class OperacoesFuncao {
    constructor() {
        this.selected = false;
        this.checked = false;
    }
    public id: number;
    public descricao: string;
    public selected: boolean;
    public checked: boolean;
}
import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { EspecialidadeEngenheiro } from 'src/app/domain/administracao/especialidadeEngenheiro';

declare var $: any

@Component({
  selector: 'app-especialidade-engenheiro-form',
  templateUrl: './especialidade-engenheiro-form.component.html',
  styleUrls: ['./especialidade-engenheiro-form.component.css']
})
export class EspecialidadeEngenheiroFormComponent implements OnInit {
  _msgParams = { titulo: '', descricao: '' }
  _code = 'app-especialidade-engenheiro-form'
  listModel = new Array<EspecialidadeEngenheiro>()
  tempModel = new EspecialidadeEngenheiro()

  constructor(private context: RepositorioService, public userLoggedService: UserLoggedInService, private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.getAll()    
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }

  getAll() {
    this.context.path = 'especialidade-engenheiro'
    this.context.getAll('empresaId=' + this.userLoggedService.empresaId).subscribe(
      d => {
        this.listModel = new Array<EspecialidadeEngenheiro>()
        d.forEach(d => { this.listModel.push(d) })
      },
      e => { },
      () => { this.setPlanilha(1) })
  }

  onResize(event) {
    setTimeout(() => {
      if ($("td.descricao").width() < 300)
        $("td.descricao").css('min-width', '300px')
      $("th.descricao").css('width', ($("td.descricao").width() + 3) + 'px')
      $("th.descricao").css('min-width', ($("td.descricao").width() + 3) + 'px')
      $("td.descricao").css('min-width', ($("td.descricao").width()) + 'px')

      $('.div-table-fixed').css('height', ($('body').height() - 230) + 'px')
      this.setScroll()
    }, 50);
  }

  mouseEnter(data: EspecialidadeEngenheiro) {
    data._selected_hover = true
    UseFullService.StartTooltip()
  }

  trClick(data: EspecialidadeEngenheiro) {
    this.listModel.forEach(c => {
      c._selected = (data == c) ? true : false
      if (!c._selected)
      EspecialidadeEngenheiro.setProperties(c)
    })
  }

  focusInputDescricao(i) {
    setTimeout(() => {
      $('input#descricao-' + i).focus()
    }, 1);
  }
  focusInputNome(i) {
    setTimeout(() => {
      $('input#nome-' + i).focus()
    }, 1);
  }

  _buscar = ''
  removerRow(row: number) {
    this.listModel.splice((row - 1), 1)
    this.atualisarPlanilha(this.listModel)
    this._buscar = ''
  }

  addRow(row: number) {
    const tempList = new Array<EspecialidadeEngenheiro>()
    const data = new EspecialidadeEngenheiro()
    for (let i = 0; i < row; i++)
      tempList.push(this.listModel[i])

    tempList.push(data)

    setTimeout(() => {
      const countList = this.listModel.length
      for (let i = row; i < countList; i++) {
        tempList.push(this.listModel[i])

        if (i == countList - 1) {
          this.listModel = tempList
          this.atualisarPlanilha(this.listModel)
        }
      }
    }, 11);
  }

  mouseLeave(data: EspecialidadeEngenheiro) {
    data._selected_hover = false
    UseFullService.StartTooltip()
  }

  setPlanilha(x: number) {
    for (let i = 0; i < x; i++) {
      const model = new EspecialidadeEngenheiro()
      this.listModel.push(model)
      if (i == x - 1)
        this.atualisarPlanilha(this.listModel);
    }
  }

  atualisarPlanilha(list: Array<any>) {
    var x = 1
    list.forEach(c => {
      c._index = x++
      c._par = Number.isInteger((x + 1) / 2)
    })
    setTimeout(() => {
      this.onResize(this)
      UseFullService.StartTooltip()
    }, 50);
  }

  onChange(data: EspecialidadeEngenheiro) {
    setTimeout(() => {
      this.context.path = 'especialidade-engenheiro'
      if (data.id > 0)
        this.context.put(data).subscribe();
      else
        this.context.post(data).subscribe(d => {
          data.id = d.id
          data.empresaId = d.empresaId
          if (data._index == this.listModel.length)
            this.setPlanilha(1)
        })
    }, 1)
  }

  buscarItem(data: EspecialidadeEngenheiro) {
    data._active_buscar = true;
  }

  setScroll() {
    $(".table-recurse").scroll(function (e) {
      $(".table-recurse thead").css('margin-left', '-' + e.currentTarget.scrollLeft + 'px')
    });
  }

  tempIndex = 0
  confirmDelete(model: EspecialidadeEngenheiro, row: number): void {
    this.tempModel = model;
    this.tempIndex = row
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Especialidade";
    this.confirmService._message = "Deseja excluir especialidade: <b>" + model.nome + "</b>?";
    this.confirmService.Show();       
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return;
    this.context.path = 'especialidade-engenheiro'
    this.context.delete(this.tempModel.id).subscribe(
      d => { },
      e => { },
      () => { this.removerRow(this.tempIndex) }
    );
  }

}

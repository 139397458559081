import { Component } from '@angular/core';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';

@Component({
  selector: 'app-relatorio-curva-abc-servico',
  templateUrl: './relatorio-curva-abc-servico.component.html',
  styleUrls: ['./relatorio-curva-abc-insumo.component.css']
})
export class RelatorioCurvaABCServicoComponent {

  list = new Array<any>()
  _planilha = new PlanilhaOrcamentaria()
  _obra = new Obra()

  constructor(
    public context: RepositorioService,
    public route: ActivatedRoute,
    public router: Router,
    public user: UserLoggedInService) {

  }
  id = this.route.snapshot.params['id']

  ngOnInit() {
    //this.user.SetCurrentUser()
    if (this.id){
      this.getUnidadesDeMedida()
      this.getPlanilha()
    }
  }

  unidadeDeMedida = new Array<UnidadeMedida>()
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => {
      this.unidadeDeMedida = d;
      this.getAll()
    })
  }

  getAll() {
    this.context.path = 'relatorio/planilhaorcamentaria/curva-abc-servico'
    this.context.getAll(this.id).subscribe(d => {
      this.list = d;  
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].unidadeDeMedida = this.unidadeDeMedida.filter((o: UnidadeMedida) => { return (o.id === this.list[i].unidadeMedidaId) })[0].nome
        this.list[i].valor = 'R$ ' + UseFullService.ToFixedString(this.list[i].valor, 4)
        this.list[i].valorTotal = 'R$ ' + UseFullService.ToFixedString(this.list[i].valorTotal, 2)
        this.list[i].porcentagem = UseFullService.ToFixedString(this.list[i].porcentagem, 6)
        this.list[i].porcentagemAcumulada = UseFullService.ToFixedString(this.list[i].porcentagemAcumulada, 6)
        this.list[i].quantidade = UseFullService.ToFixedString(this.list[i].quantidade, 4)
        this.list[i].valorFinal = 'R$ ' + UseFullService.ToFixedString(this.list[i].valorFinal, 2)
      }
    })
  }
  
  getPlanilha() {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(this.id).subscribe(d => {
      this._planilha = d;
      this.getObra(this._planilha.obraId)
    })
  }

  dataRel = ''
  getObra(id) {
    this.context.path = 'obra'
    this.context.get(id).subscribe(d => {
      this._obra = d;
      this._obra._dataInicio = UseFullService.formatDate(new Date(this._obra.dataInicio))
      this._obra._dataFim = UseFullService.formatDate(new Date(this._obra.dataFim))
      this.dataRel = UseFullService.formatDate(new Date())
    })
  }
}

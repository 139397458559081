import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario, TipoAssinaturaUsuario } from 'src/app/domain/usuario/Usuario';
import { Router } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { table } from 'src/app/domain/common/table';
import { HistoricoFinanceiro } from 'src/app/domain/licitacao/historicoFinanceiro';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { MinhaContaComponent } from './minha-conta.component';

@Component({
  selector: 'app-minha-conta-assinatura',
  templateUrl: './minha-conta-assinatura.component.html',
  styleUrls: ['./minha-conta.component.css']
})
export class MinhaContaAssinaturaComponent extends MinhaContaComponent {


}

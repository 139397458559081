import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from 'src/app/core/global-variable';
import { Dashboard, FaturamentoDashboard } from 'src/app/domain/auxiliar/dashboard';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  
  private url = GlobalVariable.BASE_API_URL + "dashboard/resumo";
  constructor(private http: HttpClient) { }

  Get(inicio, fim) {
    return this.http.get<Dashboard>(this.url+"?inicio="+inicio+"&fim="+fim).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetAll(inicio, fim) {
    return this.http.get<FaturamentoDashboard[]>(this.url+"/faturamento?inicio="+inicio+"&fim="+fim).pipe(
      map(res => {
        return res;
      })
    );
  }

}

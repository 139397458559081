import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from 'src/app/service/alert.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private alert: AlertService) { }
  handleError(error) {
    console.log(error)
    if (error && !error.status)
      this.alert.SetMsgError(error);
    //throw error;
  }
}
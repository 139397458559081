import { Component, OnInit } from '@angular/core';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { RelacaoPlanilhaOrcamentariaItem, EnumActionRow } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Estado } from 'src/app/domain/enum/estado';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ComposicaoService } from 'src/app/service/engenharia/composicao.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { MedicaoPlanilhaOrcamentaria } from 'src/app/domain/engenharia/medicao-planilha-orcamentaria';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { table } from 'src/app/domain/common/table';

declare var $: any;
@Component({
  selector: 'app-medicao-planilha-orcamentaria',
  templateUrl: './medicao-planilha-orcamentaria.component.html',
  styleUrls: ['./medicao-planilha-orcamentaria.component.css']
})

export class MedicaoPlanilhaOrcamentariaComponent implements OnInit {

  _code = 'app-medicao-planilha-orcamentaria'
  model = new PlanilhaOrcamentaria()

  listRelacaoPlanilha = new Array<RelacaoPlanilhaOrcamentariaItem>()
  relacaoPlanilha = new RelacaoPlanilhaOrcamentariaItem()

  unidadesDeMedida = new Array<UnidadeMedida>()
  listEstado = new Array<Estado>()

  listMedicao = new Array<MedicaoPlanilhaOrcamentaria>()
  medicao = new MedicaoPlanilhaOrcamentaria()
  obra = new Obra()
  id = this.route.snapshot.params['id']

  constructor(
    public user: UserLoggedInService,
    private confirmService: AlertConfirmService,
    private router: Router,
    private route: ActivatedRoute,
    private context: ComposicaoService,
    private userLoggedIn: UserLoggedInService
  ) {

    this.user.SetCurrentUser()
  }

  async ngOnInit() {
    if (this.id) {
      this.getPlanilha(this.id)
    } else if (this.user._obra && this.user._obra.id) {
      this.get(this.user._obra.id)
    } else
      this.router.navigateByUrl('dashboard')

    /*
  this.getUnidadesDeMedida()
  this.getEstados()
  this.getObra()*/
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }

  get(obraId: number) {
    this.context.path = 'planilha-orcamentaria'
    this.context.getAll('&obraId=' + obraId).subscribe(d => {
      this.listPlaninha = d;
      PlanilhaOrcamentaria.set(this.listPlaninha)
    })
  }

  toggleCalendar() {
    UseFullService.TraduzirCalendario();
  }


  showPlanejamento(planilhaOrcamentaria) {
    this.router.navigateByUrl('planilha-orcamentaria/medicao/view/' + planilhaOrcamentaria.id)
  }

  /***** enuns ******/
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => { this.unidadesDeMedida = d })
  }

  getPlanilha(id: number) {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.model = d;     
      this.obra = this.user._obra;
      this.showMedicao(this.model)
    })
  }

  totalMedido = '0,00'
  getMedicoes() {
    this.context.path = 'medicao-planilha-orcamentaria'
    this.context.getAll(this.model.id).subscribe(d => {
      this.listMedicao = d
      let x = 1
      let vlrTotal = 0
      this.listMedicao.forEach(c => {
        c._index = x++
        c.dataMedicao = UseFullService.formatDate(new Date(c.dataMedicao))
        vlrTotal += parseFloat(c._vlrTotal)
        c._vlrTotal = UseFullService.ToFixed(c._vlrTotal)
      })
      this.totalMedido = UseFullService.ToFixed(vlrTotal)
    })
  }

  editMedicao(medicao: MedicaoPlanilhaOrcamentaria) {
    this.medicao = medicao
    this.setDate()
  }

  ChangeDate() {
    setTimeout(() => {
      $('input[name=dataMedicao]').val(UseFullService.GetFormatDate(this.medicao._dataMedicao.day, this.medicao._dataMedicao.month, this.medicao._dataMedicao.year));
      this.medicao.dataMedicao = $('input[name=dataMedicao]').val();
    }, 10);
  }

  setDate() {
    this.medicao._dataMedicao = UseFullService.formatNgbDate(UseFullService.formatDateUS(this.medicao.dataMedicao))
    setTimeout(() => {
      $('input[name=dataMedicao]').val(this.medicao.dataMedicao)
    }, 10);
  }

  insertPlanilha() {
    this.context.path = 'medicao-planilha-orcamentaria'
    this.context.post(this.medicao).subscribe(d => {
      this.showMedicao(this.model)
    })
  }

  formMedicao = false
  showMedicao(planilhaOrcamentaria) {
    this.model = planilhaOrcamentaria
    this.getAllRelacoes(this.model.id)
    this.cancelPlanilha()
    this.getMedicoes()
    this.obra._dataFim = UseFullService.formatDateString(this.obra.dataFim)
    this.obra._dataInicio = UseFullService.formatDateString(this.obra.dataInicio)
  }

  cancelPlanilha() {
    this.medicao = new MedicaoPlanilhaOrcamentaria()
    this.medicao.planilhaOrcamentariaId = this.model.id
    this.medicao.dataMedicao = UseFullService.formatDate(new Date())
    this.setDate()
  }

  routerListMedicao(data: MedicaoPlanilhaOrcamentaria) {
    this.router.navigateByUrl('planilha-orcamentaria/medicao/list/' + data.id)
  }

  /****** start - list planhilhas ******/
  listPlaninha = new Array<PlanilhaOrcamentaria>()
  tempListPlaninha = new Array<PlanilhaOrcamentaria>()
  listObra = new Array<Obra>()

  getAllRelacoes(id: number) {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.getAll("planilhaOrcamentariaId=" + id).subscribe(d => {
      this.listRelacaoPlanilha = d;
      this.atualizarPlanilha()
    })
  }

  atualizarPlanilha() {
    this.listRelacaoPlanilha.forEach(c => { RelacaoPlanilhaOrcamentariaItem.setProperties(c) })
    setTimeout(async () => {
      await PlanilhaOrcamentaria.ordenarList(this.listRelacaoPlanilha)
      PlanilhaOrcamentaria.setValues(this.listRelacaoPlanilha, this.model);
    }, 100);
  }

  /****** end - list planhilhas ******/


  removeMedicao(model: MedicaoPlanilhaOrcamentaria): void {
    this.medicao = model
    this.confirmService._code = this._code
    this.confirmService._title = "Excluir Medição"
    this.confirmService._message = "Deseja excluir a medição <b>" + model.observacao + "</b>, todos os itens medidos com data de medição superior à <b>" +
      model.dataMedicao + "</b> terão valores alterados. <br /> Clique em sim para confirmar?"
    this.confirmService.Show()
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return
    this.context.path = 'medicao-planilha-orcamentaria'
    this.context.delete(this.medicao.id).subscribe(
      d => { this.showMedicao(this.model) }
    )
  }
}
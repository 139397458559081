import { InteractiveTable } from '../../common/interactiveTable'
import { RelacaoComposicao } from './relacaoComposicao'
import { UseFullService } from 'src/app/service/usefull/usefull.service'

export class Composicao extends InteractiveTable {
  public id: number
  public empresaId: number
  public codigo: string
  public origem: number
  public descricao: string
  public unidadeMedidaId: number
  public grupoId: number
  public subGrupoId: number
  public usuarioId: number
  public data: Date

  setNewComposicao(){
    this.empresaId = 0
    this.unidadeMedidaId = 0
    this.usuarioId = 0
    this.grupoId = 0
    this.subGrupoId = 0
  }

  _valorComposicaoEstado: ValorComposicaoEstado
  _unidadeMedida: string
  _preco: string
  _relacaoComposicao: RelacaoComposicao[]

  static valida(item: Composicao): Composicao {
    return item
  }
}


export class ValorComposicaoEstado {

  constructor(){
    this._valor = '0,00'
  }

  public id: number
  public composicaoId: number
  public estado: string
  public valor: string
  public _valor: string

  static getValorTotal(list: RelacaoComposicao[]): ValorComposicaoEstado {
    const model = new ValorComposicaoEstado()
    model.valor = '0'
    list.forEach(c => {
      if (c._paramsEstado.id) {
        model.estado = c._paramsEstado.estado
        model.composicaoId = c.composicaoId
        model.valor = String(parseFloat(model.valor) + parseFloat(UseFullService.ToDecimalString(c._paramsEstado.valorTotal)))
        model._valor = UseFullService.ToFixed(model.valor)
      }
    });
    return model
  }

  static valida(model: ValorComposicaoEstado) {
  }
}
export enum table {
  valormaoobraestado = 0,
  usuarioconfiguracao = 1,
  unidadedemedida = 2,
  valorinsumoestado = 3,
  gruposervico = 4,
  regiao = 5,
  estado = 6,
  relacaocomposicao = 7,
  valoritemcomposicaoestado = 8,
  composicao = 9,
  municipio = 10,
  endereco = 11,
  profissional = 12,
  relacaoobraprofissional = 13,
  planilhaorcamentaria = 14,
  historico = 15,
  relacaoplanilhaorcamentariaitem = 16,
  medicaoplanilhaorcamentaria = 17,
  medicaoItemplanilhaorcamentaria = 18,
  planejamentoplanilhaorcamentaria = 19,
  planejamentoitemplanilhaorcamentaria = 20,
  maoobradiarioobra = 21,
  equipamentodiarioobra = 22,
  diarioobra = 23,
  licitacao = 24,
  licitacaofavorita = 25,
  historicofinanceiro = 26
}
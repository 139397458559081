import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], searchText: string, fieldText: any = "", IsElement: boolean = false ): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            var retorno = false;
            if(IsElement)
                return (it[searchText] == fieldText || it[searchText] === undefined);
            
            this.filterObject(it).forEach(c=>{
                if(String(c).toLowerCase().includes(searchText)){
                    retorno = true;
                    return true;
                }
            });
            return retorno;
        });
    }

    private filterObject(data: object): object[]{
        var _string = JSON.stringify(data);
        var data_string = _string.split(',');
        var _arrayPlus = [];
        data_string.forEach(c=>{
            var _array = c.split(':');
            _arrayPlus.push(String(_array[1]))
        });
        return _arrayPlus;
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Usuario } from 'src/app/domain/usuario/Usuario';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = GlobalVariable.BASE_API_URL + "users/";
  public usuarios: Usuario[];
  public usuario: Usuario;
  
  constructor(private http: HttpClient) {}
  
  getAllCNPJ(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "getallcnpj").pipe(
      map(res=>{
        return res;
      })
    );
  }
  getAll(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "getall").pipe(
      map(res=>{
        return res;
      })
    );
  }
  get(id: number): Observable<Usuario> {
    return this.http.get<Usuario>(this.url + "?id="+id).pipe(
      map(res=>{
        return res;
      })
    );
  }
  
  getLoggedIn(): Observable<Usuario> {
    return this.http.get<Usuario>(this.url).pipe(
      map(res=>{
        return res;
      })
    );
  }

  getAllStatus(status: number): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.url + "all?options=status = "+status).pipe(
      map(res=>{
        return res;
      })
    );
  }

  SetUserProvider(usuario: Usuario): Observable<any> {  
    if(usuario.id == undefined || usuario.id <= 0)
      return this.SetSaveUserProvider(usuario);
    else
      return this.SetEditUserProvider(usuario);
  }

  SetSaveUserProvider(usuario: Usuario): Observable<any> { 
    return this.http.post<any>(this.url + "createUserProvider", usuario).pipe(
      map(res=> { return res; })
    );
  }

  SetEditUserProvider(usuario: Usuario): Observable<any> { 
    return this.http.put<any>(this.url + "editUserProvider", usuario).pipe(
      map(res=> { return res; })
    );
  }

  SetEditStatusProvider(usuario: Usuario): Observable<any> { 
    return this.http.put<any>(this.url, usuario).pipe(
      map(res=> { return res; })
    );
  }

  SetDeleteUserProvider(usuario: Usuario): Observable<any> { 
    return this.http.delete<any>(this.url + "deleteUserProvider?id="+usuario.id).pipe(
      map(res=> { return res; })
    );
  }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/core/global-variable';

@Injectable({
    providedIn: 'root'
})
export class RepositorioService {

    public path: string
    private getUrl(): string { return GlobalVariable.BASE_API_URL + this.path + '/' }

    constructor(private http: HttpClient) { }

    public getAttr(option: string = '', object = ''): Observable<any> {
        return this.http.get<any>(this.getUrl() + "getAttr?options=" + option + "&object=" + object).pipe(
            map(res => {
                return res;
            })
        );
    }
    public getAll(option: any = '', object: any = ''): Observable<any> {
        return this.http.get<any>(this.getUrl() + "all?options=" + option + "&object=" + object).pipe(
            map(res => {
                return res;
            })
        );
    }
    public get(id: any, object = ''): Observable<any> {
        return this.http.get<any>(this.getUrl() + "?id=" + id + "&object=" + object).pipe(
            map(res => {
                return res;
            })
        );
    }

    public post(data: any): Observable<any> {
        return this.http.post<any>(this.getUrl(), data).pipe(
            map(res => { return res; })
        );
    }

    public put(data: any): Observable<any> {
        return this.http.put<any>(this.getUrl(), data).pipe(
            map(res => { return res; })
        );
    }

    public delete(id: number): Observable<any> {
        return this.http.delete<any>(this.getUrl() + '?id=' + id).pipe(
            map(res => { return res; })
        );
    }

    public deleteAll(ids: []): Observable<any> {
        return this.http.delete<any>(this.getUrl() + '?ids=' + JSON.stringify(ids)).pipe(
            map(res => { return res; })
        );
    }

    /**async **/
    public async getAsync(id: number, object = ''): Promise<Observable<any>> {
        const data = await this.http.get<any>(this.getUrl() + "?id=" + id + "&object=" + object).pipe(
            map(async res => {
                return await res;
            })
        );
        return data
    }

    /*
        public postTeste(): Observable<any> {
            let header = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    Cookie: 'PHPSESSID=freemiun-362529-1590430890; _hjid=629104bf-fa19-4e24-877b-3a2906834c1c; _hjIncludedInSample=1; _hjAbsoluteSessionInProgress=1; _pwz_lead_hash_6015=98624f1c0b1aa455a0aa742dc2e1e95c; _ga=GA1.3.1615032447.1590430894; _gid=GA1.3.1438041881.1590430894'
                }
            }
    
            const formData = new FormData();
            formData.append('action', 'getlist');
            formData.append('publicacao_inicio', '01/05/2019');
            formData.append('publicacao_fim', '28/05/2020');
            formData.append('areas_interesse', 'TELE');
            formData.append('page', String(1));
    
            return this.http.post<any>('https://app.rcc.com.br/resources/controller/licitacoes.php?action=getlist&page=1&publicacao_inicio=01/05/2019&publicacao_fim=28/05/2020&areas_interesse=TELE', formData, header).pipe(
                map(res => { return res; })
            );
        }
        */
}
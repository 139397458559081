import { InteractiveTable } from '../../common/interactiveTable'
import { Data } from '@angular/router';
import { ValorMaoObraEstado } from './valorMaoDeObraEstado';

export class MaoDeObra extends InteractiveTable {

    constructor() {
        super();
    }

    public id: number
    public codigo: string
    public empresaId: number
    public descricao: string
    public unidadeMedidaId: number
    public data: Data

    _unidadeMedida: string
    _preco: string   

    _listValorMaoObraEstado: Array<ValorMaoObraEstado>

    static validar(model: MaoDeObra) {
        if (!model.descricao || model.descricao.length < 4)
            throw ('Descrição deve ter no minímo 4 digitos!')
        if (!model.unidadeMedidaId || model.unidadeMedidaId < 0)
            throw ('Informa unidade de medida!')
    }
}
export class UsuarioConfiguracao {
    public id: number
    public usuarioId: number
    public sequenciaEstadoMaoDeObra: string
    public sequenciaEstadoInsumo: string

    static editSequence(lista: string, uf_1: string, uf_2: string): string {
        const listEstados = lista.split(':')
        var estados = ''
        const ultimoEstado = listEstados[listEstados.length - 1]

        listEstados.forEach(c => {
            if (c == uf_1)
                estados += uf_2 + ':'
            if (c != uf_2) {
                estados += c
                if (ultimoEstado != c)
                    estados += ':'                
            }
        })
        if(estados[estados.length - 1] == ':')
            estados = estados.substring(0, (estados.length - 1))
        return estados
    }

    static editSequenceMaoDeObra(model: UsuarioConfiguracao, uf_1: string, uf_2: string) {
        model.sequenciaEstadoMaoDeObra = this.editSequence(model.sequenciaEstadoMaoDeObra, uf_1, uf_2)
    }

    static editSequenceInsumo(model: UsuarioConfiguracao, uf_1: string, uf_2: string) {
        model.sequenciaEstadoInsumo = this.editSequence(model.sequenciaEstadoInsumo, uf_1, uf_2)
    }
}
/*AC:AL:AP:AM:BA:CE:DF:ES:GO:MA:MT:MS:MG:PA:PB:PR:PE:PI:RJ:RN:RS:RO:RR:SC:SP:SE:TO*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './core/page/home/home.component';
import { NotFoundComponent } from './core/page/not-found/not-found.component';
import { UserComponent } from './modules/user/user.component';
import { LoginComponent } from './modules/user/login/login.component';
import { AuthGuard } from './core/Interceptor/auth-guard';
import { MinhaContaComponent } from './modules/user/minha-conta/minha-conta.component';
import { GerenciamentoAcessoComponent } from './modules/user/gerenciamento-acesso/gerenciamento-acesso.component';
import { LoginSiteComponent } from './modules/user/login-site/login-site.component';
import { ConfiguracaoComponent } from './modules/configuracao/configuracao/configuracao.component';
import { ConfiguracaoAdminComponent } from './modules/configuracao/configuracao-admin/configuracao-admin.component';
import { TemporaryMessageComponent } from './core/page/temporary-message/temporary-message.component';
import { CategoriaVirtualComponent } from './modules/configuracao/configuracao-admin/categoria-virtual/categoria-virtual.component';
import { CategoriaNivelComponent } from './modules/configuracao/configuracao-admin/categoria-nivel/categoria-nivel.component';
import { VinculoNivelComponent } from './modules/configuracao/configuracao-admin/vinculo-nivel/vinculo-nivel.component';
import { FuncaoSistemaComponent } from './modules/configuracao/configuracao-admin/funcao-sistema/funcao-sistema.component';
import { PerfilUsuarioComponent } from './modules/configuracao/configuracao-admin/perfil-usuario/perfil-usuario.component';
import { PrazoPagamento } from './domain/auxiliar/prazo-pagamento';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { DashboardService } from './service/auxiliar/dashboard.service';
import { ComposicaoListComponent } from './modules/engenharia/composicao/composicao-list.component';
import { ComposicaoFormComponent } from './modules/engenharia/composicao/composicao-form/composicao-form.component';
import { MaoDeObraFormComponent } from './modules/engenharia/composicao/maoDeObra/mao-de-obra-form/mao-de-obra-form.component';
import { InsumoFormComponent } from './modules/engenharia/composicao/insumo/mao-de-obra-form/insumo-form.component';
import { GrupoServicoComponent } from './modules/engenharia/composicao/grupo-servico/grupo-servico.component';
import { PlanilhaOrcamentariaFormComponent } from './modules/engenharia/planilha-orcamentaria/planilha-orcamentaria-form/planilha-orcamentaria-form.component';
import { ListPlanilhaOrcamentariaComponent } from './modules/engenharia/planilha-orcamentaria/list-planilha-orcamentaria/list-planilha-orcamentaria.component';
import { ProfissionalFormComponent } from './modules/administracao/profissional/profissional-form.component';
import { EspecialidadeEngenheiroFormComponent } from './modules/administracao/especialidadeEngenheiro/especialidade-engenheiro-form.component';
import { ProfissionalComponent } from './modules/administracao/profissional/profissional.component';
import { ObraFormComponent } from './modules/engenharia/obra/obra-form.component';
import { ObraComponent } from './modules/engenharia/obra/obra.component';
import { PlanilhaComponent } from './modules/relatorio/planilha.component';
import { MedicaoPlanilhaOrcamentariaComponent } from './modules/engenharia/planilha-orcamentaria/medicao-planilha-orcamentaria/medicao-planilha-orcamentaria.component';
import { MedicaoPlanilhaOrcamentariaListComponent } from './modules/engenharia/planilha-orcamentaria/medicao-planilha-orcamentaria/medicao-planilha-orcamentaria-list.component';
import { PlanejamentoPlanilhaOrcamentariaListComponent } from './modules/engenharia/planejamento/planejamento-planilha-orcamentaria/planejamento-planilha-orcamentaria-list.component';
import { PlanejamentoPlanilhaOrcamentariaComponent } from './modules/engenharia/planejamento/planejamento-planilha-orcamentaria/planejamento-planilha-orcamentaria.component';
import { FeriadoFormComponent } from './modules/engenharia/planejamento/feriado/feriado-form.component';
import { DiarioObraComponent } from './modules/diario-obra/diario-obra.component';
import { DiarioObraFormComponent } from './modules/diario-obra/diario-obra-form.component';
import { DiarioObraListComponent } from './modules/diario-obra/diario-obra-list.component';
import { MaoDeObraPersonalizadaComponent } from './modules/auxiliar/mao-de-obra-personalizada/mao-de-obra-personalizada.component';
import { EquipamentoComponent } from './modules/auxiliar/equipamento/equipamento.component';
import { DiarioObraViewComponent } from './modules/diario-obra/diario-obra-view.component';
import { DiarioObraRelatorioComponent } from './modules/diario-obra/relatorios/diario-obra-relatorio.component';
import { LicitacaoComponent } from './modules/licitacao/licitacao.component';
import { RelatorioCurvaABCInsumoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-curva-abc-insumo.component';
import { RelatorioCurvaABCServicoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-curva-abc-servico.component';
import { RelatorioOrcamentoSinteticoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-orcamento-sintetico.component';
import { RelatorioOrcamentoMemoriaCalculoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-orcamento-memoria-calculo.component';
import { RelatorioOrcamentoAnaliticoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-orcamento-analitico.component';
import { EmpresaFormComponent } from './modules/configuracao/empresa-form/empresa-form.component';
import { RelatorioCronogramaComponent } from './modules/relatorio/relatorioPlanejamento/relatorio-cronograma.component';
import { PlanejamentoPlanilhaOrcamentariaFormComponent } from './modules/engenharia/planejamento/planejamento-planilha-orcamentaria/planejamento-planilha-orcamentaria-form.component';
import { MedicaoPlanilhaOrcamentariaViewComponent } from './modules/engenharia/planilha-orcamentaria/medicao-planilha-orcamentaria/medicao-planilha-orcamentaria-view.component';
import { MenuContentComponent } from './core/page/menu-content/menu-content.component';
import { MinhaContaAssinaturaComponent } from './modules/user/minha-conta/minha-conta-assinatura.component';

const appRoutes: Routes = [

  //redirect
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  //inicio
  {
    path: 'inicio', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '', component: TemporaryMessageComponent, canActivate: [AuthGuard] }]
  },

  // "Engenharia" - Planilha Orçmentária
  {
    path: 'planilha-orcamentaria', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'list', component: ListPlanilhaOrcamentariaComponent, canActivate: [AuthGuard] },
      { path: 'form', component: PlanilhaOrcamentariaFormComponent, canActivate: [AuthGuard] },
      { path: 'form/:id', component: PlanilhaOrcamentariaFormComponent, canActivate: [AuthGuard] },
      { path: 'medicao', component: MedicaoPlanilhaOrcamentariaComponent, canActivate: [AuthGuard] },
      { path: 'medicao/list/:id', component: MedicaoPlanilhaOrcamentariaListComponent, canActivate: [AuthGuard] },
      { path: 'medicao/view/:id', component: MedicaoPlanilhaOrcamentariaViewComponent, canActivate: [AuthGuard] },
      { path: 'planejamento', component: PlanejamentoPlanilhaOrcamentariaComponent, canActivate: [AuthGuard] },
      { path: 'planejamento/view/:id', component: PlanejamentoPlanilhaOrcamentariaListComponent, canActivate: [AuthGuard] },
      { path: 'planejamento/list/:id', component: PlanejamentoPlanilhaOrcamentariaFormComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
///planilha-orcamentaria/medida/
  // "Engenharia" - Composições
  {
    path: 'composicao', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'list', component: ComposicaoListComponent, canActivate: [AuthGuard] },
      { path: 'form', component: ComposicaoFormComponent, canActivate: [AuthGuard] },
      { path: 'form/:id', component: ComposicaoFormComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "Engenharia" - mão de obra
  {
    path: 'mao-de-obra', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: MaoDeObraFormComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "Engenharia" - insumo
  {
    path: 'insumo', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: InsumoFormComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "Engenharia" - feriado
  {
    path: 'feriado', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: FeriadoFormComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "Engenharia" - equipamento
  {
    path: 'equipamento', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: EquipamentoComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "Engenharia" - mao de obra personalizada
  {
    path: 'mao-de-obra-personalizada', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: MaoDeObraPersonalizadaComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "Engenharia" - grupo de servico
  {
    path: 'grupo-servico', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: GrupoServicoComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },

  //pagina inicial
  {
    path: 'home', component: HomeComponent, children: [
      { path: '', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: '**', component: NotFoundComponent }
    ]
  },
  //pagina configuração
  {
    path: 'configuracao', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '', component: ConfiguracaoComponent },
      { path: '**', component: NotFoundComponent }
    ]
  },
  //pagina configuração
  {
    path: 'configuracao-adm', component: HomeComponent, canActivate: [AuthGuard], children: [
      {
        path: 'admin', component: ConfiguracaoAdminComponent, canActivate: [AuthGuard], children: [
          { path: 'categoria-virtual', component: CategoriaVirtualComponent },
          { path: 'categoria-nivel', component: CategoriaNivelComponent },
          { path: 'vincula-niveis', component: VinculoNivelComponent },
          { path: 'funcao-sistema', component: FuncaoSistemaComponent },
          { path: 'perfil-usuario', component: PerfilUsuarioComponent }
        ]
      },
      { path: '', component: ConfiguracaoAdminComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }
    ]
  },
  //pagina usuario
  {
    path: 'user', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'gerenciar-usuario', component: UserComponent, canActivate: [AuthGuard] },
      { path: 'gerenciar-acesso', component: GerenciamentoAcessoComponent, canActivate: [AuthGuard] },
      { path: 'minha-conta', component: MinhaContaComponent, canActivate: [AuthGuard] },
      { path: 'minha-conta-financeiro', component: MinhaContaAssinaturaComponent, canActivate: [AuthGuard] },
      { path: '', component: MinhaContaComponent }]
  },

  
  // "Adminsitração" - Profissional
  {
    path: 'profissional', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: ProfissionalFormComponent, canActivate: [AuthGuard] },
      { path: 'form/:id', component: ProfissionalFormComponent, canActivate: [AuthGuard] },
      { path: '', component: ProfissionalComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  
  // "Engenharia" - obra
  {
    path: 'obra', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: ObraFormComponent, canActivate: [AuthGuard] },
      { path: 'form/:id', component: ObraFormComponent, canActivate: [AuthGuard] },
      { path: '', component: ObraComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  
  // "Adminsitração" - EspecialidadeEngenheiro
  {
    path: 'especialidade-engenheiro', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'form', component: EspecialidadeEngenheiroFormComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "Relatorios PDF" - relatorio
  {
    path: 'relatorio', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'planilha-orcamentaria/:id', component: PlanilhaComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }]
  },
  // "empresa"
  {
    path: 'empresa', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '**', component: EmpresaFormComponent }]
  },
  // "licitação"
  {
    path: 'licitacao', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '**', component: LicitacaoComponent }]
  },
  //relatorios
  { path: 'diario-obra/relatorio/:id/:entrar', component: DiarioObraRelatorioComponent, canActivate: [AuthGuard] },
  { path: 'planilhaorcamentaria/relatorio/curva/abc-insumo/:id/:entrar', component: RelatorioCurvaABCInsumoComponent, canActivate: [AuthGuard] },
  { path: 'planilhaorcamentaria/relatorio/curva/abc-servico/:id/:entrar', component: RelatorioCurvaABCServicoComponent, canActivate: [AuthGuard] },
  { path: 'planilhaorcamentaria/relatorio/sintetico/:id/:entrar', component: RelatorioOrcamentoSinteticoComponent, canActivate: [AuthGuard] },
  { path: 'planilhaorcamentaria/relatorio/memoria-calculo/:id/:entrar', component: RelatorioOrcamentoMemoriaCalculoComponent, canActivate: [AuthGuard] },
  { path: 'planilhaorcamentaria/relatorio/analitico/:id/:entrar', component: RelatorioOrcamentoAnaliticoComponent, canActivate: [AuthGuard] },
  { path: 'planejamento/relatorio/geral/:id/:entrar', component: RelatorioCronogramaComponent, canActivate: [AuthGuard] },
  {
    path: 'diario-obra', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'view/:id', component: DiarioObraViewComponent, canActivate: [AuthGuard] },
      //{ path: 'form', component: DiarioObraFormComponent, canActivate: [AuthGuard] },
      { path: 'form/:id', component: DiarioObraFormComponent, canActivate: [AuthGuard] },
      { path: 'list', component: DiarioObraListComponent, canActivate: [AuthGuard] },
      { path: 'list/:id', component: DiarioObraListComponent, canActivate: [AuthGuard] },
      { path: '**', component: DiarioObraComponent, canActivate: [AuthGuard] }
    ]
  },

  //pagina usuario
  {
    path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '**', component: DashboardComponent, canActivate: [AuthGuard] }
    ]
  },
  //menu-auxiliar
  {
    path: 'menu-auxiliar', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: '**', component: MenuContentComponent, canActivate: [AuthGuard] }
    ]
  },
  

  //Login
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login-site',
    component: LoginSiteComponent
  },

  //redirect not found
  {
    path: '**',
    //component: NotFoundComponent
    redirectTo: 'not-found',
    pathMatch: 'full'
  }
];


export const AppRoutingProviders: any[] = [
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, { useHash: true });
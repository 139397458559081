import { Component, OnInit } from '@angular/core';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { RelacaoPlanilhaOrcamentariaItem, EnumActionRow } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Estado } from 'src/app/domain/enum/estado';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ComposicaoService } from 'src/app/service/engenharia/composicao.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { table } from 'src/app/domain/common/table';
import { PlanejamentoPlanilhaOrcamentaria, EnumStatusPlanejamento } from 'src/app/domain/engenharia/planejamento/planejamento-planilha-orcamentaria';

declare var $: any;
@Component({
  selector: 'app-planejamento-planilha-orcamentaria',
  templateUrl: './planejamento-planilha-orcamentaria.component.html',
  styleUrls: ['./planejamento-planilha-orcamentaria.component.css']
})

export class PlanejamentoPlanilhaOrcamentariaComponent implements OnInit {


  _code = 'app-planejamento-planilha-orcamentaria'
  model = new PlanilhaOrcamentaria()
  listEstado = new Array<Estado>()
  planejamento = new PlanejamentoPlanilhaOrcamentaria()
  obra = new Obra()
  id = this.route.snapshot.params['id']

  constructor(
    public user: UserLoggedInService,
    private confirmService: AlertConfirmService,
    private router: Router,
    private route: ActivatedRoute,
    private context: ComposicaoService,
    private userLoggedIn: UserLoggedInService
  ) {
    this.user.SetCurrentUser()
  }

  ngOnInit() {
    if (this.id)
      this.getPlanilha(this.id)
    else if(this.user._obra && this.user._obra.id)
      this.get(this.user._obra.id)
    else
      this.router.navigateByUrl('dashboard')
      
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }
  
  get(obraId: number) {
    this.context.path = 'planilha-orcamentaria'
    this.context.getAll('&obraId='+obraId).subscribe(d => {
      this.listPlaninha = d;
      PlanilhaOrcamentaria.set(this.listPlaninha)
    })
  }

  actionDiasNaoUteis(item) {
    item.active = !item.active
    PlanejamentoPlanilhaOrcamentaria.updateDiasNaoUteis(this.planejamento, item)

    this.context.path = 'planejamento-planilha-orcamentaria'
    this.planejamento.status = EnumStatusPlanejamento.update
    this.context.put(this.planejamento).subscribe(d => { })
  }

  toggleCalendar() {
    UseFullService.TraduzirCalendario();
  }

  getPlanilha(id: number) {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.model = d;      
      this.getObra(this.model.obraId)
    })
  }

  totalMedido = '0,00'
  getPlanejamento() {
    this.context.path = 'planejamento-planilha-orcamentaria/att'
    this.context.get(this.model.id).subscribe(d => {
      if (d && d.id)
        this.planejamento = d
      PlanejamentoPlanilhaOrcamentaria.setDiasSemana(this.planejamento)
    })
  }

  editPlanejamento(planejamento: PlanejamentoPlanilhaOrcamentaria) {
    this.planejamento = planejamento
  }

  gerarPlanejamento() {
    this.context.path = 'planejamento-planilha-orcamentaria'
    this.context.post(this.planejamento).subscribe(d => {
      this.showPlanejamento(this.model)
    })
  }

  showPlanejamento(planilhaOrcamentaria) {
    this.router.navigateByUrl('planilha-orcamentaria/planejamento/list/' + planilhaOrcamentaria.id)
  }

  startList() {
    this.getPlanejamento()
    this.obra._dataFim = UseFullService.formatDateString(this.obra.dataFim)
    this.obra._dataInicio = UseFullService.formatDateString(this.obra.dataInicio)
  }

  gerenciarPlanejamento() {
    this.router.navigateByUrl('planilha-orcamentaria/planejamento/view/' + this.planejamento.id)
  }

  /****** start - list planhilhas ******/
  listPlaninha = new Array<PlanilhaOrcamentaria>()

  _estado = ''
  _obra = ''

  getObra(id) {
    this.context.path = 'obra'
    this.context.get(id).subscribe(d => { this.obra = d; this.startList() })
  }

  /****** end - list planhilhas ******/
  removePlanejamento(model: PlanejamentoPlanilhaOrcamentaria): void {
    this.planejamento = model
    this.confirmService._code = this._code
    this.confirmService._title = "Excluir Planejamento"
    this.confirmService._message = "Deseja excluir o planejamento <b>" + model.observacao + "</b>.<br />Clique em sim para confirmar?"
    this.confirmService.Show()
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return
    this.context.path = 'planejamento-planilha-orcamentaria'
    this.context.delete(this.planejamento.id).subscribe(
      d => { }
    )
  }
}
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user/user.service';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { StatusUsuarioEnum } from 'src/app/domain/usuario/status-usuario-enum';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { HistoricoFinanceiro } from 'src/app/domain/licitacao/historicoFinanceiro';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { table } from 'src/app/domain/common/table';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { HistoricoFinanceiroService } from 'src/app/service/user/historicoFinanceiro.service';
import { AtendimentoUsuarioService } from 'src/app/service/user/atendimento.service';

@Component({
  selector: 'app-gerenciamento-acesso',
  templateUrl: './gerenciamento-acesso.component.html',
  styleUrls: ['./gerenciamento-acesso.component.css']
})
export class GerenciamentoAcessoComponent implements OnInit {

  _code = "app-gerenciamento-acesso";

  temp_usuario = new Usuario();
  public usuario_selecionado: Usuario;
  listHistorico = new Array<HistoricoFinanceiro>()

  constructor(
    private historicoService: HistoricoFinanceiroService, 
    private userService: UserService, 
    private userLoggedIn: UserLoggedInService, 
    private context: RepositorioService, 
    private confirmService: AlertConfirmService,
    public atendimento: AtendimentoUsuarioService
    ) {
  }
  public usuarios: Usuario[];
  public _buscar: string;
  public _busca_status: number;
  novoHistorico = new HistoricoFinanceiro()

  ngOnInit() {
    this.userLoggedIn.SetProperties()
    this.usuario_selecionado = new Usuario();
    this._buscar = "";
    this.usuarios = new Array<Usuario>();
    this.GetAllUser(StatusUsuarioEnum.aguardando);
    this.confirmService.confirmEmitter$.subscribe(c => this.SetAlterUser(c));
  }


  salvarAlteracoes() {
    this.userService.SetEditStatusProvider(this.usuario_selecionado).subscribe(
      d => { },
      e => { },
      () => { }
    );
  }

  SelectUser(user: Usuario): void {
    user._dataCriacao = UseFullService.formatDate(new Date(user.dataCriacao));
    user._dataUpdate = UseFullService.formatDate(new Date(user.dataUpdate));
    user._dataStatus = UseFullService.formatDate(new Date(user.dataStatus));
    this.usuario_selecionado = user;
    this.getHistoy(user.id);
    this.atendimento.obterTodas(user.id)
  }

  GetAllUser(status: StatusUsuarioEnum) {
    this._busca_status = status;
    this.userService.getAllStatus(status).subscribe(
      d => { this.usuarios = d; },
      e => { },
      () => { }
    );
    this.usuario_selecionado = new Usuario();
  }

  GetAll() {
    this.userService.getAll().subscribe(
      d => { this.usuarios = d; },
      e => { },
      () => { }
    );
    this.usuario_selecionado = new Usuario();
  }

  clickAlterStatus(e, usuario: Usuario, status: StatusUsuarioEnum): void {
    e.preventDefault();
    this.temp_usuario = usuario;
    this.temp_usuario.status = status;
    this.confirmService._code = this._code;
    this.confirmService._title = "Alterar Status";
    this.confirmService._message = "Deseja alterar o status do usuário " + usuario.nome + "?";
    this.confirmService.Show();
  }


  ValidarUser(e, usuario: Usuario): void {
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.ativo;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d => { },
      e => { },
      () => { this.GetAllUser(this._busca_status); }
    );
  }

  InativarUser(e, usuario: Usuario): void {
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.inativo;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d => { },
      e => { },
      () => { this.GetAllUser(this._busca_status); }
    );
  }

  RecusarUser(e, usuario: Usuario): void {
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.recusado;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d => { },
      e => { },
      () => { this.GetAllUser(this._busca_status); }
    );
  }

  AtivarUser(e, usuario: Usuario): void {
    e.preventDefault();
    usuario.status = StatusUsuarioEnum.ativo;
    this.userService.SetEditStatusProvider(usuario).subscribe(
      d => { },
      e => { },
      () => { this.GetAllUser(this._busca_status); }
    );
  }

  SetAlterUser(confirm: boolean): void {
    if (confirm && this.confirmService._code != this._code)
      return;
    this.userService.SetEditStatusProvider(this.temp_usuario).subscribe(
      d => { },
      e => { },
      () => { this.GetAllUser(this._busca_status); }
    );
  }

  getHistoy(id) {
    this.context.path = 'financeiro'
    this.context.getAll('&id=' + id).subscribe(
      d => {
        this.listHistorico = d
        this.listHistorico.forEach(c => { HistoricoFinanceiro.format(c) })
        this.cancelEditarHistorico()
      },
      r => { },
      () => { }
    );
  }

  carregarBoleto(input: any) {
    setTimeout(() => {
      this.novoHistorico._file = input.files[0];
      this.novoHistorico._updateFile = 1
    }, 100)
  }

  insertHistorico() {
    (this.novoHistorico.id > 0) ?
      this.historicoService.put(this.novoHistorico).subscribe(d => { this.getHistoy(this.usuario_selecionado.id) }) :
      this.historicoService.post(this.novoHistorico).subscribe(d => { this.getHistoy(this.usuario_selecionado.id) })
  }
  editarHistorico(historico) {
    this.novoHistorico = historico
    this.novoHistorico.dataVencimento = UseFullService.formatDateUS(this.novoHistorico._vencimento)
  }

  cancelEditarHistorico() {
    this.novoHistorico = new HistoricoFinanceiro()
    this.novoHistorico.usuarioId = this.usuario_selecionado.id
  }

}


import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Router, ActivatedRoute } from '@angular/router';
import { Obra } from 'src/app/domain/engenharia/obra';

declare var $: any

@Component({
  selector: 'app-obra',
  templateUrl: './obra.component.html',
  styleUrls: ['./obra.component.css']
})
export class ObraComponent implements OnInit {
  _msgParams = { titulo: '', descricao: '' }
  _code = 'app-obra'
  listModel = new Array<Obra>()
  model = new Obra()

  constructor(public context: RepositorioService, public router: Router, public route: ActivatedRoute, public userLoggedService: UserLoggedInService, public confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser();
    this.context.path = 'obra'
  }

  ngOnInit() { this.get()
  
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c)) }

  get() {
    this.context.getAll().subscribe(d => {
      this.listModel = d
      Obra.set(this.listModel)
      Obra.formatAllData(this.listModel)
    })
  }

  gerenciar(model: any) { this.router.navigate(['/obra/form/', model.id]) }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code) return;
    this.context.delete(this.model.id).subscribe(d => this.listModel.splice(this.model._index - 1, 1));
  }

  confirmDelete(model: Obra): void {
    this.model = model;
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Obra";
    this.confirmService._message = "Deseja excluir obra: <b>" + model.nome + "</b>?";
    this.confirmService.Show();
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../core/global-variable';
import { Http } from '@angular/http';
import { Empresa } from 'src/app/domain/empresa/empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private url = GlobalVariable.BASE_API_URL+"empresa/";
  constructor(private http: HttpClient, private simpleHttp: Http) { }
  
  Insert(_empresa: Empresa) {    
    const formData = new FormData();
    formData.append('id', String(_empresa.id));
    formData.append('razao_social', _empresa.razaoSocial);
    formData.append('nome_fantasia', _empresa.nomeFantasia);
    formData.append('cnpj', _empresa.cnpj);
    formData.append('img', _empresa._img);
    formData.append('logo', _empresa.logo);
    formData.append('telefone', _empresa.telefone);
       
    return this.http.post(this.url+'create', formData).pipe(
      map(r => { return r; })
    );
  }

  GetLoggedIn(): Observable<Empresa> {
    return this.http.get<Empresa>(this.url + "getcnpj").pipe(
      map(r => { return r; })
    );
  }
}

import { Feriado } from './feriado'

export class Cronograma {

    constructor(){
        this.ultimoDia = false
    }

    public data: Date
    public dia: number
    public diaSemana: number
    public _diaSemana: string
    public nomeSemaha: string
    public width: number
    public primeiroDiaSemana: String
    public diaMes: string   
    public ultimoDia: boolean
    public dataBr: string
    public feriado: Feriado
    public _dias: number

    static getSemana(x: number, t = 0): string {
        let days = []
        days.push(['Dom', 'Domingo'])
        days.push(['Seg', 'Segunda'])
        days.push(['Ter', 'Terça'])
        days.push(['Qua', 'Quarta'])
        days.push(['Qui', 'Quinta'])
        days.push(['Sex', 'Sexta'])
        days.push(['Sab', 'Sábado']) 
        return days[x][t]
    }
}
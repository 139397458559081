import { InteractiveTable } from '../../common/interactiveTable'
import { UseFullService } from 'src/app/service/usefull/usefull.service'
import { Cronograma } from './cronograma'

export class Feriado extends InteractiveTable {
    public id: number
    public empresaId: number
    public origem: number
    public origemId: number
    public descricao: string
    public data: string
    public dataUpdate: Date
    public _data: any

    static valida(data: Feriado) {
        if (!data.descricao) throw ('Descrição é obrigatório!')
        if (data.origem > 0 && data.origemId < 1) throw ('Seleção da obra é obrigatório!')
        if (!data.data || !data.data.match('/')) throw ('Data é obrigatório!')
    }

    static eFeriado(feriados: Feriado[], inicio: Date): boolean {
        const data = UseFullService.formatDateUsForm(inicio)
        const getPorData = (o) => {
            const oData = UseFullService.formatDateUsForm(new Date(o.data))
            return (oData == data)
        }
        const result = feriados.filter(getPorData)
        return (result && result.length > 0)
    }

    static getPorData(feriados: Feriado[], data: Date): Feriado {
        const _data = UseFullService.formatDateUsForm(data)
        const getPorData = (o) => {
            const oData = UseFullService.formatDateUsForm(new Date(o.data))
            return (oData == _data)
        }
        const result = feriados.filter(getPorData)
        return (result && result.length > 0) ? result[0] : null
    }

    static getFeriadosPlanejamento(diasNaoUteis: string, feriados: Feriado[], data: Date): Feriado {
        const diaSemana = data.getDay()
        let feriado = this.getPorData(feriados, data)
        if (!feriado && diasNaoUteis && diasNaoUteis.match(String(diaSemana))) {
            feriado = new Feriado()
            feriado.descricao = Cronograma.getSemana(diaSemana, 1) + ' é um dia não util'
        }
        return feriado
    }

    static haAtualiazacaoPendente(model: import("./planejamento-planilha-orcamentaria").PlanejamentoPlanilhaOrcamentaria, feriados: Feriado[]): boolean {
        const obterFilaUpdate = (o) => { 
            return (new Date(model.data) < new Date(o.dataUpdate)) 
        }
        const result = feriados.filter(obterFilaUpdate)
        return (result && result.length > 0)
    }
}

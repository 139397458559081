import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule, AppRoutingProviders } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FilterPipe } from './core/filter.pipe';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ChartModule } from 'angular-highcharts';

import { AppComponent } from './app.component';

import { HTTPListener, HTTPStatus } from './core/Interceptor/loader-interceptor';
import { HeaderComponent } from './core/header/header.component';
import { CustomHttpInterceptor } from './core/Interceptor/custom-http-interceptor';
import { GlobalErrorHandler } from './core/Interceptor/ErrorHandler';
import { AuthenticationService } from './service/authenticate/authentication.service';
import { NavBarComponent } from './core/page/nav-bar/nav-bar.component';
import { HomeComponent } from './core/page/home/home.component';
import { SideBarComponent } from './core/page/side-bar/side-bar.component';
import { FooterComponent } from './core/page/footer/footer.component';
import { BreadcrumbComponent } from './core/page/breadcrumb/breadcrumb.component';
import { NotFoundComponent } from './core/page/not-found/not-found.component';
import { MessageComponent } from './core/page/message/message.component';
import { LoadPageComponent } from './core/page/load-page/load-page.component';
import { AlertComponent } from './core/page/alert/alert.component';
import { AlertConfirmComponent } from './core/page/alert-confirm/alert-confirm.component';
import { NotPermissionComponent } from './core/page/not-permission/not-permission.component';
import { TemporaryMessageComponent } from './core/page/temporary-message/temporary-message.component';
import { ImageModalComponent } from './core/page/data-modal/data-modal.component';
import { DashboardEntregaComponent } from './modules/dashboard/dashboard-entrega/dashboard-entrega.component';
import { DashboardVendaComponent } from './modules/dashboard/dashboard-venda/dashboard-venda.component';
import { DashboardOrcamentoComponent } from './modules/dashboard/dashboard-orcamento/dashboard-orcamento.component';
import { DashboardCotacaoComponent } from './modules/dashboard/dashboard-cotacao/dashboard-cotacao.component';
import { DashboardComponent } from './modules/dashboard/dashboard/dashboard.component';
import { UserComponent } from './modules/user/user.component';
import { LoginComponent } from './modules/user/login/login.component';
import { NovoUsuarioComponent } from './modules/user/novo-usuario/novo-usuario.component';
import { MinhaContaComponent } from './modules/user/minha-conta/minha-conta.component';
import { UsuariosEmpresaComponent } from './modules/user/usuarios-empresa/usuarios-empresa.component';
import { GerenciamentoAcessoComponent } from './modules/user/gerenciamento-acesso/gerenciamento-acesso.component';
import { LoginSiteComponent } from './modules/user/login-site/login-site.component';
import { ConfiguracaoComponent } from './modules/configuracao/configuracao/configuracao.component';
import { EmpresaFormComponent } from './modules/configuracao/empresa-form/empresa-form.component';
import { ConfiguracaoAdminComponent } from './modules/configuracao/configuracao-admin/configuracao-admin.component';
import { CategoriaNivelComponent } from './modules/configuracao/configuracao-admin/categoria-nivel/categoria-nivel.component';
import { CategoriaVirtualComponent } from './modules/configuracao/configuracao-admin/categoria-virtual/categoria-virtual.component';
import { VinculoNivelComponent } from './modules/configuracao/configuracao-admin/vinculo-nivel/vinculo-nivel.component';
import { FuncaoSistemaComponent } from './modules/configuracao/configuracao-admin/funcao-sistema/funcao-sistema.component';
import { PerfilUsuarioComponent } from './modules/configuracao/configuracao-admin/perfil-usuario/perfil-usuario.component';
import { PermissaoUsuarioComponent } from './modules/configuracao/permissao-usuario/permissao-usuario.component';
import { ComposicaoListComponent } from './modules/engenharia/composicao/composicao-list.component';
import { ComposicaoFormComponent } from './modules/engenharia/composicao/composicao-form/composicao-form.component';
import { MaoDeObraFormComponent } from './modules/engenharia/composicao/maoDeObra/mao-de-obra-form/mao-de-obra-form.component';
import { InsumoFormComponent } from './modules/engenharia/composicao/insumo/mao-de-obra-form/insumo-form.component';
import { GrupoServicoComponent } from './modules/engenharia/composicao/grupo-servico/grupo-servico.component';
import { ListPlanilhaOrcamentariaComponent } from './modules/engenharia/planilha-orcamentaria/list-planilha-orcamentaria/list-planilha-orcamentaria.component';
import { PlanilhaOrcamentariaFormComponent } from './modules/engenharia/planilha-orcamentaria/planilha-orcamentaria-form/planilha-orcamentaria-form.component';
import { ViewItemDetailedComponent } from './modules/engenharia/composicao/view-item-detailed/view-item-detailed.component';
import { ProfissionalFormComponent } from './modules/administracao/profissional/profissional-form.component';
import { EspecialidadeEngenheiroFormComponent } from './modules/administracao/especialidadeEngenheiro/especialidade-engenheiro-form.component';
import { ProfissionalComponent } from './modules/administracao/profissional/profissional.component';
import { ObraFormComponent } from './modules/engenharia/obra/obra-form.component';
import { ObraComponent } from './modules/engenharia/obra/obra.component';
import { PlanilhaComponent } from './modules/relatorio/planilha.component';
import { MedicaoPlanilhaOrcamentariaListComponent } from './modules/engenharia/planilha-orcamentaria/medicao-planilha-orcamentaria/medicao-planilha-orcamentaria-list.component';
import { MedicaoPlanilhaOrcamentariaComponent } from './modules/engenharia/planilha-orcamentaria/medicao-planilha-orcamentaria/medicao-planilha-orcamentaria.component';
import { PlanejamentoPlanilhaOrcamentariaComponent } from './modules/engenharia/planejamento/planejamento-planilha-orcamentaria/planejamento-planilha-orcamentaria.component';
import { PlanejamentoPlanilhaOrcamentariaListComponent } from './modules/engenharia/planejamento/planejamento-planilha-orcamentaria/planejamento-planilha-orcamentaria-list.component';
import { FeriadoFormComponent } from './modules/engenharia/planejamento/feriado/feriado-form.component';
import { DiarioObraComponent } from './modules/diario-obra/diario-obra.component';
import { DiarioObraFormComponent } from './modules/diario-obra/diario-obra-form.component';
import { DiarioObraListComponent } from './modules/diario-obra/diario-obra-list.component';
import { MaoDeObraPersonalizadaComponent } from './modules/auxiliar/mao-de-obra-personalizada/mao-de-obra-personalizada.component';
import { EquipamentoComponent } from './modules/auxiliar/equipamento/equipamento.component';
import { DiarioObraViewComponent } from './modules/diario-obra/diario-obra-view.component';
import { DiarioObraRelatorioComponent } from './modules/diario-obra/relatorios/diario-obra-relatorio.component';
import { LicitacaoComponent } from './modules/licitacao/licitacao.component';
import { RelatorioCurvaABCInsumoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-curva-abc-insumo.component';
import { RelatorioCurvaABCServicoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-curva-abc-servico.component';
import { RelatorioOrcamentoSinteticoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-orcamento-sintetico.component';
import { RelatorioOrcamentoMemoriaCalculoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-orcamento-memoria-calculo.component';
import { RelatorioOrcamentoAnaliticoComponent } from './modules/relatorio/relatorioPlanilhaOrcamentaria/relatorio-orcamento-analitico.component';
import { RelatorioCronogramaComponent } from './modules/relatorio/relatorioPlanejamento/relatorio-cronograma.component';
import { IconsModule } from './core/icons/icons.module';
import { PlanejamentoPlanilhaOrcamentariaFormComponent } from './modules/engenharia/planejamento/planejamento-planilha-orcamentaria/planejamento-planilha-orcamentaria-form.component';
import { MedicaoPlanilhaOrcamentariaViewComponent } from './modules/engenharia/planilha-orcamentaria/medicao-planilha-orcamentaria/medicao-planilha-orcamentaria-view.component';
import { MenuContentComponent } from './core/page/menu-content/menu-content.component';
import { MinhaContaAssinaturaComponent } from './modules/user/minha-conta/minha-conta-assinatura.component';

const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
  imports: [
    BrowserModule,   
    FormsModule, 
    ScrollingModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    NgbModule,
    CurrencyMaskModule,
    AngularResizedEventModule,
    ReactiveFormsModule,
    ChartModule,
    IconsModule
  ],

  declarations: [
    AppComponent,
    FilterPipe,
    HeaderComponent,
    NavBarComponent,
    HomeComponent,
    SideBarComponent,
    FooterComponent,
    BreadcrumbComponent,
    NotFoundComponent,
    MessageComponent,
    LoadPageComponent,
    AlertComponent,
    AlertConfirmComponent,
    NotPermissionComponent,
    TemporaryMessageComponent,
    ImageModalComponent,

    UserComponent,
    LoginComponent,
    NovoUsuarioComponent,
    MinhaContaComponent,
    UsuariosEmpresaComponent,
    GerenciamentoAcessoComponent,
    LoginSiteComponent,
    ConfiguracaoComponent,
    EmpresaFormComponent,
    ConfiguracaoAdminComponent,
    CategoriaNivelComponent,
    CategoriaVirtualComponent,
    VinculoNivelComponent,
    FuncaoSistemaComponent,
    PerfilUsuarioComponent,
    PermissaoUsuarioComponent,
    DashboardComponent,
    DashboardCotacaoComponent,
    DashboardOrcamentoComponent,
    DashboardVendaComponent,
    DashboardEntregaComponent,
    ListPlanilhaOrcamentariaComponent,
    PlanilhaOrcamentariaFormComponent,
    ComposicaoListComponent,
    ComposicaoFormComponent,
    MaoDeObraFormComponent,
    InsumoFormComponent,
    GrupoServicoComponent,
    ViewItemDetailedComponent,
    ProfissionalFormComponent,
    EspecialidadeEngenheiroFormComponent,
    ProfissionalComponent,
    ObraFormComponent,
    ObraComponent,
    PlanilhaComponent,
    MedicaoPlanilhaOrcamentariaListComponent,
    MedicaoPlanilhaOrcamentariaComponent,
    PlanejamentoPlanilhaOrcamentariaComponent,
    PlanejamentoPlanilhaOrcamentariaListComponent,
    FeriadoFormComponent,
    DiarioObraComponent,
    DiarioObraFormComponent,
    DiarioObraListComponent,
    MaoDeObraPersonalizadaComponent,
    EquipamentoComponent,
    DiarioObraViewComponent,
    DiarioObraRelatorioComponent,
    LicitacaoComponent,
    RelatorioCurvaABCInsumoComponent,
    RelatorioCurvaABCServicoComponent,
    RelatorioOrcamentoSinteticoComponent,
    RelatorioOrcamentoMemoriaCalculoComponent,
    RelatorioOrcamentoAnaliticoComponent,
    RelatorioCronogramaComponent,
    PlanejamentoPlanilhaOrcamentariaFormComponent,
    MedicaoPlanilhaOrcamentariaViewComponent,
    MenuContentComponent,
    MinhaContaAssinaturaComponent
  ],

  providers: [
    RxJS_Services,
    /*{ provide: AppRoutingProviders, useClass: AuthenticationService }, */
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
export class InteractiveTable{

    _selected: boolean
    _selected_hover: boolean
    _textInputItemTipo: string
    _buscar: string
    _active_buscar: boolean  
    _index: number
    _par: boolean
    _detailed: boolean
    _state: EnumState
    _active: boolean

    
    static setProperties(model: InteractiveTable) {
        model._active_buscar = false; 
        model._buscar = ''; 
    }
    
    static set(list: any[]) {
        let x = 1
        list.forEach(c=>c._index = x++)
    }
    
}

export enum EnumState{
    none = 0,
    insert = 1,
    update = 2,
    delete = 3
}

import { UseFullService } from 'src/app/service/usefull/usefull.service'
import { LicitacaoFavorita } from './licitacaoFavorita'

export class Licitacao {
  public id: number
  public modalidade: string
  public objeto: string
  public numero: string
  public cidade: string
  public estado: string
  public orgao: string
  public telefone: string
  public email: string
  public link: string
  public HrAbertura: string
  public status: string
  public ramos: string
  public dataAbertura: Date
  public dataPublicacao: Date
  public dataCriacao: Date
  public referenciaSecundaria: any
  public referencia: any

  public anexos: string
  public arquivos: string
  public _arquivos: Array<ArquivosLicitacao>

  public favorito: LicitacaoFavorita

  public _link: string
  public static filtrarLink(link): boolean {
    return (link.match('bll') ||
      link.match('comprasnet') ||
      link.match('governamentais') ||
      link.match('licitacoes'));

  }



  static valida(data: Licitacao): Licitacao {
    if (!data.objeto) throw String('Descreva objeto!')
    if (!data.modalidade) throw String('Descreva modalidade!')
    if (!data.dataAbertura) throw String('Informe a data de abertura!')
    const model = new Licitacao()
    model.id = data.id
    model.modalidade = data.modalidade
    model.objeto = data.objeto
    model.numero = data.numero
    model.dataAbertura = data.dataAbertura
    model.cidade = data.cidade
    model.estado = data.estado
    model.dataPublicacao = data.dataPublicacao
    model.orgao = data.orgao
    model.telefone = data.telefone
    model.email = data.email
    model.link = data.link
    model.HrAbertura = data.HrAbertura
    model.status = data.status
    model.ramos = data.ramos
    model.referenciaSecundaria = data.referenciaSecundaria
    model.referencia = data.referencia
    model.dataCriacao = data.dataCriacao
    return model
  }

  static modalidades = [

    { value: 'AP', name: 'Audiência Pública', _index: 1, _selected: false, _confirSelected: false },
    { value: 'CP', name: 'Coleta de Preços', _index: 2, _selected: false, _confirSelected: false },
    { value: 'CPE', name: 'Compra Eletrônica', _index: 3, _selected: false, _confirSelected: false },
    { value: 'CR', name: 'Concorrência', _index: 4, _selected: false, _confirSelected: false },
    { value: 'CS', name: 'Convite', _index: 5, _selected: false, _confirSelected: false },
    { value: 'CV', name: 'Convite Shopping', _index: 6, _selected: false, _confirSelected: false },
    { value: 'DL', name: 'Dispensa de Licitação', _index: 7, _selected: false, _confirSelected: false },
    { value: 'LE', name: 'Leilão', _index: 8, _selected: false, _confirSelected: false },
    { value: 'PE', name: 'Pregão Eletrônico', _index: 9, _selected: false, _confirSelected: false },
    { value: 'PR', name: 'Pregão Presencial', _index: 10, _selected: false, _confirSelected: false },
    { value: 'RDC', name: 'Regime Diferenciado de Contratação', _index: 11, _selected: false, _confirSelected: false },
    { value: 'SM', name: 'Sem Modalidade', _index: 12, _selected: false, _confirSelected: false },
    { value: 'TP', name: 'Tomada de Preço', _index: 13, _selected: false, _confirSelected: false },

    /*
    { value: 'CR', name: 'Carta Registro', _index: 2, _selected: false, _confirSelected: false },
    { value: 'CD', name: 'Chamada Para Cadastramento', _index: 3, _selected: false, _confirSelected: false },
    { value: 'CP', name: 'Chamada Pública', _index: 4, _selected: false, _confirSelected: false },
    { value: 'CH', name: 'Chamamento Público', _index: 5, _selected: false, _confirSelected: false },
    { value: 'CPD', name: 'Compra Direta', _index: 6, _selected: false, _confirSelected: false },
    { value: 'COM', name: 'Compras Eletrônicas', _index: 8, _selected: false, _confirSelected: false },
    { value: 'CI', name: 'Concorrência Internacional', _index: 10, _selected: false, _confirSelected: false },
    { value: 'CO', name: 'Concurso', _index: 11, _selected: false, _confirSelected: false },
    { value: 'CE', name: 'Cotação Eletrônica', _index: 13, _selected: false, _confirSelected: false },
    { value: 'IN', name: 'Inexigibilidade', _index: 15, _selected: false, _confirSelected: false },
    { value: 'IR', name: 'Intencao Registro Preços', _index: 16, _selected: false, _confirSelected: false },
    { value: 'LRE', name: 'Lei de Responsabilidade Estatais', _index: 17, _selected: false, _confirSelected: false },
    { value: 'LR', name: 'Lei de Responsabilidade Estatais', _index: 18, _selected: false, _confirSelected: false },
    { value: 'LL', name: 'Leilão', _index: 19, _selected: false, _confirSelected: false },
    { value: 'NI', name: 'Não Informada', _index: 20, _selected: false, _confirSelected: false },
    { value: 'PR', name: 'Pregão', _index: 21, _selected: false, _confirSelected: false },
    { value: 'PA', name: 'Pregão Amplo', _index: 22, _selected: false, _confirSelected: false },
    { value: 'PE', name: 'Pregão Eletrônico', _index: 23, _selected: false, _confirSelected: false },
    { value: 'PP', name: 'Pregão Presencial', _index: 24, _selected: false, _confirSelected: false },
    { value: 'RD', name: 'Regime Diferenciado de Contratação', _index: 25, _selected: false, _confirSelected: false },
    { value: 'RP', name: 'Registro de Preços', _index: 26, _selected: false, _confirSelected: false },
    { value: 'TP', name: 'Tomada de Preços', _index: 27, _selected: false, _confirSelected: false }
    */
  ]
}

export class ArquivosLicitacao {
  public id: string
  public nome: string
  public extensao: string
}

export class buscarLicitacao {
  constructor() {
    this.resetFiltro()
  }
  public inicio: any
  public fim: any
  public estado: string
  public cidade: string
  public buscar: string
  public modalidade: string
  public tipoPeriodo: string
  public filtro: number
  public status: string

  public _inicio: string
  public _fim: string

  resetFiltro() {
    const data = new Date()
    const mes = data.getMonth()
    this.inicio = UseFullService.formatNgbDate(UseFullService.formatDateUsForm(new Date(data.setMonth(mes - 1))))
    this.fim = UseFullService.formatNgbDate(UseFullService.formatDateUsForm(new Date()))
    this.tipoPeriodo = 'dataPublicacao'
    this.modalidade = ''
    this.estado = ''
    this.filtro = 1
  }

  getListStatus() {
    const listStatus = [
      { value: 'Vigente', _index: 1, _selected: true, _confirSelected: true },
      { value: 'Urgente', _index: 2, _selected: true, _confirSelected: true },
      { value: 'Cancelada', _index: 3, _selected: true, _confirSelected: true },
      { value: 'Adiada', _index: 4, _selected: true, _confirSelected: true },
      { value: 'Suspensa', _index: 5, _selected: true, _confirSelected: true },
      { value: 'Prorrogada', _index: 6, _selected: true, _confirSelected: true },
      { value: 'Reabertura', _index: 7, _selected: true, _confirSelected: true },
      { value: 'Retificação', _index: 8, _selected: true, _confirSelected: true },
      { value: 'Alterada', _index: 9, _selected: true, _confirSelected: true },
      { value: 'Anulada', _index: 10, _selected: true, _confirSelected: true },
      { value: 'Revogada', _index: 11, _selected: true, _confirSelected: true },
      { value: 'Suspensa sine die', _index: 12, _selected: true, _confirSelected: true },
      { value: 'Adiada sine die', _index: 13, _selected: true, _confirSelected: true },
      { value: 'Leilão', _index: 14, _selected: true, _confirSelected: true }
    ]
    listStatus.sort((a, b) => {
      if (a.value > b.value) { return 1; }
      if (a.value < b.value) { return -1; }
      return 0;
    })
    return listStatus
  }
}



/*
          id: '3357225'
          ordem_servico: '0'
          hashid: 'dc1b02f7122a26d31e54dcea342c36b91a661c440a36104bf5e0ee8cc8eda4c4'
          ramos: 'INFO,INFP,TELE'
          sigla: 'PP'
          extenso: 'PREGÃO PRESENCIAL'
          chave: '22/2020'
          objeto: 'as'
          solicita_edital: '1'
          dtabertura: '01/06/2020'
          cidade: 'NOVA FRIBURGO'
          uf: 'RJ'
          dtpublicacao: '25/05/2020'
          declinado: 'não'
          agendado: 'não'
          visualizado: 'não'
          dias: '6'
          orgao: 'MUNICIPIO DE NOVA FRIBURGO RJ'
          telefone: '2225259100'
          email: null
          link: 'http://www.pmnf.rj.gov.br/licitacaoView/?id=90'
          list_agenda: null
          list_declinada: null
          list_monitorar: null
          HrAbertura: '14:00:00'
          idf: '3356177'
          analise: false
          stat: 'Alterado'
          itens: null
        */

/*
    "INFO,RODI,PLAS,ESCR,FERR,MADE,INFP,AGRI,ELET,EDIF,VEIM,COMB,CONT,FERA,MATC,MAQC,ODON,SINA,SANE,TELE,QUIM,LIMP,PAIS,LABO",
    try {
      page.click('.button-aqua')
      await page.waitForNavigation()
    } catch (error) {
      console.log('logou direto')
    }
    /*
    await page.evaluate(() => {
      const e = document.querySelector('.button-aqua')
      console.log(e)
    })
*/
// page.click('#button-aqua')

/*
    const result = await page.evaluate(() => {
      const books = []
      document.querySelectorAll('section > div > ol > li img')
        .forEach(book => books.push(book.getAttribute('alt')))
      return books
    })
*/
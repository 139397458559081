export class PlanejamentoPlanilhaOrcamentaria {
    public id: number
    public planilhaOrcamentariaId: number
    public status: number
    public observacao: string
    public diasNaoUteis: string
    public usuarioId: string
    public data: Date
    public _diasSemana: Array<any>

    static setDiasSemana(model: PlanejamentoPlanilhaOrcamentaria){
        model._diasSemana = [
            { id: 1, dia:'Segunda-feira', active:false},
            { id: 2, dia:'Terça-feira', active:false},
            { id: 3, dia:'Quarta-feira', active:false},
            { id: 4, dia:'Quinta-feira', active:false},
            { id: 5, dia:'Sexta-feira', active:false},
            { id: 6, dia:'Sábado', active:false},
            { id: 0, dia:'Domingo', active:false}
        ]
        model._diasSemana.forEach(c => { c.active = !(model.diasNaoUteis && model.diasNaoUteis.match(c.id))})
    }

    

    static updateDiasNaoUteis(model: PlanejamentoPlanilhaOrcamentaria, dia: any){        
        if(!model.diasNaoUteis)
            model.diasNaoUteis = ''

        if(dia.active)
            model.diasNaoUteis = model.diasNaoUteis.replace(dia.id,'')
        else if(!model.diasNaoUteis.match(dia.id))      
            model.diasNaoUteis += dia.id
    }
}

export enum EnumStatusPlanejamento{
    aberto = 0,
    update = 1,
    concluido = 2
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResizedEvent } from 'angular-resize-event';
import { TypeUser } from 'src/app/domain/usuario/Usuario';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { MenuSideBar } from 'src/app/domain/auxiliar/menuSideBar';
import { NavBarComponent } from '../nav-bar/nav-bar.component';

declare var $: any;
@Component({
  selector: 'app-sid-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent extends NavBarComponent {

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    setTimeout(() => {
      if(this.userLoggedService._obra)
        this.obra = this.userLoggedService._obra
      this.AtualizarSideBar();
      var _this = this
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/5c117a1e7a79fc1bddf0af89/default';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);        
        setTimeout(() => {
          $('[title="chat widget"]').css('left', 0)
          $($('[title="chat widget"]')[1]).contents().find('body').css("max-width", "50px")
          $($('[title="chat widget"]')[1]).contents().find('div').css("text-align", "center")          
          $($('[title="chat widget"]')[1]).contents().find('span').text("?")
          $($('[title="chat widget"]')[1]).contents().find('span').css('font-size','20px')
          $($('[title="chat widget"]')[1]).contents().find('div').css("margin", "0 auto")   
          $($('[title="chat widget"]')[1]).contents().find('div').css("background-color", "#242424")    
          
          $($('[title="chat widget"]')[2]).css('left', '25px')    
          $($('[title="chat widget"]')[2]).css('bottom', '34px')          
        }, 1000);
      })();
      UseFullService.SetMask()
    }, 50);
  }

  posicionarChat() {
    $(document).ready(function () {
      
    });
  }

  /*
  if (menu.subMenu != null && menu.subMenu.length > 0) {
    var subMenu = new MenuSideBar();;
    menu.subMenu.forEach(function (s, i) {
      subMenu = s as MenuSideBar;

      subMenu.autorizado = false;
      subMenu.access.forEach(e => {
        if (_this.userLoggedService.type === e)
          subMenu.autorizado = true;
      });

      subMenu.class = '';
      if (subMenu.route == url) {
        subMenu.class = 'active';
        menu.subClass = 'in';
        menu.class = 'active';
      }
      s = subMenu;
    });
  } else {
    if (menu.route == url)
      menu.class = 'active';
  }
  */

}

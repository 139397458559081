import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/core/global-variable';
import { AtendimentoUsuario } from 'src/app/domain/usuario/atendimentoUsuario';
import { AlertConfirmService } from '../alert-confirm.service';

@Injectable({ providedIn: 'root' })
export class AtendimentoUsuarioService {

    code = 'service-atendimento'
    public userId = 0
    private url = GlobalVariable.BASE_API_URL + "user/atendimento/";
    public listAtendimento = new Array<AtendimentoUsuario>();
    public atendimento = new AtendimentoUsuario();

    constructor(private http: HttpClient, private confirm: AlertConfirmService) {
        this.confirm.confirmEmitter$.subscribe(c => { this.excluir(c); });
    }

    public obterTodas(userId: number) {
        this.getAll(userId).subscribe(d => { this.listAtendimento = d })
    }

    public reset() {
        this.atendimento = new AtendimentoUsuario();
    }

    public actionEditar(data: AtendimentoUsuario) {
        this.atendimento = data;
    }

    public insertOrUpdate() {
        if (!this.atendimento.id) {
            this.atendimento.usuarioId = this.userId
            this.post(this.atendimento).subscribe(d => { this.obterTodas(this.userId); });
        }else
            this.put(this.atendimento).subscribe(d => { this.obterTodas(this.userId); });
    }

    getAll(id): Observable<AtendimentoUsuario[]> {
        this.userId = id
        return this.http.get<AtendimentoUsuario[]>(this.url + "all?id=" + id).pipe(
            map(res => { return res; })
        );
    }


    post(atendimento: AtendimentoUsuario): Observable<AtendimentoUsuario> {
        return this.http.post<AtendimentoUsuario>(this.url, atendimento).pipe(
            map(res => { return res; })
        );
    }

    put(atendimento: AtendimentoUsuario): Observable<any> {
        return this.http.put<any>(this.url, atendimento).pipe(
            map(res => {
                return res;
            })
        );
    }

    delete(id): Observable<any> {
        return this.http.delete<any>(this.url + "?id=" + id).pipe(
            map(res => {
                return res;
            })
        );
    }

    idAtendimento = 0
    confirmExcluir(data: AtendimentoUsuario) {
        this.idAtendimento = data.id;
        this.confirm._code = this.code
        this.confirm._title = "Excluir Observação De Atendimento";
        this.confirm._message = "Deseja excluir a observação do atendimento " + data.observacao + "?";
        this.confirm.Show();
    }

    excluir(confirm: boolean = false) {
        if (this.confirm._code != this.code || confirm == false) return;
        this.delete(this.atendimento).subscribe(d => { this.obterTodas(this.userId) });
    }

}
import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { CalssificacaoGenerica, ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { CategoriaNivelService } from 'src/app/service/suprimento/categoria-nivel.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { EspecificacaoProduto } from 'src/app/domain/suprimento/produto';
import { VinculoNivel } from 'src/app/domain/suprimento/vinculo-nivel';
import { VinculoNivelService } from 'src/app/service/suprimento/vinculo-nivel.service';

declare var $: any;
@Component({
  selector: 'app-vinculo-nivel',
  templateUrl: './vinculo-nivel.component.html',
  styleUrls: ['./vinculo-nivel.component.css']
})
export class VinculoNivelComponent implements OnInit {

  public _categorias: ProdutoCategoria[];
  public _categoria_niveis: ProdutoCategoriaNivel[];
  public _categoria_nivel: ProdutoCategoriaNivel;
  public _temp_categoria_nivel: ProdutoCategoriaNivel;
  public _produto: InsumoDoFornecedor;
  temp_vinculo_nivel = new VinculoNivel();

  private _code = "app-vinculo-nivel";
  public _selected_niveis: Array<SelectedNivel>;
  _niveis = CalssificacaoGenerica;
  keys(): Array<string> {
    var keys = Object.keys(this._niveis);
    return keys.slice(keys.length / 2);
  }


  constructor(
    private httpProduto: ProdutoService,
    private categoriaNivelService: CategoriaNivelService,
    private vinculoNivelService: VinculoNivelService, private confirmService: AlertConfirmService) {
  }

  ngOnInit() {
    this._categoria_nivel = new ProdutoCategoriaNivel();
    this._produto = new InsumoDoFornecedor();
    this.SetListaCatetorias();
    this.confirmService.confirmEmitter$.subscribe(c => this.RemoveRelacao(c));
  }

  SelectItem(nivel: ProdutoCategoriaNivel, item: EspecificacaoProduto) {
    nivel._selected = item.ger_id;
    var nivel_filho = ProdutoCategoriaNivel.ObterPorOrdem(this._categoria_niveis, nivel.order + 1);
    if (nivel_filho) {
      nivel_filho.nivel.forEach(e => { e._selecionado = false; });
      this.vinculoNivelService.GetAllNivel(nivel.id, item.ger_id).subscribe(
        (data) => {
          data.forEach(e => {
            nivel_filho.nivel.forEach(c => {
              if (e.filho_nivel_item_id == c.ger_id) {
                c._selecionado = true;
                c._temp_id_vinculo = e.id;
              }
            });
          });
        }
      );
    }
  }

  ChangeNivel(e, nivel: ProdutoCategoriaNivel, item: EspecificacaoProduto) {
    var nivel_pai = ProdutoCategoriaNivel.ObterPorOrdem(this._categoria_niveis, nivel.order - 1);
    
    var relacao = new VinculoNivel();
    relacao.nivel_id = nivel_pai.id;
    relacao.nivel_item_id = nivel_pai._selected;
    relacao.filho_nivel_id = nivel.id;
    relacao.filho_nivel_item_id = item.ger_id
    if (e.srcElement.checked) {
      if (relacao.Valida())
        this.Adicionar(relacao);
      else
        e.srcElement.checked = false;
    } else if (nivel_pai) {
      if (item._temp_id_vinculo > 0) {
        this.ClickRemoveAll(relacao);
      }
    }
  }

  OnChangeSearch() {
    this.categoriaNivelService.GetAll(this._categoria_nivel.categoria_id).subscribe(
      d => { this._categoria_niveis = d; }
    );
  }

  Adicionar(vinculoNivel: VinculoNivel) {
    this.vinculoNivelService.Create(vinculoNivel).subscribe();
  }

  Remover(nivel_id: number, nivel_item_id: number, filho_nivel_id: number, filho_nivel_item_id: number) {
    this.vinculoNivelService.SetDelete(nivel_id, nivel_item_id, filho_nivel_id, filho_nivel_item_id).subscribe();
  }

  RemoveRelacao(confirm: boolean) {
    if (this.confirmService._code != this._code || confirm == false)
      return;
    var o = this.temp_vinculo_nivel;
    this.Remover(o.nivel_id, o.nivel_item_id, o.filho_nivel_id, o.filho_nivel_item_id);
  }

  SetListaCatetorias() {
    this.httpProduto.getAllCategories().subscribe(
      data => { this._categorias = data; },
      error => { },
      () => { }
    );
  }

  ClickRemoveAll(data: VinculoNivel) {
    this.temp_vinculo_nivel = data;
    this.confirmService._code = this._code;
    this.confirmService._title = "Exclusão de Vinculo";
    this.confirmService._message = "Deseja excluir vinculo ?";
    this.confirmService.Show();
  }

  ReciverPesquisaProduto(produto: InsumoDoFornecedor): void {
    this._produto = produto;
    this._categoria_nivel.categoria_id = produto.categoria_id;
    this._categoria_nivel.produto_id = produto.id;
    this._categoria_nivel.nivel_id = 0;
    this.OnChangeSearch();
  }

  SelecionarTudoDoNivel(data: ProdutoCategoriaNivel){    
    data.nivel.forEach(e => {
      var checked = { srcElement : {checked: true}};
      if(!e._selecionado)
        this.ChangeNivel(checked, data, e);
    });  
  }

  SugerirProduto(): void {
    $('#pesquisa-insumo-modal').modal('show');
  }

  MouseLeaveListaVinculo(arg){
    alert(arg);
  }
}

export class SelectedNivel {
  public id: number;
  public nome: string;
  public active: boolean;
}
import { UserService } from '../../service/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/domain/usuario/Usuario';
import { LoadPageService } from 'src/app/service/load-page.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [UserService]
})
export class UserComponent implements OnInit {

  public usuario_selecionado: Usuario;
  public _page_permisao_or_usuario: boolean;
  constructor(private loadPageService: LoadPageService) { }

  ngOnInit() {
    this._page_permisao_or_usuario = false;
    this.usuario_selecionado = new Usuario();
  }
  
  reciverFeedback(respostaFilho) { 
    this.loadPageService.StartLoadPage();
    this.usuario_selecionado = respostaFilho;
    setTimeout(() => {      
      this.loadPageService.EndLoadPage();
      this.usuario_selecionado._load = false;
    }, 10);
    
  }

  AlterPage(mostrar_permissao: boolean){
    this._page_permisao_or_usuario = mostrar_permissao;
  }
}

import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UsuarioConfiguracao } from 'src/app/domain/usuario/usuarioConfiguracao';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Feriado } from 'src/app/domain/engenharia/planejamento/feriado';
import { Obra } from 'src/app/domain/engenharia/obra';

declare var $: any

@Component({
  selector: 'app-feriado-form',
  templateUrl: './feriado-form.component.html',
  styleUrls: ['./feriado-form.component.css']
})

export class FeriadoFormComponent implements OnInit {
  _msgParams = { titulo: '', descricao: '' }
  _code = 'app-feriado-form'
  listModel = new Array<Feriado>()
  tempModel = new Feriado()
  usuarioConfiguracao = new UsuarioConfiguracao()
  unidadesDeMedida = new Array<UnidadeMedida>()

  origens = [
    { id: 0, descricao: 'Nacional' },
    { id: 1, descricao: 'Obra' },
  ]

  constructor(private context: RepositorioService, public userLoggedService: UserLoggedInService, private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.getAll()
    this.getObra()
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }

  getAll() {
    this.context.path = 'feriado'
    this.context.getAll('empresaId=' + this.userLoggedService.empresaId).subscribe(
      d => {
        this.listModel = new Array<Feriado>()
        d.forEach(d => {
          this.listModel.push(d)
        })
      },
      e => { },
      () => { this.setPlanilha(1) })
  }

  listObra = new Array<Obra>()
  getObra() {
    this.context.path = 'obra'
    this.context.getAll().subscribe(d => { this.listObra = d; })
  }

  toggleCalendar() {
    UseFullService.TraduzirCalendario();
  }


  ChangeDate(item: Feriado) {
    setTimeout(() => {
      $('input#data-' + item._index).val(UseFullService.GetFormatDate(item._data.day, item._data.month, item._data.year));
      item.data = $('input#data-' + item._index).val();
      setTimeout(() => { this.onChange(item) }, 5);
    }, 10);
  }


  setDate(item: Feriado) {
    if (!item.data) return
    item._data = UseFullService.formatNgbDate(item.data)
    setTimeout(() => {
      $('input#data-' + item._index).val(UseFullService.formatDateString(item.data))
    }, 10);
  }


  mouseEnter(data: Feriado) {
    data._selected_hover = true
    UseFullService.StartTooltip()
  }

  trClick(data: Feriado) {
    this.listModel.forEach(c => {
      c._selected = (data == c) ? true : false
      if (!c._selected)
        Feriado.setProperties(c)
    })
  }

  focusInputDescricao(i) {
    setTimeout(() => {
      $('input#descricao-' + i).focus()
    }, 1);
  }

  removerRow(row: number) {
    this.listModel.splice((row - 1), 1)
    this.atualisarPlanilha(this.listModel)
  }

  addRow(row: number) {
    const tempList = new Array<Feriado>()
    const data = new Feriado()

    for (let i = 0; i < row; i++)
      tempList.push(this.listModel[i])

    tempList.push(data)

    setTimeout(() => {
      const countList = this.listModel.length
      for (let i = row; i < countList; i++) {
        tempList.push(this.listModel[i])

        if (i == countList - 1) {
          this.listModel = tempList
          this.atualisarPlanilha(this.listModel)
        }
      }
    }, 11);
  }

  mouseLeave(data: Feriado) {
    data._selected_hover = false
    UseFullService.StartTooltip()
  }

  setPlanilha(x: number, atualisarPlanilha = true) {
    for (let i = 0; i < x; i++) {
      const model = new Feriado()
      this.listModel.push(model)
      if (i == x - 1 && atualisarPlanilha)
        this.atualisarPlanilha(this.listModel);
      else {
        model._index = this.listModel.length
        model._par = Number.isInteger((model._index + 1) / 2)
      }
    }
  }

  atualisarPlanilha(list: Array<any>) {
    var x = 1
    list.forEach(c => {
      c._index = x++
      c._par = Number.isInteger((x + 1) / 2)
      this.setDate(c)
    })
  }

  onChange(item: Feriado) {
    item.data = $('input#data-' + item._index).val()
    if (item.origem < 1)
      item.origemId = 0
    else if (item.origemId < 1)
      throw ("Selecione uma obra ?")

    Feriado.valida(item)
    this.context.path = 'feriado'
    if (item.id > 0)
      this.context.put(item).subscribe();
    else
      this.context.post(item).subscribe(d => {
        item.id = d.id
        this.atualizarCampos(item)
        if (item._index == this.listModel.length)
          this.setPlanilha(1, false)
      })
  }

  atualizarCampos(item: Feriado) {
    if (!item.origem)
      item.origem = 0
  }

  buscarItem(data: Feriado) {
    data._active_buscar = true;
  }

  setScroll() {
    $(".table-recurse").scroll(function (e) {
      $(".table-recurse thead").css('margin-left', '-' + e.currentTarget.scrollLeft + 'px')
    });
  }

  tempIndex = 0
  confirmDelete(model: Feriado, row: number): void {
    this.tempModel = model;
    this.tempIndex = row
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Feriado";
    this.confirmService._message = "Deseja excluir feriado: <b>" + model.descricao + "</b>?";
    this.confirmService.Show();
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return;
    this.context.path = 'feriado'
    this.context.delete(this.tempModel.id).subscribe(
      d => { },
      e => { },
      () => { this.removerRow(this.tempIndex) }
    );
  }

}

import { Endereco } from '../auxiliar/endereco';
import { InteractiveTable } from '../common/interactiveTable';

export class Profissional extends InteractiveTable{
    public id: number
    public empresaId: number
    public nome: string
    public cpf: string
    public rg: string
    public crea: string
    public dataNascimento: Date
    public telefone: string
    public email: string
    public especialidade: string
    public enderecoId: number
    public data: Date
    public endereco: Endereco
}

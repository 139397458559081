import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { retry } from 'rxjs/operators';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UseFullService {
  constructor() { }

  static getDateUs(br: string): Date { return new Date(br.split('/').reverse().join('-') + 'T00:00:00') }

  static monthInBetween(d1: Date, d2: Date, d3: Date): boolean {
    return (d1 <= d3 && d2 >= d3)
  }

  static monthIsYearIsEqual(d1: Date, d2: Date): boolean {
    return ((d1.getMonth() + '/' + d1.getFullYear()) === (d2.getMonth() + '/' + d2.getFullYear()))
  }

  static getLastDayMonth(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth() + 1, 0);
  }

  static getDaysDates(inicio: Date, fim: Date): number {
    const diff = Math.abs(inicio.getTime() - fim.getTime())
    return Math.ceil(diff / (1000 * 60 * 60 * 24)) + 1
  }

  static getMonthsDates(d1: Date, d2: Date): number {
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();
    return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
  }


  static formatDate(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return dia + "/" + mes + "/" + ano;
  }
  static formatDateDiaMes(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return dia + "/" + mes;
  }

  static formatDateUS(data_br: string) {
    var data = data_br.trim().split('/');
    if (data.length < 3)
      return null
    var dia = data[0].padStart(2, '0');
    var mes = data[1].padStart(2, '0');
    var ano = data[2];
    return ano + "-" + mes + "-" + dia;
  }

  static formatDateUSInt(data_br: string) {
    var data = data_br.trim().split('/');
    var dia = data[0].padStart(2, '0');
    var mes = data[1].padStart(2, '0');
    var ano = data[2];
    return ano + "" + mes + "" + dia;
  }

  static formatDateUSTime(data_br: string) {
    var data = data_br.trim().split('/');
    var dia = data[0].padStart(2, '0');
    var mes = data[1].padStart(2, '0');
    var ano = data[2];
    return new Date(ano + "-" + mes + "-" + dia + 'T00:00:00');
  }

  static formatDateUsForm(data: Date) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return ano + "-" + mes + "-" + dia;
  }

  static formatDateString(dataString: string) {
    var data = new Date(dataString);
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    return dia + "/" + mes + "/" + ano;
  }


  static formatNgbDateStruct(data: NgbDateStruct): Date {
    var _data = this.formatDateUS(data.day + "/" + data.month + "/" + data.year);
    return new Date(_data);
  }

  static formatNgbDate(data: any): NgbDate {
    if (!data) { return null }
    var d = data.split('-')
    return new NgbDate(parseInt(d[0]), parseInt(d[1]), parseInt(d[2]));
  }

  static formatNgbDateBr(data: string): NgbDate {
    if (!data) { return null }
    var d = data.split('/')
    return new NgbDate(parseInt(d[2]), parseInt(d[1]), parseInt(d[0]));
  }

  static formatNgbDateStructString(data: NgbDateStruct): string {
    return this.formatDateUS(data.day + "/" + data.month + "/" + data.year);
  }
  static formatNgbDateStructStringBr(data: NgbDateStruct): string {
    return data.day + "/" + data.month + "/" + data.year;
  }
  static formatNgbDateStructDate(data: NgbDateStruct): Date {
    return new Date(data.year + "-" + data.month + "-" + data.day + ' 00:00:00');
  }

  static formatDateTime(data: Date = new Date()) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    var hh = data.getHours().toString().padStart(2, '0');
    var mm = data.getMinutes().toString().padStart(2, '0');;
    var ss = data.getSeconds().toString().padStart(2, '0');;
    return dia + "/" + mes + "/" + ano + " " + hh + ":" + mm + ":" + ss;
  }

  static formatDateTimeUS(data: Date = new Date()) {
    var dia = data.getDate().toString().padStart(2, '0');
    var mes = (data.getMonth() + 1).toString().padStart(2, '0');
    var ano = data.getFullYear();
    var hh = data.getHours().toString().padStart(2, '0');
    var mm = data.getMinutes().toString().padStart(2, '0');;
    var ss = data.getSeconds().toString().padStart(2, '0');;
    return ano + "-" + mes + "-" + dia + " " + hh + ":" + mm + ":" + ss;
  }

  static GetFormatDate(dia: number, mes: number, ano: number) {
    var dd = dia.toString().padStart(2, '0');
    var mm = mes.toString().padStart(2, '0');
    return dd + "/" + mm + "/" + ano;
  }

  static OneToLowerCase(text: string): string {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
  }

  static CalcularPaginacao(parcial: number, total: number): number {
    var r = Math.ceil(total / parcial);
    return r;
  }

  static RemoveArray(list: any[], data: any) {
    var count = list.length;
    for (let i = 0; i < count; i++) {
      if (list[i] == data)
        list.splice(i, 1);
    }
  }

  static TraduzirCalendario() {
    $("option[aria-label='January']").text("Jan");
    $("option[aria-label='February']").text("Fev");
    $("option[aria-label='March']").text("Mar");
    $("option[aria-label='April']").text("Abr");
    $("option[aria-label='May']").text("Mai");
    $("option[aria-label='June']").text("jun");
    $("option[aria-label='July']").text("Jul");
    $("option[aria-label='August']").text("Ago");
    $("option[aria-label='September']").text("Set");
    $("option[aria-label='October']").text("Out");
    $("option[aria-label='November']").text("Nov");
    $("option[aria-label='December']").text("Dez");

    $(".ngb-dp-weekdays div").each(function (index) {
      var text = '';
      switch ($(this).text().trim()) {
        case 'Mo': text = 'Seg'; break;
        case 'Tu': text = 'Ter'; break;
        case 'We': text = 'Qua'; break;
        case 'Th': text = 'Qui'; break;
        case 'Fr': text = 'Sex'; break;
        case 'Sa': text = 'Sab'; break;
        case 'Su': text = 'Dom'; break;
      }
      $(this).text(text);
    });

    $('.ngb-dp-arrow').click(() => {
      atualizarGridData();
    });

    function atualizarGridData() {
      if ($($(".ngb-dp-week")[6]).text().trim() == '')
        $($(".ngb-dp-week")[6]).hide()
      else
        $($(".ngb-dp-week")[6]).show()
    }
    atualizarGridData();
  }

  static ToFixed(value): string {
    if (!value)
      return '0,00'
    let data = parseFloat(value).toLocaleString();
    const casa = data.split(',')
    if (casa.length > 0) {
      if (parseFloat(casa[1]) < 10 && casa[1].length == 1)
        data = data + '0'
    }
    return data.match(',') ? data : data + ',00'
  }

  static ToFixedString(value, digitos = 2, max = undefined, retunUndefined = false): string {
    if (!value && retunUndefined)
      return ''
    if (!value)
      return '0,00'
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: digitos, maximumFractionDigits: max })
  }

  static DecimalEquals(v1, v2): boolean {
    return (parseFloat(v1).toFixed(2) === parseFloat(v2).toFixed(2))
  }

  static ToDecimalString(value): string {
    if (!value)
      return '0.00'
    return String(value).replace('.', '').replace('.', '').replace('.', '').replace(',', '.');
  }

  static ConvertFloat(v) { return parseFloat(this.ToDecimalString(v)) }

  static StartTooltip() {
    $(".tooltip.show").removeClass('show')
    setTimeout(() => {
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      })
    }, 100);
  }

  static startButtonPopOver(a: boolean) {
    if (a)
      $('.documento-background').css('display', 'block')
    else {
      $('.documento-background').css('display', 'none')
      $('[data-toggle="popover"]').popover('hide')
    }
  }

  static showMsgModal() {
    $('#message-modal').modal('show')
  }

  static removeAccent(v) {
    v = v.toLowerCase();
    v = v.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    v = v.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    v = v.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    v = v.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    v = v.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    v = v.replace(new RegExp('[Ç]', 'gi'), 'c');
    return v;
  }

  static getMonthString(x): string {
    switch (x) {
      case 0: return 'Jan'
      case 1: return 'Fev'
      case 2: return 'Mar'
      case 3: return 'Abr'
      case 4: return 'Mai'
      case 5: return 'jun'
      case 6: return 'Jul'
      case 7: return 'Ago'
      case 8: return 'Set'
      case 9: return 'Out'
      case 10: return 'Nov'
      case 11: return 'Dez'
      default: return null
    }
  }

  static SetMask(time = 200) {
    setTimeout(() => {
      $(".tooltip.show").removeClass('show')
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      })
      $("#cep").mask("99999-999"); 
      $("#telefone").mask("(99) 9 9999-9999");
      $("#data").mask("99/99/9999");
      $("#numero").mask("999999999999999999999999");
      $("#cpf").mask("999.999.999-99");
      $("#_cpf").mask("999.999.999-99");
      $("#cnpj").mask("99.999.999/9999-99");
    }, time);
  }



}
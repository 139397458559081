import { InteractiveTable } from '../common/interactiveTable'

export class DiarioObra extends InteractiveTable {    
    public id: number
    public obraId: number
    public usuarioId: number
    public empresaId: number
    public codigo: string
    public ocorrencia: string
    public prazoContratual: number
    public diasDecorrigo: number
    public diasAVencer: number
    public dataRelatorio: string
    public data: string
    /*
        clima = "0.0.0.0.0:0.0.0.0.0:0.0.0.0.0"
        1°=turno, 2°=condicao, 3°=tempo1, 4°=tempo2, 5°=tempo3,  
    */
    public clima: string
    public _turno: any
    public _dataRelatorio: any
    
    public listmaodeobra: Array<MaoObraDiarioObra>
    public listequipamento: Array<EquipamentoDiarioObra>
    public listImage: Array<ImagensDiarioObra>

    static setClimaPardao(model: DiarioObra){
        model.clima = "1.1.1.0.0:1.1.1.0.0:0.0.0.0.0"
    }

    static setTurnos = (model: DiarioObra) => {
        model._turno = DiarioObra.getTurnos()
        const actives = []
        model.clima.split(':').forEach(c => { actives.push(c.split('.')) })

        for (let i = 0; i < actives.length; i++) {
            const c = actives[i];
            model._turno[i].active = c[0] // turno
            model._turno[i].condicao = c[1] // condições prativel / impraticável
            model._turno[i].tempo[0].active = c[2] // tempo claro
            model._turno[i].tempo[1].active = c[3] // tempo nublado
            model._turno[i].tempo[2].active = c[4] // tempo chuvoso
        }
    }

    static updateTurnos = (model: DiarioObra) => {
        model.clima = ''
        model._turno.forEach(e => {
            model.clima += e.active == 1 ? '1.' : '0.'
            model.clima += e.condicao == 1 ? '1.' : '0.'
            model.clima += e.tempo[0].active == 1 ? '1.' : '0.'
            model.clima += e.tempo[1].active == 1 ? '1.' : '0.'
            model.clima += e.tempo[2].active == 1 ? '1' : '0'
            model.clima += (e.name == model._turno[2].name) ? '':':'
        });
    }

    static getTurnos = () => {
        return [
            {
                "active": 0, "name": "Manhã", "condicao": 0, "tempo": [
                    { "ative": 0, "name": "Claro" },
                    { "ative": 0, "name": "Nublado" },
                    { "ative": 0, "name": "Chuvoso" }],
            },
            {
                "active": 0, "name": "Tarde", "condicao": 0, "tempo": [
                    { "ative": 0, "name": "Claro" },
                    { "ative": 0, "name": "Nublado" },
                    { "ative": 0, "name": "Chuvoso" }],
            },
            {
                "active": 0, "name": "Noite", "condicao": 0, "tempo": [
                    { "ative": 0, "name": "Claro" },
                    { "ative": 0, "name": "Nublado" },
                    { "ative": 0, "name": "Chuvoso" }],
            }
        ]
    }
}

export class ImagensDiarioObra extends InteractiveTable {
    public id: number
    public usuarioId: number
    public diarioObraId: number
    public nome: string
    public descricao: string
    public data: Date

    public _file: File
    public _maxWidth: number
    public _small: string
}

export class EquipamentoDiarioObra extends InteractiveTable {
    public id: number
    public usuarioId: number
    public diarioObraId: number
    public itemId: number
    public descricao: string
    public quantidade: string
}

export class MaoObraDiarioObra extends InteractiveTable {
    public id: number
    public usuarioId: number
    public diarioObraId: number
    public itemId: number
    public descricao: string
    public quantidade: string
}
import { UsuarioConfiguracao } from '../../usuario/usuarioConfiguracao'

export class ValorInsumoEstado {
  public id: number
  public insumoId: number
  public estado: string
  public valor: number

  static getAll(usuarioConfiguracao: UsuarioConfiguracao, insumoId: number = 0): Array<ValorInsumoEstado> {
    const listEstados = usuarioConfiguracao.sequenciaEstadoInsumo.split(':')
    const listModel = new Array<ValorInsumoEstado>()
    listEstados.forEach(c => {
      const model = new ValorInsumoEstado()
      model.insumoId = insumoId
      model.estado = c
      listModel.push(model)
    });
    return listModel
  }

  public validar() {
    if (this.insumoId < 1)
      throw ('Não há insumo!')
    if (!this.estado && this.estado.length > 2)
      throw ('Descrição não encontrada!')
  }
}

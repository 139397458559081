import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { AlertService } from 'src/app/service/alert.service';
import { LoadPageService } from 'src/app/service/load-page.service';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';

@Injectable()
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;
    constructor() {
        this.requestInFlight$ = new BehaviorSubject(false);
    }
    setHttpStatus(inFlight: boolean) {
        this.requestInFlight$.next(inFlight);
    }
    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
    private _requests = 0;
    constructor(private status: HTTPStatus, private router: Router, public alertService: AlertService, private loadPageService: LoadPageService, private authService: AuthenticationService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        ++this._requests;

        this.status.setHttpStatus(true);

        this.loadPageService.StartLoadPage();

        return next.handle(req).pipe(
            map(event => {
                if(req.method != 'GET')
                    this.alertService.ChecksMessageSuccess(event as EventProperties)
                return event
            }),

            catchError(error => {
                var msg = error.error;
                if (error.status === 401)
                    msg = "Acesso negado!";
                else if (error.status === 0)
                    msg = "Sem conexão!";
                else if (error.error === "403")
                    this.authService.logout();

                this.alertService.SetMessageError(msg);
                return throwError(error);
            }),
            finalize(() => {
                --this._requests;
                this.status.setHttpStatus(this._requests > 0);
                this.loadPageService.EndLoadPage();
            })
        );
    }
}

export class EventProperties {
    public body: any;
    public heades: any;
    public ok: boolean;
    public status: number;
    public statusText: string;
    public type: number;
    public url: string;
}
import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { GrupoDeServico } from 'src/app/domain/engenharia/composicao/grupoDeServico';
import { Composicao } from 'src/app/domain/engenharia/composicao/composicao';
import { Estado } from 'src/app/domain/enum/estado';
import { table } from 'src/app/domain/common/table';
import { Router } from '@angular/router';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any
@Component({
  selector: 'app-composicao-list',
  templateUrl: './composicao-list.component.html',
  styleUrls: ['./composicao-list.component.css']
})
export class ComposicaoListComponent implements OnInit {
  _code = 'app-composicao-list'
  _msgParams = { titulo: '', descricao: ''}
  model = new Composicao()
  listComposicao = new Array<Composicao>()
  unidadesDeMedida = new Array<UnidadeMedida>()
  listGrupoServico = new Array<GrupoDeServico>()
  listSubGrupoServico = new Array<GrupoDeServico>()
  listEstado = new Array<Estado>()
  
  busca = new Composicao()
  _estado = '';

  constructor(private context: RepositorioService, private router: Router, public userLoggedService: UserLoggedInService, private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser(); 
    this.busca.setNewComposicao()
  }

  ngOnInit() {
    this._estado = this.userLoggedService.uf
    this.onResize(this)
    this.getUnidadesDeMedida()
    this.grupoServico()
    this.getEstados()
    UseFullService.StartTooltip()
    this.confirmService.confirmEmitter$.subscribe(c => { this.delete(c) })   
  }

  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => { 
      this.unidadesDeMedida = d;    
      this.get()
   })
  }

  grupoServico() {
    this.context.path = 'grupo-de-servico'
    this.context.getAll("hierarquia=0").subscribe(d => { this.listGrupoServico = d })
  }

  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => { this.listEstado = d })
  }

  subGrupoServico(id) {
    this.context.path = 'grupo-de-servico'
    this.context.getAll("hierarquia=" + id).subscribe(d => { this.listSubGrupoServico = d })
  }

  onChangeGrupo(id) {
    this.onChange()
    this.busca.subGrupoId = 0
    setTimeout(()=>{
      if(id){ this.subGrupoServico(id) }
    },5)
  }
  
  onChangeEstado() {
    this.userLoggedService.setUf(this._estado)
    //this.getAllValores(this._estado)
  }

  onResize(event) {
    setTimeout(() => {
      $("th.descricao").width($("td.descricao").width())
    }, 50);
  }

  trClick(data: Composicao) {
  }

  get() {
    this.context.path = 'composicao'
    this.context.getAll(JSON.stringify(this.busca)).subscribe(d => { 
      this.listComposicao = d;
      this.onResize(event)       
      this.refresh(this.listComposicao)
    })
  }

  refresh(list: Array<Composicao>) {    
    this.getComposicaoValor()
    UnidadeMedida.setModel(list, this.unidadesDeMedida)
  }

  getComposicaoValor() {
    //throw new Error("Method not implemented.");
  }

  onChange() {
    setTimeout(() => { this.get() }, 1)
  }

  gerenciarComposicao(model: Composicao) {
    this.router.navigate(['/composicao/form/', model.id]);
  }


  confirmDelete(model: Composicao): void {
    this.context.path = 'composicao/halink'
    this.context.get(model.id).subscribe(
      d => {
        if (d) {
          const composicao = d as Array<Composicao>
          this._msgParams.titulo = 'ESTA MÃO DE OBRA NÃO PODE SER EXCLUÍDA'
          this._msgParams.descricao = 'Há ' + d.length + ' composiç' + ((d.length > 1) ? 'ões' : 'ão') + ' dependente' + ((d.length > 1) ? 's' : '') + ' da composição <b>' + model.descricao + '</b>.<br /><br />'
          composicao.forEach(c => {
            this._msgParams.descricao += '<a href="' + GlobalVariable.HOST + '/#/composicao/form/' + c.id + '">' + c.codigo + ' - ' + c.descricao + '</a> <br />'
          });
          this._msgParams.descricao += '<br />'
          UseFullService.showMsgModal()
        } else {
          this.model = model;
          this.confirmService._code = this._code;
          this.confirmService._title = "Excluir Composição";
          this.confirmService._message = "Deseja excluir composição: <b>" + model.descricao + "</b>?";
          this.confirmService.Show();
        }
      }
    )
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return;
    this.context.path = 'composicao'
    this.context.delete(this.model.id).subscribe(
      d => { },
      e => { },
      () => { this.get() }
    );
  }

}

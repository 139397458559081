import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Router, ActivatedRoute } from '@angular/router';
import { ViaCepService, ViaCep } from 'src/app/service/usefull/viacep.service';
import { EnumService } from 'src/app/service/usefull/enum.service';
import { Estado } from 'src/app/domain/enum/estado';
import { table } from 'src/app/domain/common/table';
import { Municipio } from 'src/app/domain/enum/municipio';
import { Endereco } from 'src/app/domain/auxiliar/endereco';
import { Obra, RelacaoObraProfissional } from 'src/app/domain/engenharia/obra';
import { Profissional } from 'src/app/domain/administracao/profissional';

declare var $: any

@Component({
  selector: 'app-obra-form',
  templateUrl: './obra-form.component.html',
  styleUrls: ['./obra-form.component.css']
})
export class ObraFormComponent implements OnInit {
  _msgParams = { titulo: '', descricao: '' }
  _code = 'app-obra-form'
  listModel = new Array<Obra>()
  model = new Obra()

  _estados = new Array<Estado>()
  _municipios = new Array<Municipio>()
  _viacep = new ViaCep()

  _profissionais = new Array<RelacaoObraProfissional>()

  id = this.route.snapshot.params['id'];
  buscar_profissional: string;
  constructor(
    private enumService: EnumService,
    private viaCepService: ViaCepService,
    private router: Router,
    private route: ActivatedRoute,
    private context: RepositorioService,
    public userLoggedService: UserLoggedInService,
    private confirmService: AlertConfirmService
  ) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.model.endereco = new Endereco()
    this.getEstados();
    this.JqueryEnvet()
    if (this.id)
      this.get()
    else
      this.getProfissionais()
  }

  get() {
    this.context.path = 'obra'
    this.context.get(this.id).subscribe(d => {
      if (d && d.id) {
        this.model = d
        Obra.formatData(this.model)
        if (this.model.profissionais && this.model.profissionais.length > 0)
          this.model.profissionais.forEach(c => { c._active = true; });
        if (this.model.enderecoId)
          this.getEndereco(this.model.enderecoId)
      }
      this.getProfissionais()
    })
  }

  getEndereco(id) {
    this.context.path = 'common'
    this.context.get(id, String(table.endereco)).subscribe(d => {
      if (d) {
        this.model.endereco = d
        this.getMunicipios(this.model.endereco.estado, false)
      }
    })
  }

  getProfissionais() {
    this.context.path = 'profissional'
    this.context.getAll().subscribe(d => {
      this._profissionais = d
      this._profissionais.forEach(c => {
        if (this.model.profissionais)
          this.model.profissionais.forEach(p => {
            if (p.profissionalId === c.id) {
              c.profissionalId = c.id
              c._active = true
            }
          })
      })
    })
  }

  voltar() { this.router.navigate(['/obra']) }

  insertOrUpdate() {
    this.context.path = 'obra'

    const municipioPorCodigo = (o) => { return (o.Codigo == this.model.endereco.cidade) }
    this.model.endereco.cidadeNome = this._municipios.filter(municipioPorCodigo)[0].Nome

    if (this.model.id)
      this.context.put(this.model).subscribe()
    else
      this.context.post(this.model).subscribe(d => this.router.navigate(['/obra/form/' + d.id]))
  }

  buscandoProfissionais = false
  onChangeProfissional() { setTimeout(() => { this.buscandoProfissionais = true }, 1) }
  finalizarBuscaProfissional() { this.buscandoProfissionais = false; this.buscar_profissional = String() }
  novoProfissional() { $("#cp-modal").modal('show') }

  selectProfissional(profissional: RelacaoObraProfissional) {
    this.onChangeProfissional()
    if (profissional.profissionalId)
      return

    profissional.profissionalId = profissional.id
    if (this.model.id)
      profissional.obraId = this.model.id

    if (!this.model.profissionais)
      this.model.profissionais = new Array<RelacaoObraProfissional>()
    profissional._active = true

    const haNaList = (obj) => { return (obj.profissionalId === profissional.id) }
    const data = this.model.profissionais.filter(haNaList)
    if (!(data && data.length > 0)) {
      this.model.profissionais.push(profissional)
    }
  }

  removerProfissional(profissional: RelacaoObraProfissional) {
    for (let i = 0; i < this.model.profissionais.length; i++) {
      if (this.model.profissionais[i].profissionalId === profissional.profissionalId) {
        this.model.profissionais[i]._active = false
      }
    }
    profissional._active = false
    profissional.profissionalId = 0
    profissional.obraId = 0
  }

  getAllProfissionais() {
    this.context.path = 'profissional'
    this.context.getAll().subscribe(d => { this._profissionais = d; })
  }

  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => { this._estados = d; })
  }

  OnChangeEstado() {
    setTimeout(() => { this.getMunicipios(this.model.endereco.estado); }, 1);
  }

  getMunicipios(uf: string, via_cep: boolean = true) {
    this.context.path = 'common'
    this.context.getAll("Uf='" + uf.trim() + "'", String(table.municipio)).subscribe(d => {
      this._municipios = d;
      if (via_cep)
        this.SetCidadeViaCep();
    })
  }

  OnChangeCep() {
    this.LimparInputs();
    setTimeout(() => {
      if (this.model.endereco.cep != undefined && this.model.endereco.cep.length == 9)
        this.GetCep();
    }, 10);
  }

  GetCep() {
    this.viaCepService.Get(this.model.endereco.cep).subscribe(
      d => { this._viacep = d; },
      e => { },
      () => { this.SetEndereco(); }
    );
  }

  SetEndereco() {
    if (this._viacep.erro)
      return;
    this.model.endereco.bairro = this._viacep.bairro;
    this.model.endereco.endereco = this._viacep.logradouro + " " + this._viacep.complemento;
    this.model.endereco.estado = this._viacep.uf;
    this.getMunicipios(this._viacep.uf);
  }

  LimparInputs() {
    this.model.endereco.bairro = "";
    this.model.endereco.endereco = "";
    this.model.endereco.estado = "";
    this.model.endereco.cidade = "";
  }

  SetCidadeViaCep() {
    this.model.endereco.cidade = this._viacep.ibge;
  }

  JqueryEnvet() {
    const _this = this
    setTimeout(() => {
      $("#cep").mask("99999-999");
      $("#telefone").mask("(99) 9 9999-9999");
      $("#data").mask("99/99/9999");
      $("#dataFim").mask("99/99/9999");
      $("#numero").mask("999999999999999999999999");
      $("#cpf").mask("999.999.999-99");
      $("#cp-modal").on('hide.bs.modal', function () {
        _this.getProfissionais()
      });
    }, 1500);
  }
}

import { Component, OnInit } from '@angular/core';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { Pagination } from 'src/app/domain/common/pagination';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Licitacao, buscarLicitacao } from 'src/app/domain/licitacao/licitacao';
import { Estado } from 'src/app/domain/enum/estado';
import { table } from 'src/app/domain/common/table';
import { NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from 'src/app/core/injectable/NgbDateFRParserFormatter';
import { LicitacaoFavorita } from 'src/app/domain/licitacao/licitacaoFavorita';
import { Municipio } from 'src/app/domain/enum/municipio';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any;
@Component({
  selector: 'app-licitacao',
  templateUrl: './licitacao.component.html',
  styleUrls: ['./licitacao.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class LicitacaoComponent implements OnInit {

  listModel = new Pagination()
  listEstado = new Array<Estado>()
  listCidade = new Array<Municipio>()
  modalidades = Licitacao.modalidades
  params = new buscarLicitacao()
  listStatus = []
  hostApi = GlobalVariable.BASE_API_URL
  constructor(private _context: RepositorioService) { }

  ngOnInit() {
    this.getEstados()
    this.getAll()
    window.addEventListener('scroll', this.scroll, true);
    this.listStatus = this.params.getListStatus()
    this.confirmSelectStatus()
    this.statusTodas = true
  }

  toggleCalendar() {
    UseFullService.TraduzirCalendario();
    //$('.input-data-fim ngb-datepicker').css('left', '-80px')
    //console.log($('.input-data-fim ngb-datepicker').css('left'))
    //document.querySelector('.input-data-fim .dropdown-menu').attributes.getNamedItem('style').value = 'left: -80px'
  }

  onDateSelectionFim() { this.getAll(true) }
  onDateSelectionInicio() { this.getAll(true) }
  onChangeEstado() { this.getAll(true) }
  onChangeFiltrar(filtro: number) {
    if (this.params.filtro != filtro) {
      this.params.filtro = filtro
      this.getAll(true)
    }
  }

  getEstados() {
    this._context.path = 'common'
    this._context.getAll(String(), String(table.estado)).subscribe(
      d => { this.listEstado = d },
      e => { },
      () => {
        let x = 0

        this.listEstado.sort((a, b) => {
          if (a.uf > b.uf) { return 1; }
          if (a.uf < b.uf) { return -1; }
          return 0;
        })

        this.listEstado.forEach(c => {
          c._index = x++
          c._selected = false
        })
      }
    )
  }

  getCidade() {
    this._context.path = 'common'
    this._context.getAll(this.params.estado, String(table.municipio)).subscribe(
      d => { this.listCidade = d },
      e => { },
      () => {
        let x = 0

        this.listCidade.sort((a, b) => {
          if (a.Nome > b.Nome) { return 1; }
          if (a.Nome < b.Nome) { return -1; }
          return 0;
        })

        this.listCidade.forEach(c => {
          c._index = x++
          c._selected = false
        })
      }
    )
  }

  page = 0
  carregando = false
  sActive = false
  getAll(n = false) {

    this.carregando = true
    if (n) {
      this.listModel = new Pagination()
      this.page = 0
    }
    this._context.path = 'licitacao'

    this.params.cidade = (this.countSelectCidade > 0) ? this.params.cidade : String()
    this.params.estado = (this.countSelectEstado > 0) ? this.params.estado : String()
    this.params.modalidade = (this.countSelectModalidade > 0) ? this.params.modalidade : String()
    this.params.status = (this.countSelectStatus > 0) ? this.params.status : String()

    this.params._inicio = UseFullService.formatNgbDateStructString(this.params.inicio)
    this.params._fim = UseFullService.formatNgbDateStructString(this.params.fim)
    this.sActive = false
    this._context.getAll(this.page, JSON.stringify(this.params)).subscribe(d => {
      if (d._list && d._list.length > 0) {
        const haList = (this.listModel._list && this.listModel._list.length > 0)
        d._list.forEach(c => {
          c._dataAbertura = UseFullService.formatDateString(c.dataAbertura)
          c._dataPublicacao = UseFullService.formatDateString(c.dataPublicacao)
          if (!c.favorito) {
            c.favorito = new LicitacaoFavorita()
            c.favorito.licitacaoId = c.id
          }
          if (haList)
            this.listModel._list.push(c)
        })
        if (!haList)
          this.listModel = d

        if (d._list && d._list.length >= 10) {
          setTimeout(() => {
            this.loadingScroll = true
          }, 1);
        }

        let x = 1
        for (let i = 0; i < this.listModel._list.length; i++) {
          this.listModel._list[i]._index = x++
          if (this.listModel._list[i].telefone && this.listModel._list[i].telefone.match("{")) {
            this.listModel._list[i].phones = JSON.parse(this.listModel._list[i].telefone)
            //console.log(this.listModel._list[i].phones)
          } else if (this.listModel._list[i].telefone === '[]') {
            this.listModel._list[i].telefone = ""
          }

          this.listModel._list[i]._link = (!Licitacao.filtrarLink(this.listModel._list[i].link)) ? this.listModel._list[i].link : ''

          if (this.listModel._list[i].arquivos) {
            this.listModel._list[i]._arquivos = JSON.parse(this.listModel._list[i].arquivos)
            d = 1
            this.listModel._list[i]._arquivos.forEach(e => {
              const arrayArquivos = e.nome.split('.')
              e.extensao = 'anexo' + d++ + '.' + arrayArquivos[arrayArquivos.length - 1]
            });
          }
        }
      }
      this.loading()
    })
  }

  loading() {
    this.carregando = false
    UseFullService.StartTooltip()
  }

  loadingScroll = true
  scroll = (event): void => {

    if (document.scrollingElement.scrollHeight < (document.scrollingElement.scrollTop + 2000) && this.loadingScroll) {
      this.loadingScroll = false
      this.page++
      this.getAll()
    }
    /*
    var element = document.getElementById("block-search");
    if (document.scrollingElement.scrollTop > 300)
      element.classList.add("search-fixed");
    else
      element.classList.remove("search-fixed");
    */
  };

  updateFavito(item: Licitacao) {
    item.favorito.licitacaoId = item.id
    this._context.path = 'licitacao'
    this._context.post(item.favorito).subscribe(d => { item.favorito = d })
  }

  showModalidade() {
    var element1 = document.getElementById("show-hide-estado");
    element1.classList.remove('show')
    var element2 = document.getElementById("show-hide-cidade");
    element2.classList.remove('show')
    var element2 = document.getElementById("show-hide-status");
    element2.classList.remove('show')

    var element = document.getElementById("show-hide-modalidade");
    if (element.classList.contains('show')) {
      element.classList.remove('show')
      this.modalidades.forEach(c => { c._selected = c._confirSelected })
    } else
      element.classList.add('show')
  }

  showEstado() {
    var element1 = document.getElementById("show-hide-modalidade");
    element1.classList.remove('show')
    var element2 = document.getElementById("show-hide-cidade");
    element2.classList.remove('show')
    var element2 = document.getElementById("show-hide-status");
    element2.classList.remove('show')


    var element = document.getElementById("show-hide-estado");
    if (element.classList.contains('show')) {
      element.classList.remove('show')
      this.listEstado.forEach(c => { c._selected = c._confirSelected })
    } else
      element.classList.add('show')
  }

  showCidade() {
    var element1 = document.getElementById("show-hide-modalidade");
    element1.classList.remove('show')
    var element2 = document.getElementById("show-hide-estado");
    element2.classList.remove('show')
    var element2 = document.getElementById("show-hide-status");
    element2.classList.remove('show')

    var element = document.getElementById("show-hide-cidade");
    if (element.classList.contains('show')) {
      element.classList.remove('show')
      this.listCidade.forEach(c => { c._selected = c._confirSelected })
    } else
      element.classList.add('show')
  }

  showStatus() {
    var element1 = document.getElementById("show-hide-estado");
    element1.classList.remove('show')
    var element2 = document.getElementById("show-hide-cidade");
    element2.classList.remove('show')
    var element2 = document.getElementById("show-hide-modalidade");
    element2.classList.remove('show')

    var element = document.getElementById("show-hide-status");
    if (element.classList.contains('show')) {
      element.classList.remove('show')
      this.listStatus.forEach(c => { c._selected = c._confirSelected })
    } else
      element.classList.add('show')
  }

  hideSelect() {
    var element1 = document.getElementById("show-hide-modalidade");
    element1.classList.remove('show')
    var element2 = document.getElementById("show-hide-estado");
    element2.classList.remove('show')
    var element = document.getElementById("show-hide-cidade");
    element.classList.remove('show')
    var element = document.getElementById("show-hide-status");
    element.classList.remove('show')
  }

  selectStatus(item: any) {
    item._selected = !item._selected
    this.setCountSelectStatusTemp()
  }

  selectModalidade(item: any) {
    item._selected = !item._selected
    this.setCountSelectMadalidadeTemp()
  }

  confirmSelectModalidade() {
    this.hideSelect()
    this.modalidades.forEach(c => { c._confirSelected = c._selected })
    this.setCountSelectMadalidade()
    let options = " in('0' "
    this.modalidades.filter((e) => { return (e._confirSelected) }).forEach(c => { options += " ,'" + c.name + "' " })
    this.params.modalidade = options + " ) "
  }

  confirmSelectStatus() {
    this.hideSelect()
    this.listStatus.forEach(c => { c._confirSelected = c._selected })
    this.setCountSelectStatus()
    let options = " in('0' "
    this.listStatus.filter((e) => { return (e._confirSelected) }).forEach(c => { options += " ,'" + c.value + "' " })
    this.params.status = options + " ) "
  }

  selectEstado(item: any) {
    if (!item._selected) item._selected = false
    item._selected = !item._selected
    this.setCountSelectEstadoTemp()
  }

  confirmSelectEstado() {
    this.hideSelect()
    this.listEstado.forEach(c => { c._confirSelected = c._selected })
    let options = " uf in('0' "
    this.listEstado.filter((e) => { return (e._confirSelected) }).forEach(c => { options += " ,'" + c.uf + "' " })
    this.params.estado = options + " ) "
    this.getCidade()
    this.setCountSelectEstado()
  }

  selectCidade(item: any) {
    if (!item._selected) item._selected = false
    item._selected = !item._selected
    this.setCountSelectCidadeTemp()
  }

  confirmSelectCidade() {
    this.hideSelect()
    this.listCidade.forEach(c => { c._confirSelected = c._selected })
    this.setCountSelectCidade()
    let options = " in('0' "
    this.listCidade.filter((e) => { return (e._confirSelected) }).forEach(c => { options += " ,'" + c.Nome + "' " })
    this.params.cidade = options + " ) "
  }

  modalidadeTodas = false
  setModalidadeTodas() {
    this.modalidadeTodas = !this.modalidadeTodas
    this.modalidades.forEach(c => { c._selected = this.modalidadeTodas })
  }

  estadoTodas = false
  setEstadoTodas() {
    this.estadoTodas = !this.estadoTodas
    this.listEstado.forEach(c => { c._selected = this.estadoTodas })
  }

  cidadeTodas = false
  setCidadeTodas() {
    this.cidadeTodas = !this.cidadeTodas
    this.listCidade.forEach(c => { c._selected = this.cidadeTodas })
  }

  statusTodas = false
  setStatusTodas() {
    this.statusTodas = !this.statusTodas
    this.listStatus.forEach(c => { c._selected = this.statusTodas })
  }

  countSelectCidade = 0
  countSelectEstado = 0
  countSelectModalidade = 0
  countSelectStatus = 0
  setCountSelectCidade() { this.countSelectCidade = this.listCidade.filter((o) => { return (o._confirSelected) }).length }
  setCountSelectEstado() { this.countSelectEstado = this.listEstado.filter((o) => { return (o._confirSelected) }).length }
  setCountSelectMadalidade() { this.countSelectModalidade = this.modalidades.filter((o) => { return (o._confirSelected) }).length }
  setCountSelectStatus() { this.countSelectStatus = this.listStatus.filter((o) => { return (o._confirSelected) }).length }

  setCountSelectCidadeTemp() {
    const countSelectCidade = this.listCidade.filter((o) => { return (o._selected) }).length
    if (countSelectCidade != this.listCidade.length)
      this.cidadeTodas = false
  }
  setCountSelectEstadoTemp() {
    const countSelectEstado = this.listEstado.filter((o) => { return (o._selected) }).length
    if (countSelectEstado != this.listEstado.length)
      this.estadoTodas = false
  }
  setCountSelectMadalidadeTemp() {
    const countSelectModalidade = this.modalidades.filter((o) => { return (o._selected) }).length
    if (countSelectModalidade != this.modalidades.length)
      this.modalidadeTodas = false
  }
  setCountSelectStatusTemp() {
    const countSelectStatus = this.listStatus.filter((o) => { return (o._selected) }).length
    if (countSelectStatus != this.listStatus.length)
      this.statusTodas = false
  }
}

import { Injectable } from '@angular/core';
import { Authenticate } from 'src/app/domain/authenticate';
import { Obra } from 'src/app/domain/engenharia/obra';

@Injectable({
    providedIn: 'root'
})
export class UserLoggedInService {

    private _currentUser: Authenticate;
    public id: number;
    public empresaId: number;
    public estado: string;
    public name: string;
    public login: string;
    public expires: string;
    public type: number;
    public img: string;
    public msg: string;

    public uf: string;
    public _obra: Obra

    constructor() {
        //this.SetCurrentUser();
    }


    SetCurrentUser(): void {
        const storage = localStorage.getItem('currentUser')
        if (storage && storage != 'undefined') {
            this._currentUser = JSON.parse(storage) as Authenticate;
            if (this._currentUser && this._currentUser.token && this._currentUser.id)
                this.SetProperties();
        } else
            localStorage.removeItem('currentUser')



        try {
            const _params = localStorage.getItem('_params')
            if (_params && _params != 'undefined')
                this._obra = JSON.parse(atob(_params)) as Obra;
            else
                localStorage.removeItem('_params')
        } catch (error) {
            localStorage.removeItem('_params')
        }
    }

    setUf(_uf: string) {
        localStorage.setItem('tWCIRc', JSON.stringify({ uf: _uf }))
    }

    SetProperties() {
        this.id = this._currentUser.id;
        this.empresaId = this._currentUser.empresaId;
        this.estado = this._currentUser.estado;
        this.name = this._currentUser.nome;
        this.login = this._currentUser.email;
        this.expires = this._currentUser.expires;
        this.type = this._currentUser.type;
        this.img = this._currentUser.img;
        this.msg = this._currentUser.msg;

        const tempStore = localStorage.getItem('tWCIRc')
        if (tempStore) {
            const store = JSON.parse(tempStore) as any;
            this.uf = store.uf
        } else
            this.uf = this.estado
    }
}
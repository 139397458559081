import { Component, OnInit } from '@angular/core';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.css']
})
export class AlertConfirmComponent implements OnInit {

  constructor(public confirmService: AlertConfirmService) { }

  ngOnInit() {
  }
  
  ConfirmEmit(confirm: boolean){
    this.confirmService.ConfirmEmit(confirm);
  }
}

import { Component, OnInit } from '@angular/core';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { RelacaoPlanilhaOrcamentariaItem, EnumActionRow } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EnumItemTipo, EnumStatusValorEstado, RelacaoComposicao } from 'src/app/domain/engenharia/composicao/relacaoComposicao';
import { MaoDeObra } from 'src/app/domain/engenharia/composicao/MaoDeObra';
import { Insumo } from 'src/app/domain/engenharia/composicao/Insumo';
import { Composicao } from 'src/app/domain/engenharia/composicao/composicao';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Estado } from 'src/app/domain/enum/estado';
import { ActivatedRoute, Router } from '@angular/router';
import { table } from 'src/app/domain/common/table';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ComposicaoService } from 'src/app/service/engenharia/composicao.service';
import { EnumState } from 'src/app/domain/common/interactiveTable';
import { Obra } from 'src/app/domain/engenharia/obra';
import { MedicaoPlanilhaOrcamentaria, MedicaoItemPlanilhaOrcamentaria, EnumStatusVlrMedicao } from 'src/app/domain/engenharia/medicao-planilha-orcamentaria';

declare var $: any;
@Component({
  selector: 'app-medicao-planilha-orcamentaria-list',
  templateUrl: './medicao-planilha-orcamentaria-list.component.html',
  styleUrls: ['./medicao-planilha-orcamentaria-list.component.css']
})
export class MedicaoPlanilhaOrcamentariaListComponent implements OnInit {
  _viewItemDetailed = []
  _code = 'app-medicao-planilha-orcamentaria'
  planilha = new PlanilhaOrcamentaria()
  model = new MedicaoPlanilhaOrcamentaria()
  listRelacaoPlanilha = new Array<RelacaoPlanilhaOrcamentariaItem>()
  relacaoPlanilha = new RelacaoPlanilhaOrcamentariaItem()
  unidadesDeMedida = new Array<UnidadeMedida>()
  listEstado = new Array<Estado>()
  obra = new Obra()
  id = this.route.snapshot.params['id'];

  constructor(private confirmService: AlertConfirmService, private router: Router, private route: ActivatedRoute, private context: ComposicaoService, private userLoggedIn: UserLoggedInService) {

  }

  async ngOnInit() {
    this.getUnidadesDeMedida()
    this.getEstados()
    this.getAllRelacoesMedicao(this.id)
  }

  /***** enuns ******/
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => { this.unidadesDeMedida = d })
  }

  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => {
      this.listEstado = d
    })
  }

  getObra(id) {
    this.context.path = 'obra'
    this.context.get(id).subscribe(d => { 
      this.obra = d;       
      this.obra.dataInicio = UseFullService.formatDate(new Date())      
      this.obra.dataFim = UseFullService.formatDate(new Date())
    })
  }

  getPlanilha(id: any) {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.planilha = d;
      this.getObra(this.planilha.obraId)
    })
  }

  get(id: any, all = true) {
    this.context.path = 'medicao-planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.model = d
      this.model.dataMedicao = UseFullService.formatDateString(this.model.dataMedicao)
      this.model._vlrTotal = UseFullService.ToFixed(this.model._vlrTotal)
      if(all){
        this.getAllRelacoes(this.model.planilhaOrcamentariaId)
        this.getPlanilha(this.model.planilhaOrcamentariaId)
      }else
        this.atualizarValorMedidoTotal()
    })
  }

  _medicaoItens = new Array<MedicaoItemPlanilhaOrcamentaria>()
  getAllRelacoesMedicao(id: any) {
    this.context.path = 'medicao-planilha-orcamentaria/item'
    this.context.getAll(id).subscribe(d => {
      this._medicaoItens = d
      this.get(id)
    })
  }

  getAllRelacoes(id: number) {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.getAll("planilhaOrcamentariaId=" + id).subscribe(d => {
      this.listRelacaoPlanilha = d;
      this.atualizarPlanilha()
    })
  }

  atualizarPlanilha() {
    var x = 1
    this.listRelacaoPlanilha.forEach(c => {
      c._index = x++

      if ((c.itemTipo == EnumItemTipo.grupo || c.itemTipo == EnumItemTipo.subgrupo) && c.descricao)
        c.descricao = c.descricao.toUpperCase()
      else 
        this.atualizarPlanilhaRow(c)
      
      c._unidadeMedida = UnidadeMedida.getName(this.unidadesDeMedida, c.unidadeMedidaId)
      c._itemTipo = RelacaoComposicao.getTipoItemName(c.itemTipo)
      RelacaoPlanilhaOrcamentariaItem.setProperties(c)
      if (c.itemTipo === EnumItemTipo.subgrupo && c.codigo && c.codigo.split('.').length > 3)
        c._typeRow = EnumActionRow.subgrupo
    })

    setTimeout(async () => {
      await PlanilhaOrcamentaria.ordenarList(this.listRelacaoPlanilha)       
      this.atualizarValorMedidoTotal();
      PlanilhaOrcamentaria.setValues(this.listRelacaoPlanilha, this.planilha)
    }, 100);
  }

  atualizarPlanilhaRow(c: RelacaoPlanilhaOrcamentariaItem) {
    c.medicao = MedicaoItemPlanilhaOrcamentaria.getPorItemPlanilha(this._medicaoItens, c.id)
    c.medicao._medido = (c.medicao._medido) ? c.medicao._medido : '0'
    const _haMedir = UseFullService.ConvertFloat(c.quantidade) - parseFloat(c.medicao._medido);
    c.medicao._haMedir = UseFullService.ToFixed(_haMedir);
    c.medicao._vlrAMedir = UseFullService.ToFixed(_haMedir * parseFloat(c.valor));
    this.atualizarValorMedido(c);   
  }

  atualizarValorMedido(c: RelacaoPlanilhaOrcamentariaItem){
    const valorMedido = (parseFloat(c.medicao._medido) + parseFloat(c.medicao.medicao)) * parseFloat(c.valor);
    c.medicao._vlrMedido = UseFullService.ToFixed(valorMedido);
    if(valorMedido > (parseFloat(c.quantidade) * parseFloat(c.valor)))
      c.medicao._statusVlr = EnumStatusVlrMedicao.vlrMaior
    else
      c.medicao._statusVlr = EnumStatusVlrMedicao.vlrMenorIgual
    UseFullService.StartTooltip()
  }

  atualizarValorMedidoTotal(){
    let valorTotal = 0;
    this.listRelacaoPlanilha.forEach(c => {
      if(c.medicao)
        valorTotal += UseFullService.ConvertFloat(c.medicao._vlrMedido)
    })
    this.model._vlrTotalMedido = UseFullService.ToFixed(valorTotal)
  }

  selectRow(item: RelacaoPlanilhaOrcamentariaItem){
    this.listRelacaoPlanilha.forEach(c => {
      c._selected = (item == c) ? true : false
      if (!c._selected)
        RelacaoPlanilhaOrcamentariaItem.setProperties(c)
      $('input#quantidade-'+item._index).focus()
    })
  }

  onChangeSave(item: RelacaoPlanilhaOrcamentariaItem) {
    this.context.path = 'medicao-planilha-orcamentaria/item'
    this.context.put(item.medicao).subscribe(d => {   
      this.atualizarValorMedido(item)   
      this.get(this.model.id, false)
    })
  }   

  mouseEnter(item: RelacaoPlanilhaOrcamentariaItem) {
    item._selected_hover = true
    UseFullService.StartTooltip()
  }

  mouseLeave(item: RelacaoPlanilhaOrcamentariaItem) {
    item._selected_hover = false
    UseFullService.StartTooltip()
  }
}
import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { CalssificacaoGenerica, ProdutoCategoriaNivel } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { CategoriaNivelService } from 'src/app/service/suprimento/categoria-nivel.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';

declare var $: any;

@Component({
  selector: 'app-categoria-nivel',
  templateUrl: './categoria-nivel.component.html',
  styleUrls: ['./categoria-nivel.component.css']
})
export class CategoriaNivelComponent implements OnInit {

  public _categorias: ProdutoCategoria[];
  public _categoria_niveis: ProdutoCategoriaNivel[];
  public _categoria_nivel: ProdutoCategoriaNivel;
  public _temp_categoria_nivel: ProdutoCategoriaNivel;
  public _produto: InsumoDoFornecedor;

  private _code = "app-categoria-nivel";
  public _selected_niveis: Array<SelectedNivel>;
  _niveis = CalssificacaoGenerica;
  keys(): Array<string> {
    var keys = Object.keys(this._niveis);
    return keys.slice(keys.length / 2);
  }


  constructor(
    private httpProduto: ProdutoService,
    private categoriaNivelService: CategoriaNivelService,
    private confirmService: AlertConfirmService) {
  }

  ngOnInit() {
    this._categoria_nivel = new ProdutoCategoriaNivel();
    this._produto = new InsumoDoFornecedor();
    this.SetListaCatetorias();

    this.SetSelectedNiveis();
    this.confirmService.confirmEmitter$.subscribe(c => this.Remover(c));
  }

  SetSelectedNiveis() {
    this._selected_niveis = new Array<SelectedNivel>();
    this.keys().forEach(e => {
      var nivel = new SelectedNivel();
      nivel.id = this._niveis[e];
      nivel.nome = e;
      nivel.active = false;
      this._selected_niveis.push(nivel);
    });
  }

  ChangeNivel(nivel: SelectedNivel) {
    this._categoria_nivel.nivel_id = nivel.id;
    this._categoria_nivel.nome = "";
    this._categoria_nivel.pre_fixo = "";
    this.Adicionar()
  }

  OnChangeSearch() {
    this.categoriaNivelService.GetAll(this._categoria_nivel.categoria_id).subscribe(
      d => { this._categoria_niveis = d; },
      e => { },
      () => { }
    );
  }

  Adicionar(categoriaNivel: ProdutoCategoriaNivel = this._categoria_nivel) {
    this.categoriaNivelService.SetSolicitationProvider(categoriaNivel).subscribe(
      d => { },
      e => { },
      () => { this.OnChangeSearch(); }
    );
  }

  Remover(confirm: boolean = false) {
    if (this.confirmService._code != this._code || confirm == false)
      return;
    this.categoriaNivelService.SetDelete(this._temp_categoria_nivel).subscribe(
      d => { },
      e => { },
      () => { this.OnChangeSearch(); }
    );
  }

  SetListaCatetorias() {
    this.httpProduto.getAllCategories().subscribe(
      data => { this._categorias = data; },
      error => { },
      () => { }
    );
  }

  ClickDelete(e, categoriaNivel: ProdutoCategoriaNivel): void {
    e.preventDefault();
    this._temp_categoria_nivel = categoriaNivel;
    this.confirmService._code = this._code;
    this.confirmService._title = "Desvinculação de Nivel";
    this.confirmService._message = "Deseja desvincular o nivel, " + categoriaNivel.nome + "?";
    this.confirmService.Show();
  }

  ReciverPesquisaProduto(produto: InsumoDoFornecedor): void {
    this._produto = produto;
    this._categoria_nivel.categoria_id = produto.categoria_id;
    this._categoria_nivel.produto_id = produto.id;
    this._categoria_nivel.nivel_id = 0;
    this.OnChangeSearch();
  }

  SugerirProduto(): void {
    $('#pesquisa-insumo-modal').modal('show');
  }
}

export class SelectedNivel {
  public id: number;
  public nome: string;
  public active: boolean;
}
import { Component, OnInit, ComponentFactoryResolver, Output } from '@angular/core';
import { Empresa } from 'src/app/domain/empresa/empresa';
import { EnumService } from 'src/app/service/usefull/enum.service';
import { Estado } from 'src/app/domain/enum/estado';
import { Municipio } from 'src/app/domain/enum/municipio';
import { ViaCepService, ViaCep } from 'src/app/service/usefull/viacep.service';
import { EmpresaService } from 'src/app/service/configuracao/empresa.service';
import { throttleTime } from 'rxjs/operators';
import { UserService } from 'src/app/service/user/user.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { table } from 'src/app/domain/common/table';
import { RepositorioService } from 'src/app/service/repositorio-service';

declare var $: any;
@Component({
  selector: 'app-empresa-form',
  templateUrl: './empresa-form.component.html',
  styleUrls: ['./empresa-form.component.css']
})
export class EmpresaFormComponent implements OnInit {

  public _nome_arquivo: string;
  public _src_arquivo: string;

  public _empresa: Empresa;
  public _estados: Estado[];
  public _municipios: Municipio[];
  public _viacep: ViaCep;

  constructor(
    private context: RepositorioService,
    private viaCepService: ViaCepService,
    private empresaService: EmpresaService,
    private userLoggedInService: UserLoggedInService) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
  }

  ngOnInit() {
    this._empresa = new Empresa();
    this._estados = new Array<Estado>();
    this._municipios = new Array<Municipio>();
    this._viacep = new ViaCep();
    this.getEstados();
    this.GetEmpresaLoggedIn();
    this.JqueryEnvet()
  }

  reload() {
    window.location.reload();
  }

  GetEmpresaLoggedIn() {
    this.empresaService.GetLoggedIn().subscribe(
      d => {
        if (d != null)
          this._empresa = d;
      },
      e => { },
      () => {
        if (this._empresa != null && this._empresa.id > 0) {
          $("#image-logo").attr("src", GlobalVariable.BASE_API_URL + this._empresa.cnpj + '/logo/miniatura/' + this._empresa.logo);
          $("#image-logo").fadeIn();
          this.getMunicipios(this._empresa._endereco.estado, false);
        }
      }
    );
  }

  MostrarLogo() {
    this.CarregarDataModal("logo/" + this._empresa.logo);
  }



  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => { this._estados = d; })
  }

  OnChangeEstado() {
    this.getMunicipios(this._empresa._endereco.estado);
  }


  getMunicipios(uf: string, via_cep: boolean = true) {
    this.context.path = 'common'
    this.context.getAll("Uf='" + uf.trim() + "'", String(table.municipio)).subscribe(d => {
      this._municipios = d;
      if (via_cep)
        this.SetCidadeViaCep();
    })
  }


  OnChangeCep() {
    setTimeout(() => {
      if (this._empresa._endereco.cep != undefined && this._empresa._endereco.cep.length == 9)
        this.GetCep();
    }, 10);
  }

  GetCep() {
    this.viaCepService.Get(this._empresa._endereco.cep).subscribe(
      d => { this._viacep = d; },
      e => { },
      () => { this.SetEndereco(); }
    );
  }

  SetEndereco() {
    if (this._viacep.erro)
      return;
    this.getMunicipios(this._viacep.uf);
  }


  SetCidadeViaCep() {
    this._empresa._endereco.cidade = this._viacep.ibge;
  }


  CarregarLogo(input: any) {
    this._empresa._img = input.files[0];

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var target = e.target as any;
        $("#image-logo").attr("src", target.result);
        $("#image-logo").show();
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  Insert() {
    this.empresaService.Insert(this._empresa).subscribe(
      d => { },
      e => { },
      () => { this.reload(); }
    );
  }

  JqueryEnvet() {
    $("#cep").mask("99999-999");
    $("#number").mask("999999999999999999999999");
    $("#numero").mask("999999999999999999999999");
    $("#cnpj").mask("99.999.999/9999-99");
  }

  CarregarDataModal(id) {
    this._nome_arquivo = "";
    this._src_arquivo = "";
    this._nome_arquivo = id;
    this.previewLoadData(this._empresa._img);
    $('#data-modal').modal('show');
  }

  previewLoadData(file: File) {
    this._nome_arquivo = file.name;
    var _this = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      var target = e.target as any;

      if (file.type == "application/pdf") {
        $("#exibir-pdf").html("<iframe width='100%' height='" + ($('body').height() - 100) + "px' src='" + target.result + "'></iframe>");
        _this._src_arquivo = "";
      } else
        _this._src_arquivo = target.result;
    };

    reader.readAsDataURL(file);
  }


}

export class TargetImage {
  public error: any;
  public result: string;
}
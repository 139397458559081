import { UseFullService } from 'src/app/service/usefull/usefull.service';

export class Dashboard {
    public quantidade_vendas: string;
    public faturamento: string;
    public solicitacao_orcamento: string;
    public orcamentos_enviados: string;
    public entregas_pendentes: string;
    public entregas_realizadas: string;
    public faturamento_analise: string;
    public quantidade_vendas_analise: string;

    public _solicitacoes_abertas: string;

    static Formatar(model: Dashboard) {
        model.quantidade_vendas = UseFullService.ToFixed(model.quantidade_vendas);
        model.faturamento = UseFullService.ToFixed(model.faturamento);
        model.solicitacao_orcamento = UseFullService.ToFixed(model.solicitacao_orcamento);
        model.orcamentos_enviados = UseFullService.ToFixed(model.orcamentos_enviados);
        model.entregas_pendentes = UseFullService.ToFixed(model.entregas_pendentes);
        model.entregas_realizadas = UseFullService.ToFixed(model.entregas_realizadas);
        var solicitacoes_abertas = parseInt(model.solicitacao_orcamento) - parseInt(model.orcamentos_enviados);
        model._solicitacoes_abertas = UseFullService.ToFixed(solicitacoes_abertas);
        model.faturamento_analise = UseFullService.ToFixed(model.faturamento_analise);
        model.quantidade_vendas_analise = UseFullService.ToFixed(model.quantidade_vendas_analise);
    }

    static opcao = {
        chart: {
            type: 'line'
        },
        title: {
            text: ''// titulo orcamento
        },
        subtitle: { //text: 'Source: WorldClimate.com' },
            xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
            yAxis: { title: { text: 'Quantidade de orçamentos' } },
            plotOptions: { line: { dataLabels: { enabled: true }, enableMouseTracking: true } },
            tooltip: {
                headerFormat: "<b  class='titulo-grafico'>{series.name}</b><br/>",
                pointFormat: "<span class='valor-faturado'>{point.y}</span>"
            },
            series: [{
                name: 'Tokyo',
                data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
            }, {
                name: 'London',
                data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8]
            }]
        }
    }

    static opcaoPrincipaisGastos = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: ''// titulo orcamento
        },
        subtitle: {
            //text: 'Source: WorldClimate.com'
        },
        colors: ['#2eabe8', '#242424'],
        xAxis: {
            categories: ['a', 'b', 'c']
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    style: {
                        color: '#242424',
                        fontWeight: "none",
                        textOutline: "0px contrast"
                        //color: (Highcharts.theme && Highcharts.theme.contrastTextColor)|| 'black'
                    }
                }
                //showInLegend: true
            }
        },
        tooltip: {
            headerFormat: "<b  class='titulo-grafico-chat'>{series.name}</b><br/>",
            pointFormat: "<span class='valores-chat'>{point.name}: <b>{point.percentage:.1f}%</b></span>"
        },
        series: [{
            type: 'pie',
            name: 'Gastos',
            data: [{
                name: 'Cimento',
                y: 20.41,
                sliced: true,
                selected: true
            }, {
                name: 'Areia',
                y: 10.80
            }, {
                name: 'Ferro',
                y: 6.23
            }, {
                name: 'Brita',
                y: 5.24
            }, {
                name: 'Pedreiro',
                y: 4.9
            }, {
                name: 'Carpinteiro',
                y: 4.4
            }]
        }]
    }

    static chatLucro = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        colors: ['#2eabe8', '#242424'],
        xAxis: {
            categories: ['01/2020', '02/2020', '03/2020', '05/2020', '05/2020']
        },
        tooltip: {
            headerFormat: "<b  class='titulo-grafico'>Total de {series.name}:</b><br/>",
            pointFormat: "R$ <span class='valor-faturado'>{point.y}</span>"
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Construção da torre A',
            data: [500, 300, 400, 700, 200]
        }, {
            name: 'Construção da torre B',
            data: [200, -200, -300, 200, 100]
        }]
    }

    static chatPrevistoRealizado = {
        chart: {
            type: 'areaspline'
        },
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        colors: ['#2eabe8', '#242424'],
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            x: 150,
            y: 100,
            floating: true,
            borderWidth: 1,
            backgroundColor: '#FFFFFF'
        },
        xAxis: {
            categories: ['11/2019', '12/2019', '01/2020', '02/2020', '03/2020', '05/2020', '05/2020'],
            plotBands: [{ // visualize the weekend
                //from: 4.5,
                //to: 6.5,
                color: 'rgba(68, 170, 213, .2)'
            }]
        },
        tooltip: {
            shared: true,
            valueSuffix: '%'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.7
            }
        },
        series: [{
            name: 'Previsto',
            data: [3, 4, 3, 5, 4, 4, 5]
        }, {
            name: 'Realizado',
            data: [1, 3, 4, 3, 3, 5, 4]
        }]
    }
}

export class FaturamentoDashboard {
    public data: Date;
    public total_diario: string;
}
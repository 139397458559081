import { Component, OnInit } from '@angular/core';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { DiarioObra, MaoObraDiarioObra, EquipamentoDiarioObra, ImagensDiarioObra } from 'src/app/domain/diario-obra/DiarioObra';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from 'src/app/core/injectable/NgbDateFRParserFormatter';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Obra, RelacaoObraProfissional } from 'src/app/domain/engenharia/obra';
import { ActivatedRoute, Router } from '@angular/router';
import { MaoDeObraPersonalizada } from 'src/app/domain/auxiliar/mao-de-obra-personalizada';
import { Equipamento } from 'src/app/domain/auxiliar/equipamento';
import { ImagensDiarioObraService } from 'src/app/service/engenharia/diario-obra.service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Endereco } from 'src/app/domain/auxiliar/endereco';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';

declare var $: any;
@Component({
  selector: 'app-diario-obra-form',
  templateUrl: './diario-obra-form.component.html',
  styleUrls: ['./diario-obra-form.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class DiarioObraFormComponent implements OnInit {
  host = GlobalVariable.BASE_API_URL
  _model = new DiarioObra()
  _obra = new Obra()
  listObra = new Array<Obra>()

  constructor(
    public _sanitizer: DomSanitizer,
    public context: RepositorioService,
    private diarioObraService: ImagensDiarioObraService,
    public route: ActivatedRoute,
    public router: Router,
    public user: UserLoggedInService,
    public confirmService: AlertConfirmService) {

    this._obra.profissionais = new Array<RelacaoObraProfissional>()
    this._obra.endereco = new Endereco()
  }
  id = this.route.snapshot.params['id']

  ngOnInit() {
    this.user.SetCurrentUser()
    if (this.id) this.get(); else this.getAllObra()
    
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }
  
  getAllObra() {
    this.context.path = 'obra'
    this.context.getAll().subscribe(d => { this.listObra = d; })
  }

  getObra() {
    this.context.path = 'obra'
    this.context.get(this._model.obraId).subscribe(d => { 
      this._obra = d; 
      this.loadObra();
    })
  }

  dias = 0;
  decorrido = 0;
  aVencer = 0;
  porcentagem = 0;
  porcentagemString = '0';
  loadObra() {
    const inicio = new Date(this._obra.dataInicio)
    const fim = new Date(this._obra.dataFim)    
    this._obra._dataInicio = UseFullService.formatDate(inicio)
    this._obra._dataFim = UseFullService.formatDate(fim)
    const hoje = new Date()
    this.dias = UseFullService.getDaysDates(inicio, fim)
    if (hoje > fim)
      this.decorrido = this.dias
    else
      this.decorrido = UseFullService.getDaysDates(inicio, hoje)
    this.aVencer = this.dias - this.decorrido
    this.porcentagem = (((this.dias - this.aVencer)/this.dias) * 100)
    this.porcentagemString = UseFullService.ToFixed(this.porcentagem)
  }

  toggleCalendar() { UseFullService.TraduzirCalendario(); }


  get() {
    this.context.path = 'diario-obra'
    this.context.get(this.id).subscribe(d => {
      if (d && d.id) {
        this._model = d
        this.loading()
        this.getObra()
      }
    })
  }

  dataRel = ''
  _ocorrencia = ''
  loading() {
    this.getMaoDeObra()
    this.getEquipamento()
    this.getImages()
    this._model._dataRelatorio = UseFullService.formatNgbDate(this._model.dataRelatorio)
    this.dataRel = UseFullService.formatDate(new Date(this._model.dataRelatorio))
    DiarioObra.setTurnos(this._model)

    if (this._model.ocorrencia)
      this._ocorrencia = this._model.ocorrencia.replace(/\n/g, '<br />')

  }

  getImages() {
    this.context.path = 'diario-obra/imagem'
    this.context.getAll(this._model.id).subscribe(d => {
      if (d && d.length > 0) {
        this._model.listImage = d
        this.setImage()
      }
    })
  }

  setImage() {
   }
  
  insert() {
    this.context.path = 'diario-obra'
    this._model.dataRelatorio = UseFullService.formatNgbDateStructString(this._model._dataRelatorio)
    if (!this._model.id) {
      DiarioObra.setClimaPardao(this._model)
      this.context.post(this._model).subscribe(d => {
        this.router.navigate(['/diario-obra/form/', d.id])
      })
    } else {
      DiarioObra.updateTurnos(this._model)
      this.context.put(this._model).subscribe(d => {
        if (d && d.id) {
          this.router.navigate(['/diario-obra/view/', d.id])
        }
      })
    }
  }

  actionCondicao(c: any) { if (c.active == 0) return; c.condicao = !c.condicao }
  actionClima(c: any, clima: any) {
    if (!c.active) return
    c.tempo.forEach(c => { c.active = (clima.name == c.name) });
  }
  actionTurno(c: any) { c.active = !c.active }



  /****** start - mao de obra ******/
  _listMaodeObra = new Array<MaoDeObraPersonalizada>()

  getMaoDeObra() {
    this.context.path = 'mao-de-obra-personalizada'
    this.context.getAll().subscribe(d => {
      this._listMaodeObra = d
      this._listMaodeObra.forEach(c => {
        if (this._model.listmaodeobra)
          this._model.listmaodeobra.forEach(p => {
            if (p.itemId === c.id) {
              c._active = true
              p._active = true
            }
          })
      })
    })
  }

  selecionarMaoDeObra(item: MaoDeObraPersonalizada) {
    item._active = !item._active

    if (!this._model.listmaodeobra) this._model.listmaodeobra = new Array<MaoObraDiarioObra>()
    const buscarItem = (o) => { return (o.itemId == item.id) }
    const data = this._model.listmaodeobra.filter(buscarItem)

    if (!item._active || data.length > 0) {
      data[0]._active = item._active
      return
    } else {
      const row = new MaoObraDiarioObra()
      row.descricao = item.descricao
      row.itemId = item.id
      row.diarioObraId = this._model.id
      row.usuarioId = this.user.id
      row._active = item._active
      this._model.listmaodeobra.push(row)
    }
  }
  removerMaoDeObra(item: MaoObraDiarioObra) {
    item._active = !item._active
    const buscarItem = (o) => { return (o.id == item.itemId) }
    const data = this._listMaodeObra.filter(buscarItem)
    if (data.length > 0) data[0]._active = item._active
  }

  buscar_mao_de_obra = String()
  buscandoMaoDeObra = false
  onChangeMaoDeObra() { setTimeout(() => { this.buscandoMaoDeObra = true }, 1) }
  finalizarBuscaMaoDeObra() { this.buscandoMaoDeObra = false; this.buscar_mao_de_obra = String() }
  
  addMaoDeObra() {
    const maoDeObraPersonalizada = new MaoDeObraPersonalizada()
    maoDeObraPersonalizada.descricao = this.buscar_mao_de_obra
    MaoDeObraPersonalizada.valida(maoDeObraPersonalizada)
    this.context.path = 'mao-de-obra-personalizada'
    this.context.post(maoDeObraPersonalizada).subscribe(d => { this.getMaoDeObra() })
  }
  /****** end - mao de obra ******/


  /****** start - Equipamento ******/
  _listEquipamento = new Array<Equipamento>()

  getEquipamento() {
    this.context.path = 'equipamento'
    this.context.getAll().subscribe(d => {
      this._listEquipamento = d;
      this._listEquipamento.forEach(c => {
        if (this._model.listequipamento)
          this._model.listequipamento.forEach(p => {
            if (p.itemId === c.id) {
              c._active = true
              p._active = true
            }
          })
      })
    })
  }

  selecionarEquipamento(item: Equipamento) {
    item._active = !item._active

    if (!this._model.listequipamento) this._model.listequipamento = new Array<EquipamentoDiarioObra>()
    const buscarItem = (o) => { return (o.itemId == item.id) }
    const data = this._model.listequipamento.filter(buscarItem)

    if (!item._active || data.length > 0) {
      data[0]._active = item._active
      return
    } else {
      const row = new EquipamentoDiarioObra()
      row.descricao = item.descricao
      row.itemId = item.id
      row.diarioObraId = this._model.id
      row.usuarioId = this.user.id
      row._active = item._active
      this._model.listequipamento.push(row)
    }
  }
  removerEquipamento(item: EquipamentoDiarioObra) {
    item._active = !item._active
    const buscarItem = (o) => { return (o.id == item.itemId) }
    const data = this._listEquipamento.filter(buscarItem)
    if (data.length > 0) data[0]._active = item._active
  }

  buscar_equipamento = String()
  buscandoEquipamento = false
  onChangeEquipamento() { setTimeout(() => { this.buscandoEquipamento = true }, 1) }
  finalizarBuscaEquipamento() { this.buscandoEquipamento = false; this.buscar_equipamento = String() }


  addEquipamento() {
    const equipamento = new Equipamento()
    equipamento.descricao = this.buscar_equipamento
    Equipamento.valida(equipamento)
    this.context.path = 'equipamento'
    this.context.post(equipamento).subscribe(d => { this.getEquipamento() })
  }

  /****** end - mao de obra ******/

  /****** start - image *****/
  CarregarImage(input: any) {

    setTimeout(() => {
      const _img = input.files[0];

      if (!this._model.listImage)
        this._model.listImage = new Array<ImagensDiarioObra>()

      for (let i = 0; i < input.files.length; i++) {
        const newImage = new ImagensDiarioObra()
        newImage._file = input.files[i]
        newImage.diarioObraId = this._model.id
        newImage.usuarioId = this.user.id
        this.saveImage(newImage)
      }

    }, 50);



    /*
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var target = e.target as any;
        $("#image-logo").attr("src", target.result);
        $("#image-logo").show();
      };
      reader.readAsDataURL(input.files[0]);
    }
    */
  }
  saveImage(newImage: ImagensDiarioObra) {
    const _this = this
    var reader = new FileReader();
    reader.onload = function (e) {
      var target = e.target as any;
      var img = new Image()
      img.src = target.result
      setTimeout(() => {
        newImage._maxWidth = img.width
        _this.diarioObraService.save(newImage).subscribe(d => {
          if (d && d.id)
            _this._model.listImage.push(d)
        })
      }, 5);

    };
    reader.readAsDataURL(newImage._file);
  }

  changeImg(img) { this.diarioObraService.update(img).subscribe() }

  _nome_arquivo = "";
  _src_arquivo = "";
  ampliarImage(img) {
    this._nome_arquivo = img.descricao;
    this._src_arquivo = this.host + 'diario-obra/imagem/file?diarioObraId=' + img.diarioObraId + '&nome=' + img.nome.replace('small.', 'medium.');
    $('#data-modal').modal('show');
  }

  _codeImg = "app-img-diario-obra";
  tempImg = new ImagensDiarioObra()
  confirmeRemove(model: ImagensDiarioObra): void {
    this.tempImg = model;
    this.confirmService._code = this._codeImg
    this.confirmService._title = "Excluir Imagem do Diário de Obra (RDA)";
    this.confirmService._message = "<div class='text-center col-12' > <img src='"+this.host + 
    'diario-obra/imagem/file?diarioObraId=' + model.diarioObraId + '&nome=' + model.nome+"' /></div><br /> Deseja excluir permanentemente a imagem?";
    this.confirmService.Show();    
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._codeImg)
      return;
    this.context.path = 'diario-obra/imagem'
    this.context.delete(this.tempImg.id).subscribe(
      d => { },
      e => { },
      () => { this._model.listImage.splice(this._model.listImage.indexOf(this.tempImg), 1) }
    );
  }

}

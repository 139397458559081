import { Component } from '@angular/core';
import { DiarioObraFormComponent } from './../diario-obra-form.component';

@Component({
  selector: 'app-diario-obra-relatorio',
  templateUrl: './diario-obra-relatorio.component.html',
  styleUrls: ['./diario-obra-relatorio.component.css']
})
export class DiarioObraRelatorioComponent extends DiarioObraFormComponent {
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  imgTable = [[]]
  setImage() {
    let x = 0
    let y = 0
    this._model.listImage.forEach(c => {
      const src = this.host + 'diario-obra/imagem/file?diarioObraId=' + c.diarioObraId + '&nome=' + c.nome

      if (x > 5) {
        x = 0
        y++
        this.imgTable.push([])
      }

      const res = { url: src, descricao: c.descricao }
      this.imgTable[y].push(res)
      x++

      /*
      this.toDataURL(src, function (dataUrl) {
        c._small = dataUrl
      })
      */
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ResizedEvent } from 'angular-resize-event';
import { Insumo } from 'src/app/domain/engenharia/composicao/Insumo';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UsuarioConfiguracao } from 'src/app/domain/usuario/usuarioConfiguracao';
import { ValorInsumoEstado } from 'src/app/domain/engenharia/composicao/valorInsumoEstado';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Composicao } from 'src/app/domain/engenharia/composicao/composicao';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any

@Component({
  selector: 'app-insumo-form',
  templateUrl: './insumo-form.component.html',
  styleUrls: ['./insumo-form.component.css']
})
export class InsumoFormComponent implements OnInit {
  _msgParams = { titulo: '', descricao: '' }
  _code = 'app-insumo-form'
  listModel = new Array<Insumo>()
  tempModel = new Insumo()
  usuarioConfiguracao = new UsuarioConfiguracao()
  listValorInsumoEstado = new Array<ValorInsumoEstado>()
  baseListValorInsumo = new Array<ValorInsumoEstado>()
  unidadesDeMedida = new Array<UnidadeMedida>()

  constructor(private context: RepositorioService, public userLoggedService: UserLoggedInService, private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.getUnidadesDeMedida()
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }
  
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(
      d => { this.unidadesDeMedida = d },
      e => { },
      () => {        
        this.getUsuarioConfiguracao()
      })
  }

  getUsuarioConfiguracao() {
    this.context.path = 'usuario/configuracao'
    this.context.get(this.userLoggedService.id).subscribe(
      d => { this.usuarioConfiguracao = d },
      e => { },
      () => {
        this.getAll()
        this.listValorInsumoEstado = ValorInsumoEstado.getAll(this.usuarioConfiguracao)
      })
  }

  getAll() {
    this.context.path = 'insumo'
    this.context.getAll('empresaId=' + this.userLoggedService.empresaId).subscribe(
      d => {
        this.listModel = new Array<Insumo>()
        d.forEach(d => {
          this.setValorPorEstado(d)
          this.listModel.push(d)
        })
      },
      e => { },
      () => {
        this.setPlanilha(1)
        this.getValorPorEstado()
      })
  }


  async setValorPorEstado(model: Insumo) {
    model._listValorInsumoEstado = await ValorInsumoEstado.getAll(this.usuarioConfiguracao, model.id);
  }

  onResize(event) {
    setTimeout(() => {
      if ($("td.descricao").width() < 300)
        $("td.descricao").css('min-width', '300px')
      $("th.descricao").css('width', ($("td.descricao").width()) + 'px')
      $("th.descricao").css('min-width', ($("td.descricao").width()) + 'px')
      $("td.descricao").css('min-width', ($("td.descricao").width()) + 'px')

      $('.div-table-fixed').css('height', ($('body').height() - 230) + 'px')
      this.setScroll()
    }, 50);
  }

  mouseEnter(data: Insumo) {
    data._selected_hover = true
    UseFullService.StartTooltip()
  }

  trClick(data: Insumo) {
    this.listModel.forEach(c => {
      c._selected = (data == c) ? true : false
      if (!c._selected)
        Insumo.setProperties(c)
    })
  }

  focusInputDescricao(i) {
    setTimeout(() => {
      $('input#descricao-' + i).focus()
    }, 1);
  }

  removerRow(row: number) {
    this.listModel.splice((row - 1), 1)
    this.atualisarPlanilha(this.listModel)
  }

  addRow(row: number) {
    const tempList = new Array<Insumo>()
    const data = new Insumo()
    data._listValorInsumoEstado = ValorInsumoEstado.getAll(this.usuarioConfiguracao)
    for (let i = 0; i < row; i++)
      tempList.push(this.listModel[i])

    tempList.push(data)

    setTimeout(() => {
      const countList = this.listModel.length
      for (let i = row; i < countList; i++) {
        tempList.push(this.listModel[i])

        if (i == countList - 1) {
          this.listModel = tempList
          this.atualisarPlanilha(this.listModel)
        }
      }
    }, 11);
  }

  mouseLeave(data: Insumo) {
    data._selected_hover = false
    UseFullService.StartTooltip()
  }

  setPlanilha(x: number) {
    for (let i = 0; i < x; i++) {
      const model = new Insumo()
      model._listValorInsumoEstado = ValorInsumoEstado.getAll(this.usuarioConfiguracao)
      this.listModel.push(model)
      if (i == x - 1)
        this.atualisarPlanilha(this.listModel);
    }
  }

  atualisarPlanilha(list: Array<any>) {
    var x = 1
    list.forEach(c => {
      c._index = x++
      c._par = Number.isInteger((x + 1) / 2)
    })
    setTimeout(() => {
      this.onResize(this)
      UseFullService.StartTooltip()
    }, 50);
  }

  onChange(data: Insumo) {
    setTimeout(() => {
      this.context.path = 'insumo'
      Insumo.validar(data)
      if (data.id > 0)
        this.context.put(data).subscribe();
      else
        this.context.post(data).subscribe(d => {
          data.id = d.id
          data.empresaId = d.empresaId
          data.data = d.data
          data.codigo = d.codigo
          data._listValorInsumoEstado.forEach(c => {
            c.insumoId = d.id
            if (c.valor)
              this.saveValor(c)
          })
          if(data._index == this.listModel.length)
            this.setPlanilha(1)
        })
    }, 1)
  }

  buscarItem(data: Insumo) {
    data._active_buscar = true;
  }

  setScroll() {
    $(".table-recurse").scroll(function (e) {
      $(".table-recurse thead").css('margin-left', '-' + e.currentTarget.scrollLeft + 'px')
    });
  }

  /*eventos padroes drag and drop*/
  onDrop(e: any, uf) {
    var uf_2 = e.dataTransfer.getData("id")
    UsuarioConfiguracao.editSequenceInsumo(this.usuarioConfiguracao, uf, uf_2);
    this.editUsuarioConfiguracao()
  }

  onDragOver(evt) {
    evt.preventDefault()
  }

  onDragLeave(evt) {
    evt.preventDefault()
  }

  drag(e) {
    e.dataTransfer.setData('id', e.target.id)
  }

  editUsuarioConfiguracao() {
    this.context.path = 'usuario/configuracao'
    this.context.put(this.usuarioConfiguracao).subscribe(d => { this.getUsuarioConfiguracao() })
  }

  startAddValor = true
  addValor(model: Insumo, valorEstado: ValorInsumoEstado) {
    if (!valorEstado.insumoId || valorEstado.insumoId < 1)
      return;

    if (this.startAddValor) {
      this.startAddValor = false
      setTimeout(() => {
        this.startAddValor = true
        this.saveValor(valorEstado)
      }, 1500);
    }
    const start = true
  }

  saveValor(valorEstado: ValorInsumoEstado) {
    this.context.path = 'insumo/estado/valor'
    if (valorEstado.id > 0)
      this.context.put(valorEstado).subscribe(d => { })
    else {
      this.context.post(valorEstado).subscribe(d => {
        valorEstado.id = d.id
      })
    }
  }

  getValorPorEstado() {
    this.context.path = 'insumo/estado/valor'

    this.listModel.forEach(async c => {
      if (c.id > 0) {
        this.context.getAll('insumoid = ' + c.id).subscribe(
          d => {
            if (d && d.length > 0) {
              const data = d as Array<ValorInsumoEstado>
              for (let i = 0; i < c._listValorInsumoEstado.length; i++) {
                function filtrar(obj) {
                  return c._listValorInsumoEstado[i].estado == obj.estado
                }
                const item = data.filter(filtrar)

                if (item && item[0]) {
                  c._listValorInsumoEstado[i] = item[0]
                }
              }
            }
          }
        );
      }
    })
  }

  tempIndex = 0
  confirmDelete(model: Insumo, row: number): void {
    this.context.path = 'insumo/halink'
    this.context.get(model.id).subscribe(
      d=>{
        if(d){
          const composicao = d as Array<Composicao>
          this._msgParams.titulo = 'ESTE INSUMO NÃO PODE SER EXCLUÍDO'
          this._msgParams.descricao = 'Há '+d.length+' composiç'+((d.length > 1)?'ões':'ão')+' dependente'+((d.length > 1)?'s':'')+' do insumo <b>'+model.descricao+'</b>.<br /><br />'
          composicao.forEach(c => {
            this._msgParams.descricao += '<a href="'+GlobalVariable.HOST+'/#/composicao/form/'+c.id+'">'+ c.codigo +' - '+c.descricao+'</a> <br />'
          });
          this._msgParams.descricao += '<br />'
          UseFullService.showMsgModal()
        }else{
          this.tempModel = model;
          this.tempIndex = row
          this.confirmService._code = this._code;
          this.confirmService._title = "Excluir Insumo";
          this.confirmService._message = "Deseja excluir insumo: <b>" + model.descricao + "</b>?";
          this.confirmService.Show();
        }
      }
    )    
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return;
    this.context.path = 'insumo'
    this.context.delete(this.tempModel.id).subscribe(
      d => { },
      e => { },
      () => { this.removerRow(this.tempIndex) }
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { Dashboard, FaturamentoDashboard } from 'src/app/domain/auxiliar/dashboard';
import { DashboardService } from 'src/app/service/auxiliar/dashboard.service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Chart } from 'angular-highcharts';
import { chart } from 'highcharts';
import { RepositorioService } from 'src/app/service/repositorio-service';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  _orcamentos = new Array<any>()
  _transacoes = new Array<any>()

  _dashboard = new Dashboard();
  _inicio = "";
  _fim = "";
  _categorias = [];
  _data = [];
  lucroChat: Chart;
  previstoRealizadoChat: Chart;
  principaisGastos: Chart;
  _faturamento_dashboard = new Array<FaturamentoDashboard>();

  _dataResumo = []
  porcentagemInfo1 = 0
  porcentagemInfo2 = 0
  porcentagemInfo3 = 0
  porcentagemInfo4 = 0

  constructor(private dashboardService: DashboardService, private _context: RepositorioService) { }

  ngOnInit() {
    var date = new Date();
    this._fim = UseFullService.formatDateUsForm(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    this._inicio = UseFullService.formatDateUsForm(new Date(date.getFullYear(), 1, 1));
    //this.GetResumo();

    this.startChatLucro()
    this.startPrevistoRealizado()
    this.startCharPrincipaisGastos()
    //this.GetUltimoOrcamentoCompra();
    setTimeout(() => {
      this.porcentagemInfo1 = 85
      this.porcentagemInfo2 = 10
      this.porcentagemInfo3 = 75
      this.porcentagemInfo4 = 42
    }, 10);

    setTimeout(() => {
      this.init()
    }, 100);
  }

  GetResumo() {
    this.dashboardService.Get(this._inicio, this._fim).subscribe(
      d => {
        this._dashboard = d;
        Dashboard.Formatar(this._dashboard);
        //this.startCharCompraEntrega()
      }
    );
    this.dashboardService.GetAll(this._inicio, this._fim).subscribe(
      d => {
        this._faturamento_dashboard = d;
      },
      e => { },
      () => { this.init(); }
    );
  }

  GetUltimoOrcamentoCompra() {
    this._context.path = 'dashboard/ultimo/orcamento'
    this._context.get('0&inicio=' + this._inicio + '&fim=' + this._fim).subscribe(
      d => {
        if (d && d.length > 0) {
          this._orcamentos = d
          this._orcamentos.forEach(c => {
            c.sub_total = UseFullService.ToFixedString(c.sub_total)
            c.data_criacao = UseFullService.formatDate(new Date(c.data_criacao))
          })
        }
      }
    );

    this._context.path = 'dashboard/ultimo/compra'
    this._context.get('0&inicio=' + this._inicio + '&fim=' + this._fim).subscribe(
      d => {
        if (d && d.length > 0) {
          this._transacoes = d
          this._transacoes.forEach(c => {
            c.valor = UseFullService.ToFixedString(c.valor)
            c.data_criacao = UseFullService.formatDate(new Date(c.data_criacao))
          })
        }
      });
  }

  init() {
    setTimeout(() => { $("[class='highcharts-credits']").remove(); $('rect.highcharts-point').css('rx', 0); }, 150);
    setTimeout(() => { $("[class='highcharts-credits']").remove(); $('rect.highcharts-point').css('rx', 0); }, 100);
    setTimeout(() => { $("[class='highcharts-credits']").remove(); $('rect.highcharts-point').css('rx', 0); }, 50);

    $(".chart-faturamento").on("mousemove", function () {
      $('rect.highcharts-point').css('rx', 0);
      $(".valor-faturado").text(UseFullService.ToFixedString(UseFullService.ToDecimalString($('.valor-faturado').text())));
    });
    var _this = this;
    $('#atualizarChat').click(() => { _this.startChatLucro() });
  }

  startPrevistoRealizado() {
    let orcamentosChat = new Chart(Dashboard.chatPrevistoRealizado as any);
    this.previstoRealizadoChat = orcamentosChat;
  }
  
  startChatLucro() {
    let orcamentosChat = new Chart(Dashboard.chatLucro as any);
    this.lucroChat = orcamentosChat;
  }

  startCharPrincipaisGastos() {
    const compraEntregaChat = new Chart(Dashboard.opcaoPrincipaisGastos as any)
    this.principaisGastos = compraEntregaChat
  }

  SetCategorias() {
    this._faturamento_dashboard.forEach(c => {
      this._categorias.push(UseFullService.formatDateDiaMes(new Date(c.data)));
      this._data.push(c.total_diario);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';
import { Authenticate } from 'src/app/domain/authenticate';
import { TypeUser } from 'src/app/domain/usuario/Usuario';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthenticationService]
})
export class LoginComponent implements OnInit {

  public _login: string;
  public _password: string;

  constructor(private httpAuthenticate: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.ConfirmLogin();
  }

  LogInto() {
    this.httpAuthenticate.login(this._login, this._password).subscribe(
      data => this.ConfirmLogin(),
      error => { },
      () => { }
    );
  }

  ConfirmLogin(): void {
    setTimeout(() => {
      if (this.httpAuthenticate.isLoggedIn()) {
        if (this.httpAuthenticate._currentUser.type === TypeUser.licitacao)
          this.router.navigateByUrl('/licitacao');
        else
          this.router.navigateByUrl('/home');
      }
    }, 100);
  }

  LogOut() {
    this.httpAuthenticate.logout();
  }
}
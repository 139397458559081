import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../core/global-variable';
import { Http } from '@angular/http';
import { ImagensDiarioObra } from 'src/app/domain/diario-obra/DiarioObra';

@Injectable({
  providedIn: 'root'
})
export class ImagensDiarioObraService {

  private url = GlobalVariable.BASE_API_URL + "diario-obra/imagem";
  constructor(private http: HttpClient, private simpleHttp: Http) { }

  save(data: ImagensDiarioObra) {
    const formData = new FormData();
    formData.append('file', data._file);
    const img = new Image();

    const options = { params: { 
      "id": String(data.id), 
      "diarioObraId": String(data.diarioObraId), 
      "descricao": data.descricao,
      "maxW": String(data._maxWidth) 
    } }
    return this.http.post<any>(this.url, formData, options).pipe(
      map(r => { return r; })
    );
  }

  update(data: ImagensDiarioObra){    
    return this.http.put<any>(this.url, data).pipe(
      map(r => { return r; })
    );
  }

  GetLoggedIn(): Observable<ImagensDiarioObra> {
    return this.http.get<ImagensDiarioObra>(this.url + "getcnpj").pipe(
      map(r => { return r; })
    );
  }
}

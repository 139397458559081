import { InteractiveTable } from '../common/interactiveTable'

export class Equipamento extends InteractiveTable {
    public id: number
    public empresaId: number
    public descricao: string
    public data: string

    static valida(data: Equipamento) {
        if (!data.descricao) throw ('Descrição é obrigatório!')
    }
}

import { InteractiveTable } from '../common/interactiveTable'

export class MaoDeObraPersonalizada extends InteractiveTable {
    public id: number
    public empresaId: number
    public descricao: string
    public quantidade: number
    public data: string

    static valida(data: MaoDeObraPersonalizada) {
        if (!data.descricao) throw ('Descrição é obrigatório!')
    }
}

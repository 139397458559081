import { Workbook, PaperSize } from 'exceljs';
import * as fs from 'file-saver';
import { RelacaoPlanilhaOrcamentariaItem } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RelacaoComposicao, EnumItemTipo } from 'src/app/domain/engenharia/composicao/relacaoComposicao';
import { IfStmt } from '@angular/compiler';

export class ExcelOrcamentoAnalitico {
  typeRow = ['principal', 'itemDeComposicao']
  tempBDI = 0
  data = []
  total = 0
  totalBDI = 0

  getDatalhesComposicao(itens: RelacaoComposicao[], nivel = 1) {

    itens.forEach(r => {
      const valorTotal = parseFloat(r._valor) * parseFloat(r.quantidade)
      const valorTotalBDI = ((this.tempBDI / 100) * valorTotal) + valorTotal
      this.data.push([
        nivel,
        r.itemTipo,
        r.codigo,
        r.descricao,
        r._unidadeMedida,
        UseFullService.ToFixedString(r.quantidade, 2),
        UseFullService.ToFixedString(r._valor),
        UseFullService.ToFixedString(valorTotal),
        UseFullService.ToFixedString(this.tempBDI),
        UseFullService.ToFixedString(valorTotalBDI),
        'itemDeComposicao'
      ]);
      if (r._composicao && r._composicao._relacaoComposicao && r._composicao._relacaoComposicao.length > 0)
        this.getDatalhesComposicao(r._composicao._relacaoComposicao, nivel + 1)
    })
  }

  generateExcel(cabecalho: any, listRelacaoPlanilha: RelacaoPlanilhaOrcamentariaItem[]) {

    this.getList(listRelacaoPlanilha)
    
    const header = [
      ["Empresa", "Nome da empresa", "", "", "CPNJ:", "00.555.555/0001-55", "", "N° 4568", ""],
      ["Obra", cabecalho.obra.nome, "", "", "inicio", "05/04/2020", "", "fim", "06/05/2021"],
      ["Endereço", "Endereçõ de teste", "", "", "", "", "", "Emissão:", "02/06/2020"],
      ["Planilha", cabecalho.planilha.descricao, "", "", "Cliente", "Nome do cliente", "", "", ""]
      
    ]
    const tableHeader = ["Item", "Código", "Descrição", "Unid.", "Coef.", "R$ Unit.", "R$ Total", "% BDI", "% Total BDI"]

    this.setPlanilha(header, tableHeader)
  }

  


  getList(listRelacaoPlanilha: RelacaoPlanilhaOrcamentariaItem[]): any[] {       

    listRelacaoPlanilha.forEach(c => {
      let tipoValido = (c.itemTipo === EnumItemTipo.grupo || c.itemTipo === EnumItemTipo.subgrupo || c.itemTipo === EnumItemTipo.subgrupoFilho)
      if (c.codigo && !tipoValido) {

        this.tempBDI = parseFloat(c.bdi)
        const quantidade = (c._unidadeMedida) ? UseFullService.ToFixedString((c.itemTipo === EnumItemTipo.composicao) ? 1 : c.quantidade, 2) : ''
        const bdi = (c._unidadeMedida) ? UseFullService.ToFixedString(c.bdi, 2) : ''
        let totalBDI = (c._unidadeMedida) ? c._valorTotal : ''

        if (c.itemTipo === EnumItemTipo.composicao)
          totalBDI = UseFullService.ToFixedString(((this.tempBDI / 100) * parseFloat(c.valor)) + parseFloat(c.valor))

        const subtotal = (c._unidadeMedida) ? c.itemTipo === EnumItemTipo.composicao ? c._valor : c._valorSubTotal : ''

        if (quantidade) {
          this.total += UseFullService.ConvertFloat(subtotal)
          this.totalBDI += UseFullService.ConvertFloat(totalBDI)
        }


        this.data.push([
          0,
          c.itemTipo,
          c.codigo,
          c.descricao,
          c._unidadeMedida,
          quantidade,
          c._valor,
          subtotal,
          bdi,
          totalBDI,
          'principal'])
        if (c._detalheComposicao && c._detalheComposicao._relacaoComposicao && c._detalheComposicao._relacaoComposicao.length > 0) {
          this.getDatalhesComposicao(c._detalheComposicao._relacaoComposicao)
        }
      }
    })

    const compAux = this.data.filter((d) => { return (d[0] > 0 && d[1] === 3) })
    for (let i = 0; i < compAux.length; i++) {
      if (compAux[i][0] > 1)
        compAux[i][0] = compAux[i - 1][0] + '.' + 1
    }
    return this.data
  }

  setPlanilha(header, tableHeader) {
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Teste Planilha');

    //Add Row and formatting

    const title = ['', 'Planilha Analítica'];

    let titleRow = worksheet.addRow(title);
    titleRow.font = { family: 4, size: 16, underline: 'none', bold: true }
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' }
    titleRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    titleRow.getCell(2).border = titleRow.getCell(1).border
    const img64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAYAAABxLuKEAAAACXBIWXMAAAsSAAALEgHS3X78AAACYUlEQVR42u2c0XGDMAyGGSHvvdx5BEZgBEZgBEbwBh6BERjBIzACI3gECj31juaaxBDJkmx897+0aUk+jPRLtlNV17jGNa6Rybjfv26rmlV21bDKr5pXLf9ogt9vr+u3v8sNhoEPNj0BcFQj/L+bViAdIoxXkBpNQGZiII/artdKBdIyAHnUFpNqSQF1ZAbyKMsNZcswQRiUfVYzXLFkEa6QNDiDt1gUqbugcMBRDIUOjpKYEqMaO/ssmSigZCvwKSEjMD9GEAPMmBmUz00g2PwlY5mzYObMwfgzUCzym3Ang35P/Dg33AHXIiQByzpriDyLRbIONcFNq2MvPkluBRDAGWIvKr5HgjyrQ8wFHRGYAYJptBJnzZbjMfrEvrtEmXN4t9Qh0W+4BDXcrNLpJrqRJpWpIwUD75ne7MHyQ8kzxmoDkyLGPC9bhDaVbEJr4TnAHPUxNUP1zwIG2/n2yQpKLWAIyxa9YMBrhQvM3wzkWTp6UsDstqS1kHkm1lYn8R2xCryT41gq0QDGctRKGsA0HNW1BjC3V0GvVDAzVwdPOhjHYbU1gHnb8zUFggmxBmsqDIyLBdMVBsYcqU3mQsAMR4u2rhAw9ZmKFnPWjE86dZOa2ZLppkTcTYqEa9nc6jF2bea25WyU3mNlqYlQjw1msjM8kBz0ygAO3REdxcE4ydGcTtnjk+4gKcAJCgJt+sOjkK0moVA866F18DlO2KPTV1JGopXCmHrMVBIH00l9r+mrDBriBbwAe25MpXFADOoAUkDIMoPY73FAyGQdrHr6ncLuw//+bITXtWpnRm7jG5/krNRqjtglAAAAAElFTkSuQmCC"


    let logo = workbook.addImage({
      base64: img64,
      extension: 'png',
    });

    worksheet.addImage(logo, 'A1:A4');
    worksheet.mergeCells('A1:A4');
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 50;
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(6).width = 14;
    worksheet.getColumn(7).width = 4;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 14;

    worksheet.mergeCells(`B${titleRow.number}:I4`);

    /************ start Cabeçalho ***************/
    let headerRow = []
    for (let i = 0; i < header.length; i++) {
      headerRow.push(worksheet.addRow(header[i]))
      for (let x = 0; x < header[i].length; x++)
        headerRow[i].getCell(x + 1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    }

    this.textBgColor(headerRow[0].getCell(1))
    this.textBgColor(headerRow[0].getCell(5))
    this.textBgColor(headerRow[1].getCell(1))
    this.textBgColor(headerRow[1].getCell(5))
    this.textBgColor(headerRow[1].getCell(8))
    this.textBgColor(headerRow[2].getCell(1))
    this.textBgColor(headerRow[2].getCell(8))
    this.textBgColor(headerRow[3].getCell(1))
    this.textBgColor(headerRow[3].getCell(5))



    headerRow[0].getCell(7).alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells(`B${headerRow[0].number}:D${headerRow[0].number}`);
    worksheet.mergeCells(`F${headerRow[0].number}:G${headerRow[0].number}`);
    worksheet.mergeCells(`H${headerRow[0].number}:I${headerRow[0].number}`);
    worksheet.mergeCells(`B${headerRow[1].number}:D${headerRow[1].number}`);
    worksheet.mergeCells(`F${headerRow[1].number}:G${headerRow[1].number}`);
    worksheet.mergeCells(`B${headerRow[2].number}:G${headerRow[2].number}`);
    this.addRowSeparation(worksheet)
    /************** end Cabeçalho **************/

    //start th
    let tableHeaderRow = worksheet.addRow(tableHeader);
    worksheet.mergeCells(`G${tableHeaderRow.number}:H${tableHeaderRow.number}`);
    tableHeaderRow.eachCell((cell, number) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'A6A6A6' }, bgColor: { argb: '000000' } }
      cell.font = { bold: true }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    //end th

    //start td
    let ponteiro = [0]
    for (let i = 0; i < this.data.length; i++) {
      const d = this.data[i];
      const itemTipo = d[1]
      let color = 'BFBFBF'
      let rowBold = true
      let textBold = (itemTipo === EnumItemTipo.grupo || itemTipo === EnumItemTipo.subgrupo || itemTipo === EnumItemTipo.subgrupoFilho)
      d[1] = ''
      if (!textBold) {
        if (itemTipo === 1)
          d[1] = 'insumo'
        else if (itemTipo === 2)
          d[1] = 'mão de obra'
        else if (itemTipo === 3)
          d[1] = 'composição'
      }

      let textSize = false
      if (d.slice(-1)[0] === this.typeRow[1]) {
        rowBold = false

        textSize = true
        //let esp = ''
        //for (let e = 0; e < espaco; e++) { esp += ' ' }
        //d[2] = esp + d[2]
        //d[1] = esp + d[1]
        if (itemTipo !== EnumItemTipo.composicao) {
          color = 'F1F1F1'
        } else {
          ponteiro[0] += 1
          d[1] = 'composição aux. ' + d[0] // ponteiro[0]
        }

        //codigoAlign = 'center'
      } else if (d.slice(-1)[0] === this.typeRow[0]) {
        ponteiro = [0]
        textSize = false
        this.addRowSeparation(worksheet)
      }

      d.splice(0, 1)
      d.pop()
      let row = worksheet.addRow(d);
      for (let i = 0; i < d.length; i++) {
        let cell = row.getCell(i + 1)
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: color }, bgColor: { argb: '000000' } }
        cell.font = { bold: textBold }
      }

      if (rowBold)
        row.font = { bold: true }
      worksheet.mergeCells(`G${row.number}:H${row.number}`);

      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(2).alignment = { vertical: 'middle', horizontal: !textSize ? 'left' : 'center' }
      row.getCell(3).alignment = { wrapText: true }
      row.getCell(4).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(5).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(5).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(6).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(7).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(9).alignment = { vertical: 'middle', horizontal: 'right' }
    }
    //end td

    //start footer
    let footerRow = worksheet.addRow(['Valor Total']);
    footerRow.font = { bold: true }
    footerRow.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CCCCCC' } }
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    footerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'right' }


    footerRow.getCell(7).fill = footerRow.getCell(1).fill
    footerRow.getCell(7).value = UseFullService.ToFixedString(this.total)
    footerRow.getCell(7).border = footerRow.getCell(1).border
    footerRow.getCell(7).alignment = { vertical: 'middle', horizontal: 'right' }

    footerRow.getCell(9).fill = footerRow.getCell(1).fill
    footerRow.getCell(9).value = UseFullService.ToFixedString(this.totalBDI)
    footerRow.getCell(9).border = footerRow.getCell(1).border
    footerRow.getCell(9).alignment = { vertical: 'middle', horizontal: 'right' }

    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    worksheet.mergeCells(`G${footerRow.number}:H${footerRow.number}`);
    // end footer

    workbook.model.sheets[0].pageSetup.paperSize = PaperSize.A4
    workbook.model.sheets[0].pageSetup.scale = 67
    workbook.model.sheets[0].pageSetup.margins = { top: 0.24, left: 0.24, bottom: 0.24, right: 0.24, header: 0.24, footer: 0.24 }
    workbook.model.sheets[0].pageSetup.printTitlesColumn = 'A:H'
    workbook.model.sheets[0].pageSetup.printArea = 'A1:H20'
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'planilha.xlsx');
    })
  }

  addRowSeparation(worksheet: import("exceljs").Worksheet) {
    let rowWrite = worksheet.addRow([''])
    rowWrite.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' }, bgColor: { argb: 'FFFFFF' } }
  }


  textBgColor(cell: any) {
    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'dddddd' }, bgColor: { argb: '000000' } }
    cell.font = { bold: true }
  }



}

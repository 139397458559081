import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalVariable } from '../../core/global-variable';
import { Http } from '@angular/http';
import { HistoricoFinanceiro } from 'src/app/domain/licitacao/historicoFinanceiro';

@Injectable({
  providedIn: 'root'
})
export class HistoricoFinanceiroService {

  private url = GlobalVariable.BASE_API_URL + "financeiro/";
  constructor(private http: HttpClient, private simpleHttp: Http) { }

  post(data: HistoricoFinanceiro) {
    const formData = new FormData();
    formData.append('file', data._file);
    const options = {
      params: {
        "usuarioId": String(data.usuarioId),
        "dataCriacao": String(data.dataCriacao),
        "dataVencimento": String(data.dataVencimento),
        "status": String(data.status),
        "valor": data.valor,
        "descricao": String(data.assinaturaId)
      }
    }
    return this.http.post<any>(this.url + 'fatura', formData, options).pipe(
      map(r => { return r; })
    );
  }

  put(data: HistoricoFinanceiro) {
    const formData = new FormData();
    formData.append('file', data._file);
    const options = {
      params: {
        "id": String(data.id),
        "dataVencimento": String(data.dataVencimento),
        "status": String(data.status),
        "valor": data.valor,
        "descricao": String(data.assinaturaId),
        "_updateFile": String(data._updateFile)
      }
    }
    return this.http.put<any>(this.url + 'fatura', formData, options).pipe( map(r => { return r; }) );
  }

}

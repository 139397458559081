import { Component, OnInit, Input } from '@angular/core';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { GlobalVariable } from 'src/app/core/global-variable';
import { Profissional } from 'src/app/domain/administracao/profissional';
import { Router, ActivatedRoute } from '@angular/router';
import { ViaCepService, ViaCep } from 'src/app/service/usefull/viacep.service';
import { EnumService } from 'src/app/service/usefull/enum.service';
import { Estado } from 'src/app/domain/enum/estado';
import { table } from 'src/app/domain/common/table';
import { Municipio } from 'src/app/domain/enum/municipio';
import { Endereco } from 'src/app/domain/auxiliar/endereco';

declare var $: any

@Component({
  selector: 'app-profissional-form',
  templateUrl: './profissional-form.component.html',
  styleUrls: ['./profissional-form.component.css']
})
export class ProfissionalFormComponent implements OnInit {

  @Input() viewModal: boolean

  _code = 'app-profissional-form'
  listModel = new Array<Profissional>()
  model = new Profissional()

  _estados = new Array<Estado>();
  _municipios = new Array<Municipio>();
  _viacep = new ViaCep();

  id = this.route.snapshot.params['id'];
  constructor(
    private enumService: EnumService,
    private viaCepService: ViaCepService,
    private router: Router,
    private route: ActivatedRoute,
    private context: RepositorioService,
    public userLoggedService: UserLoggedInService,
    private confirmService: AlertConfirmService
  ) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.model.endereco = new Endereco()
    this.getEstados();
    this.JqueryEnvet()
    if (this.id && !this.viewModal)
      this.get()
  }

  get() {
    this.context.path = 'profissional'
    this.context.get(this.id).subscribe(d => {
      if (d && d.id) {
        this.model = d;
        if (this.model.enderecoId)
          this.getEndereco(this.model.enderecoId);
      }
    })
  }

  getEndereco(id) {
    this.context.path = 'common'
    this.context.get(id, String(table.endereco)).subscribe(d => {
      this.model.endereco = d
      this.getMunicipios(this.model.endereco.estado, false)
    })
  }

  voltar() { this.router.navigate(['/profissional']) }

  insertOrUpdate() {
    this.context.path = 'profissional'
    if (this.model.id)
      this.context.put(this.model).subscribe(d => { })
    else
      this.context.post(this.model).subscribe(d => {
        if(this.viewModal)
          $('#cp-modal').modal('hide')
        this.model = d
      })
  }


  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => { this._estados = d; })
  }

  OnChangeEstado() {
    setTimeout(() => { this.getMunicipios(this.model.endereco.estado); }, 1);
  }

  getMunicipios(uf: string, via_cep: boolean = true) {

    this.context.path = 'common'
    this.context.getAll("Uf='" + uf.trim() + "'", String(table.municipio)).subscribe(d => {
      this._municipios = d;
      if (via_cep)
        this.SetCidadeViaCep();
    })
  }

  OnChangeCep() {
    this.LimparInputs();
    setTimeout(() => {
      if (this.model.endereco.cep != undefined && this.model.endereco.cep.length == 9)
        this.GetCep();
    }, 10);
  }

  GetCep() {
    this.viaCepService.Get(this.model.endereco.cep).subscribe(
      d => { this._viacep = d; },
      e => { },
      () => { this.SetEndereco(); }
    );
  }

  SetEndereco() {
    if (this._viacep.erro)
      return;
    this.model.endereco.bairro = this._viacep.bairro;
    this.model.endereco.endereco = this._viacep.logradouro + " " + this._viacep.complemento;
    this.model.endereco.estado = this._viacep.uf;
    this.getMunicipios(this._viacep.uf);
  }

  LimparInputs() {
    this.model.endereco.bairro = "";
    this.model.endereco.endereco = "";
    this.model.endereco.estado = "";
    this.model.endereco.cidade = "";
  }

  SetCidadeViaCep() {
    this.model.endereco.cidade = this._viacep.ibge;
  }

  JqueryEnvet() {
    setTimeout(() => {
      $("#cep").mask("99999-999");
      $("#telefone").mask("(99) 9 9999-9999");
      $("#data").mask("99/99/9999");
      $("#numero").mask("999999999999999999999999");
      $("#cpf").mask("999.999.999-99");
    }, 2000);

  }
}

import { Endereco } from '../auxiliar/endereco'

export class Empresa {
    public id: number
    public razaoSocial: string
    public nomeFantasia: string
    public cnpj: string
    public logo: string
    public enderecoId: number
    public usuarioResponsavel: number
    public telefone: string

    public _endereco: Endereco
    _img: File

}


export class RelacaoEmpresaUsuario {
    public id: number
    public empresaId: number
    public usuarioId: number
}
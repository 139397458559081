import { InteractiveTable } from '../common/interactiveTable'
import { Profissional } from '../administracao/profissional'
import { Endereco } from '../auxiliar/endereco'
import { UseFullService } from 'src/app/service/usefull/usefull.service'

export class Obra extends InteractiveTable {
    public id: number
    public empresaId: number
    public clienteId: number
    public enderecoId: number
    public cei: string
    public nome: string
    public descricao: string
    public dataInicio: string
    public dataFim: string
    public data: Date
    public numeroContrato: string
    public endereco = new Endereco()
    public status: string
    public profissionais: Array<RelacaoObraProfissional>
    public _dataFim: string
    public _dataInicio: string

    static formatData(model: Obra) {
        model.dataInicio = UseFullService.formatDate(new Date(model.dataInicio))
        model.dataFim = UseFullService.formatDate(new Date(model.dataFim))
    }

    static formatAllData(listModel: Obra[]) { listModel.forEach(c => this.formatData(c)) }

    static getObraId(list: Obra[], id: number): Obra {
        const porId = (o) => { return (o.id === id) }
        var model = list.filter(porId)
        return (model.length > 0) ? model[0] : new Obra
    }
}

export class RelacaoObraProfissional extends Profissional {
    public id: number
    public obraId: number
    public profissionalId: number
    public _id: number
}
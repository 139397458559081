import { RelacaoPlanilhaOrcamentariaItem } from '../orcamento/relacao-planilha-orcamentaria-item'
import { UseFullService } from 'src/app/service/usefull/usefull.service'
import { EnumItemTipo } from '../composicao/relacaoComposicao'
import { PlanejamentoPlanilhaOrcamentaria } from './planejamento-planilha-orcamentaria'
import { Feriado } from './feriado'

export class PlanejamentoItemPlanilhaOrcamentaria {

    public id: number
    public planejamentoId: number
    public relacaoPlanilhaOrcamentariaItemId: number
    public duracaoDias: number
    public observacao: string
    public dataInicio: string
    public dataFim: string
    public predecessoraId: number
    public log: string

    public _dataInicio: any
    public _dataFim: any

    public _intervalWidth: number

    static getPorIdItem(list: PlanejamentoItemPlanilhaOrcamentaria[], id: number): PlanejamentoItemPlanilhaOrcamentaria {
        const haItem = (o) => { return (o.relacaoPlanilhaOrcamentariaItemId == id) }
        const data = list.filter(haItem)
        const item = (data.length > 0) ? data[0] : new PlanejamentoItemPlanilhaOrcamentaria()
        if (!item.duracaoDias)
            item.duracaoDias = null


        return item
    }

    static valida(item: PlanejamentoItemPlanilhaOrcamentaria, planejamento: PlanejamentoPlanilhaOrcamentaria, feriados: Array<Feriado>) {        
        const inicio = UseFullService.formatDateUSTime(item.dataInicio)
        const fim = UseFullService.formatDateUSTime(item.dataFim)

        const days = UseFullService.getDaysDates(inicio, fim)
        let x = 0
        for (let i = 0; i < days; i++) {
            const newDate = UseFullService.formatDateUSTime(item.dataInicio)
            newDate.setDate(inicio.getDate() + i)
            const diaSemana = newDate.getDay()

            var eFeriado = Feriado.eFeriado(feriados, newDate)
            if ((!planejamento.diasNaoUteis || !planejamento.diasNaoUteis.match(String(diaSemana))) && !eFeriado)
                x++
        }
        //const dias = UseFullService.getDaysDates(inicio, fim)

        item.duracaoDias = x
    }


    static AtualizarDuracao(item: RelacaoPlanilhaOrcamentariaItem, planejamento: PlanejamentoPlanilhaOrcamentaria, feriados: Array<Feriado>) {
        let x = 0
        let b = 0
        while (item.planejamento.duracaoDias > x) {
            let inicio = UseFullService.formatDateUSTime(item.planejamento.dataInicio)
            if (b > 0)
                inicio.setDate(inicio.getDate() + b)

            var eFeriado = Feriado.eFeriado(feriados, inicio)
            const diaSemana = inicio.getDay()
            if ((!planejamento.diasNaoUteis || !planejamento.diasNaoUteis.match(String(diaSemana))) && !eFeriado)
                x++
            b++
            if (item.planejamento.duracaoDias == x){
                item.planejamento._dataFim = UseFullService.formatNgbDate(UseFullService.formatDateUsForm(inicio))
                item.planejamento.dataFim = UseFullService.formatDate(inicio)
            }
        }
        //inicio.setDate(inicio.getDate() + item.planejamento.duracaoDias - 1)
        //item.planejamento._dataFim = UseFullService.formatNgbDate(UseFullService.formatDateUsForm(inicio))
    }

    static AtualizarPredecessora(item: RelacaoPlanilhaOrcamentariaItem, predecessor: RelacaoPlanilhaOrcamentariaItem) {
        let inicio = UseFullService.formatDateUSTime(predecessor.planejamento.dataFim)
        inicio.setDate(inicio.getDate() + 1)
        item.planejamento._dataInicio = UseFullService.formatNgbDate(UseFullService.formatDateUsForm(inicio))
        item.planejamento.dataInicio = UseFullService.formatDate(inicio)
    }

    static setDateFim(list: RelacaoPlanilhaOrcamentariaItem[], predecessora: RelacaoPlanilhaOrcamentariaItem) {
        let x = list.map(function (c, i) {
            if (!c.planejamento.dataFim)
                return 0
            const fim = UseFullService.formatDateUSTime(c.planejamento.dataFim)
            return fim.getTime();
        });

        var max = x.reduce(function (a, b) {
            return Math.max(a, b);
        });
        predecessora.planejamento.dataFim = UseFullService.formatDate(new Date(max))
    }

    static verificarPredecessora(list: RelacaoPlanilhaOrcamentariaItem[], predecessora: RelacaoPlanilhaOrcamentariaItem) {
        let x = list.map(function (c, i) {
            const fim = UseFullService.formatDateUSTime(c.planejamento.dataFim)
            return fim.getTime();
        });

        var max = x.reduce(function (a, b) {
            return Math.max(a, b);
        });
        predecessora.planejamento.dataFim = UseFullService.formatDate(new Date(max))
    }

    static getPredecessoras(list: RelacaoPlanilhaOrcamentariaItem[], item: RelacaoPlanilhaOrcamentariaItem): RelacaoPlanilhaOrcamentariaItem[] {
        const obterPorId = function (obj) { return (obj.planejamento.predecessoraId === item.id) }
        const listData = list.filter(obterPorId)
        if (listData.length < 1) {
            //obter grupos e subgrupos do item
            const codigo = item.codigo.split('.')
            const getId = (o) => {
                const codigoObj = o.codigo.split('.')
                const result = (codigoObj[0] === codigo[0] && codigoObj[codigoObj.length - 1] === '0')
                return (result && (o.itemTipo == EnumItemTipo.grupo || o.itemTipo == EnumItemTipo.subgrupo))
            }
            const grupoOrSubGrupo = list.filter(getId)
            //verficar se existe predessores do grupos/subgrupos
            for (let i = 0; i < grupoOrSubGrupo.length; i++) {
                const c = grupoOrSubGrupo[i];
                const obterPorIdGrupoOrSubgrupo = function (obj) {
                    return (obj.planejamento.predecessoraId == c.id)
                }
                const data = list.filter(obterPorIdGrupoOrSubgrupo)
                data.forEach(d => { listData.push(d) })
            }
        }

        return listData;
    }

    static verificarPredecessoraEPai(item: RelacaoPlanilhaOrcamentariaItem, predecessora: RelacaoPlanilhaOrcamentariaItem) {
        if (item.referenciaPai === predecessora.codigo)
            throw "Predessora não pode ser o mesmo grupo ou subgrupo!"
        const a = predecessora.codigo.split('.') // coidgo predessora
        const b = item.codigo.split('.') // codigo item
        if (a.length < b.length && a[a.length - 1] === '0')
            throw "Predecessora não pode ser o mesmo grupo ou subgrupo!"
    }
}

/*
    const obterPorIdGrupoOrSubgrupo = function (p: RelacaoPlanilhaOrcamentariaItem) { return (
        p.id == obj.planejamento.predecessoraId &&
        (p.itemTipo == EnumItemTipo.grupo || p.itemTipo == EnumItemTipo.subgrupo)) }
    const predecessora = this.list.filter(obterPorIdGrupoOrSubgrupo)[0]
*/
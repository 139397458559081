import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';
import { Router } from '@angular/router';
import { GlobalVariable } from '../../global-variable';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { TypeUser } from 'src/app/domain/usuario/Usuario';
import { AlertData } from 'src/app/domain/auxiliar/AlertData';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { MenuSideBar, MenuNavBar } from 'src/app/domain/auxiliar/menuSideBar';
import { Obra } from 'src/app/domain/engenharia/obra';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { contains } from 'jquery';
import { Auxiliar } from '../../auxiliar/auxiliar';

declare var $: any;
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  _code = 'app-nav-bar'
  url = GlobalVariable.BASE_API_URL
  _listMenu = MenuSideBar._listMenu

  public _listMenuNav: Array<object>
  public _listMenuNavConfig: Array<object>
  public _menu_nav_bar: Array<MenuNavBar>
  public _menu_nav_bar_config: Array<MenuNavBar>
  public _user_nome: string

  constructor(@Inject(DOCUMENT) public document: Document, public context: RepositorioService,
    public confirmService: AlertConfirmService,
    public renderer: Renderer2, public httpAuthenticate: AuthenticationService, public router: Router, public userLoggedService: UserLoggedInService) {
  }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();

    var nomes = this.userLoggedService.name.trim().split(' ')
    this._user_nome = nomes[0];
    var ultimoNome = nomes[nomes.length - 1];
    this._user_nome += (ultimoNome != nomes[0]) ? ' ' + ultimoNome : '';

    this.SetMenuConfig();
    this.SetMenuUser();
    this.getObra()
    this.confirmService.unsubscribe();
    this.confirmService.confirmEmitter$.subscribe(c => { this.FecharSolicitacaoUsuario(c); });
  }

  listObra = new Array<Obra>()
  getObra() {
    this.context.path = 'obra'
    this.context.getAll().subscribe(d => {
      this.listObra = d;
      this.atualizarObras()
    })
  }

  OnOfSideBar(e) {
    e.preventDefault();
    if (!this.document.getElementsByClassName('mini-navbar-none').length) {
      this.renderer.addClass(this.document.body, 'mini-navbar-none');
      $('div#page-wrapper').css("left", "0");
    } else {
      this.renderer.removeClass(this.document.body, 'mini-navbar-none');
      $('div#page-wrapper').css("left", "120px");
    }

    if ($('nav.navbar-static-side').css("position") != "fixed")
      $('div#page-wrapper').css("position", "inherit");
    else
      $('div#page-wrapper').css("position", "relative");

    $('#atualizarChat').click();
  }

  LogOut() {
    this.httpAuthenticate.logout();
  }

  SetMenuConfig() {
    this._listMenuNavConfig = MenuNavBar._listMenuNavConfig
    setTimeout(() => { this.CarregarMenuConfig(); }, 10);
  }

  SetMenuUser() {
    this._listMenuNav = MenuNavBar._listMenuNav
    setTimeout(() => { this.CarregarMenu(); }, 1);
  }

  public CarregarMenuConfig() {
    this._menu_nav_bar_config = new Array<MenuNavBar>();
    this._listMenuNavConfig.forEach(c => {
      var menu = c as MenuNavBar;
      var autorizado = false;
      menu.access.forEach(m => {
        if (this.userLoggedService.type === m)
          autorizado = true;
      });
      if (autorizado)
        this._menu_nav_bar_config.push(menu);
    });
  }

  public CarregarMenu() {
    this._menu_nav_bar = new Array<MenuNavBar>();
    this._listMenuNav.forEach(c => {
      var menu = c as MenuNavBar;
      var autorizado = false;
      menu.access.forEach(m => {
        if (this.userLoggedService.type === m)
          autorizado = true;
      });
      if (autorizado)
        this._menu_nav_bar.push(menu);
    });
  }

  _alertData = new AlertData()

  _show_hide_alert = false
  ShowHideAlert() {
    this._show_hide_alert = !this._show_hide_alert
  }

  _temp_orcamento_id: number
  ConfirmSolicitacaoUsuario(orcamento_id: number) {
    this._temp_orcamento_id = orcamento_id
    this.confirmService._code = this._code
    this.confirmService._title = "Ocultar Solicitação"
    this.confirmService._message = "Clique em <b>sim</b>, para ocultar solicitação."
    this.confirmService.Show()
  }

  FecharSolicitacaoUsuario(confirm: boolean) {
    if (!this._temp_orcamento_id || !confirm || this.confirmService._code != this._code) return;
  }


  public MenuClick(e, menu) {
    e.preventDefault();

    if (menu.type == 'dad') {
      menu.subClass = (menu.subClass == 'in') ? '' : 'in';
      //menu.class = (menu.subClass == 'in') ? 'active' : '';
    } else {
      this.router.navigateByUrl(menu.route);

      setTimeout(() => {
        this.AtualizarSideBar();
      }, 50);
    }
  }

  public AtualizarSideBar() {
    var _this = this;
    var url = this.router.url;
    var menu = new MenuSideBar();
    this._listMenu.list.forEach(function (c, i) {
      menu = c as MenuSideBar;

      if (_this.userLoggedService.type === TypeUser.licitacao) {
        menu.disabled = !Auxiliar.contains(menu.route, ['/licitacao'])
      }
      menu.autorizado = false;
      menu.access.forEach(e => {
        if (_this.userLoggedService.type === e)
          menu.autorizado = true;
      });
      if (menu.autorizado && menu.subMenu) { _this.AtualizarSubMenu(menu.subMenu) }
      menu.class = (menu.route == url) ? 'active' : '';
      c = menu;
    });
    const getActive = (o: MenuSideBar) => { return (o.class == 'active') }
    const data = this._listMenu.list.filter(getActive)

    if ((!data || data.length < 1) && this.userLoggedService.type === TypeUser.licitacao) {
      this.setUserLicitacao()
    } else {
      const menuSideBarSelect = (data && data.length > 0) ? data[0] : this._listMenu.list[0]
      this._listMenu.selected = menuSideBarSelect
    }
  }

  setUserLicitacao() {    
    this._listMenu.selected = { text: '', route: '', icon: 'menu', access: [] }
    if(this.router.url.match('minha-conta')){
      this._listMenu.selected.icon = 'user'
      this._listMenu.selected.text = 'Minha Conta'
    }
    if(this.router.url.match('minha-conta-financeiro')){
      this._listMenu.selected.icon = 'dollar-sign'
      this._listMenu.selected.text = 'Minha Assinatura'
    }
  }

  public AtualizarSubMenu(menu) {
    var _this = this;
    var url = this.router.url;
    menu.forEach(function (c, i) {
      menu = c as MenuSideBar;

      if (_this.userLoggedService.type === TypeUser.licitacao) {
        menu.disabled = !Auxiliar.contains(menu.route, ['/licitacao'])
      }
      menu.autorizado = false;
      menu.access.forEach(e => {
        if (_this.userLoggedService.type === e)
          menu.autorizado = true;
      });
      menu.class = (menu.route == url) ? 'active' : '';
      c = menu;
    });
  }

  adicionarObra() {
    this.router.navigateByUrl('/obra/form');
  }

  obraClick(obra: Obra) {
    localStorage.setItem('_params', btoa(JSON.stringify(obra)));
    this.userLoggedService.SetCurrentUser();
    this.obraRedirect()
    this.atualizarObras()
  }

  obra = new Obra()
  atualizarObras() {
    let x = 1
    this.listObra.forEach(c => { c._index = x++; c._active = false })
    setTimeout(() => {
      this.obra = this.listObra.filter(o => { return (this.userLoggedService._obra && o.id === this.userLoggedService._obra.id) })[0]
      if (this.obra) {
        this.obra._active = true
      }
    }, 100);
  }

  obraRedirect() {
    const directories = {
      planilha: {
        redirect: '/planilha-orcamentaria/list',
        directories: [
          '/planilha-orcamentaria/form'
        ]
      },
      planejamento: {
        redirect: '/planilha-orcamentaria/planejamento',
        directories: [
          '/planilha-orcamentaria/planejamento'
        ]
      },
      medicao: {
        redirect: '/planilha-orcamentaria/medicao',
        directories: [
          '/planilha-orcamentaria/medida'
        ]
      },
      diarioObra: {
        redirect: '/diario-obra/list',
        directories: [
          '/diario-obra/view',
          '/diario-obra/form'
        ]
      }
    }

    if (directories.planilha.redirect === window.location.pathname || window.location.pathname.match(directories.planilha.directories[0]))
      window.location.href = directories.planilha.redirect

    if (window.location.pathname.match(directories.planejamento.redirect))
      window.location.href = directories.planejamento.redirect

    if (window.location.pathname.match(directories.medicao.redirect))
      window.location.href = directories.medicao.redirect

    if (directories.diarioObra.redirect === window.location.pathname || window.location.pathname.match(directories.diarioObra.directories[0]) || window.location.pathname.match(directories.diarioObra.directories[1]))
      window.location.href = directories.diarioObra.redirect
  }

  showMsg(){ $('#message-modal-nav').modal('show') }  
}
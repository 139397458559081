import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Authenticate } from 'src/app/domain/authenticate';
import { GlobalVariable } from 'src/app/core/global-variable';
import { UserLoggedInService } from './user-logged-in.service';
import { Router } from '@angular/router';
import { TypeUser } from 'src/app/domain/usuario/Usuario';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _url = GlobalVariable.BASE_API_URL + "auth";
  public _currentUser: Authenticate;
  public _typeUser: TypeUser;

  constructor(private http: HttpClient, private userLoggedInService: UserLoggedInService, private router: Router) {
    //this.SetCurrentUser();
  }

  login(username: string, password: string): Observable<Authenticate> {
    return this.http.post<Authenticate>(this._url, { email: username, password: password })
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  loginSite(token: string): Observable<Authenticate> {
    return this.http.get<Authenticate>(GlobalVariable.BASE_API_URL + "authToken?token=" + token)
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  RenewLogin(): Observable<Authenticate> {
    return this.http.get<Authenticate>(this._url)
      .pipe(
        map(data => {
          if (data && data.token)
            localStorage.setItem('currentUser', JSON.stringify(data));
          return data as Authenticate;
        })
      );
  }

  logout(): void {
    this._currentUser = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tWCIRc');
    localStorage.removeItem('_params')
    if (!GlobalVariable.BASE_LOGIN)
      window.location.href = "https://paineldaengenharia.com.br";
    else
      this.router.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    this.SetCurrentUser();
    if (this._currentUser && this._currentUser.token && this._currentUser.id > 0) {
      this._typeUser = this._currentUser.type;
      return this.CheckTempLogin();
    } else
      return false;
  }

  SetCurrentUser(): void {
    this._currentUser = JSON.parse(localStorage.getItem('currentUser')) as Authenticate;
    this.userLoggedInService.SetCurrentUser();
  }

  CheckTempLogin(): boolean {
    var expires = new Date(this._currentUser.expires);
    var hora_atual = new Date();
    if (expires > hora_atual) {
      hora_atual.setMinutes(hora_atual.getMinutes() + GlobalVariable.TIME_RENEW_ACCESS);
      setTimeout(() => {
        if (hora_atual > expires)
          this.RenewLogin().subscribe();
      }, 10);
      return true;
    } else
      return false;
  }
}

import { InteractiveTable } from '../common/interactiveTable'

export class Endereco extends InteractiveTable{
    public id: number
    public origemId: number
    public cep: string
    public endereco: string
    public bairro: string
    public cidade: string
    public estado: string
    public numero: number
    public complemento: string
    public telefone: string
    public contato: string
    public data: Date;
    public cidadeNome: string
}

export enum EnumOrigemEndereco{
    empresa = 1,
    obra = 2,
    profissional = 3
}

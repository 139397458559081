export class UnidadeMedida {
 
    public id: number
    public nome: string
    public descricao: string

    static get (unidades: Array<UnidadeMedida>, id: number): UnidadeMedida {
      const FiltrarPorId = function (obj: UnidadeMedida): boolean {
        return (obj.id === id)
      }
      const data = unidades.filter(FiltrarPorId)
      return data[0]
    } 

    static getName (unidades: Array<UnidadeMedida>, id: number): string {
      var data = UnidadeMedida.get(unidades, id)
        return (data)?data.nome:String()
    } 
    
    static setModel(list: any, unidadesDeMedida: UnidadeMedida[]) {
      let x = 1
      list.forEach(c => { 
        c._index = x++
        c._unidadeMedida = UnidadeMedida.getName(unidadesDeMedida, c.unidadeMedidaId) 
      });
    }
}

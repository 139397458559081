import { TypeUser } from '../domain/usuario/Usuario';
import { UseFullService } from '../service/usefull/usefull.service';

export class AuthRouter{

  public static CheckRoutePermission(url: string, typeUser: TypeUser): boolean{
    var result = !(TypeUser.licitacao === typeUser);
    this.GetJson().forEach(e => {
      var rota = e as RouterUser;

      if(parseInt(url.split('/').pop())){
        rota.route +="/"+url.split('/').pop();
      }
      if(rota.route == url){        
        result = false;
        rota.access.forEach(r=>{
          if(r == typeUser)
            result = true;
        });        
      }
    });
          
    UseFullService.SetMask(200)

    console.log(typeUser, result)    

    return result;
  }
  
  private static GetJson() : any{    
    var routers = [

      //configurações
      { route: '/configuracao', access: [TypeUser.administrator ]},
      { route: '/configuracao-adm', access: [TypeUser.administrator] },
      { route: '/configuracao-adm/admin/categoria-nivel', access: [TypeUser.administrator] },
      { route: '/configuracao-adm/admin/vincula-niveis', access: [TypeUser.administrator] },
      { route: '/configuracao-adm/admin/categoria-virtual', access: [TypeUser.administrator] },


      //pagina inicial
      { route: '/home', access: [TypeUser.administrator ] },

      //usuario
      { route: '/user', access: [TypeUser.administrator ] },
      { route: '/user/gerenciar-usuario', access: [TypeUser.administrator ] },
      { route: '/user/gerenciar-acesso', access: [TypeUser.administrator ] },
      { route: '/user/minha-conta', access: [TypeUser.administrator, TypeUser.licitacao ] },
      { route: '/user/minha-conta-financeiro', access: [TypeUser.administrator, TypeUser.licitacao ] },

      //licitacao
      { route: '/licitacao', access: [TypeUser.administrator, TypeUser.licitacao ] }
    ];
    return routers;
  }
}

export class RouterUser{
  public route: string;
  public access: Array<TypeUser>;
}
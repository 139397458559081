import { Component, OnInit } from '@angular/core';
import { MenuSideBar } from 'src/app/domain/auxiliar/menuSideBar';

@Component({
  selector: 'app-menu-content',
  templateUrl: './menu-content.component.html',
  styleUrls: ['./menu-content.component.css']
})

export class MenuContentComponent implements OnInit {

  constructor() { }
  menu = MenuSideBar._listMenu.list[7]

  ngOnInit() { }
  
}

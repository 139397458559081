import { InteractiveTable, EnumState } from '../../common/interactiveTable'
import { EnumItemTipo } from '../composicao/relacaoComposicao'
import { UseFullService } from 'src/app/service/usefull/usefull.service'
import { Composicao } from '../composicao/composicao'
import { MedicaoItemPlanilhaOrcamentaria } from '../medicao-planilha-orcamentaria'
import { PlanejamentoItemPlanilhaOrcamentaria } from '../planejamento/planejamento-item-planilha-orcamentaria'
import { PlanilhaOrcamentaria } from './planilha-orcamentaria'

export class RelacaoPlanilhaOrcamentariaItem extends InteractiveTable {

    public id: number
    public planilhaOrcamentariaId: number
    public itemTipo: number
    public itemId: number
    public origem: number
    public referencia: string
    public referenciaPai: string
    public codigoItem: string
    public memoriaCalculo: string

    public codigo: string
    public descricao: string
    public unidadeMedidaId: number

    public quantidade: string
    public bdi: string
    public valor: string
    public usuarioId: string
    public data: Date

    public medicao: MedicaoItemPlanilhaOrcamentaria
    public planejamento = new PlanejamentoItemPlanilhaOrcamentaria()

    _unidadeMedida: string
    _itemTipo: string
    _valor: string
    _valorSubTotal: string
    _valorTotal: string
    _quantidade: string
    _bdi: string
    _actions: ActionRow
    _typeRow: EnumActionRow
    _itemTipoTemp: EnumItemTipo
    _toSon: boolean

    _listComposicao: Array<Composicao>
    _detalheComposicao: Composicao

    public _update: boolean

    setReferencia(referencia = '0', referenciaPai = '0') {
        this.referencia = referencia
        this.referenciaPai = referenciaPai
    }

    static setTextInputItemTipo(item: RelacaoPlanilhaOrcamentariaItem) {
        switch (item.itemTipo) {
            case EnumItemTipo.grupo:
                item._textInputItemTipo = 'Digite uma descrição para o grupo'
                break;
            case EnumItemTipo.subgrupo:
                item._textInputItemTipo = 'Digite uma descrição para o ' + ((item.referencia === item.referenciaPai) ? 'subgrupo' : 'grupo')
                break;
            case EnumItemTipo.composicao:
                item._textInputItemTipo = 'Pesquisar composição'
                break;
            case EnumItemTipo.maodeobra:
                item._textInputItemTipo = 'Pesquisar mão de obra'
                break;
            default:
                item._textInputItemTipo = 'Pesquisar insumo'
                break;
        }
    }

    static setProperties(model: RelacaoPlanilhaOrcamentariaItem) {
        if (model.itemTipo === EnumItemTipo.composicao || model.itemTipo === EnumItemTipo.insumo || model.itemTipo === EnumItemTipo.maodeobra) {
            model._valor = UseFullService.ToFixed(model.valor)

            var quantidade = (model.quantidade) ? parseFloat(model.quantidade) :0
            var bdi = (model.bdi) ? parseFloat(model.bdi) :0
            
            const subtotal = parseFloat(model.valor) * quantidade
            const total = subtotal + ((bdi / 100) * subtotal)
            model._valorSubTotal = UseFullService.ToFixed(subtotal)
            model._valorTotal = UseFullService.ToFixed(total)
            model.quantidade = (model.quantidade) ? UseFullService.ToDecimalString(UseFullService.ToFixed(model.quantidade)) :''
            model.bdi = (model.bdi) ? UseFullService.ToDecimalString(UseFullService.ToFixed(model.bdi)) : ''
        }
        if (!model._actions)
            model._actions = this.getActions()
    }

    static async gerarCodigo(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {
        const obterPorReferencia = function (obj) { return (obj.codigo == model.referencia) }
        const modelRefenciada = await list.filter(obterPorReferencia)
        let digitos = model.referencia.split('.')
        if ((!modelRefenciada || modelRefenciada.length < 1) && !(digitos[0] == '0' && model.itemTipo == EnumItemTipo.grupo))
            throw ('Referência inválida!')

        //ser for grupo já passa para proximo
        if (model.itemTipo == EnumItemTipo.grupo)
            await this.gerarCodigoGrupo(list, model)
        else if (model.itemTipo == EnumItemTipo.subgrupo)
            await this.gerarCodigoSubGrupo(list, model)
        else {
            await this.gerarCodigoItem(list, model)
        }
        model._state = EnumState.insert
    }

    //gerar e verificar codigo dos grupos
    static async gerarCodigoGrupo(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {
        const digitos = model.referencia.split('.')
        model.codigo = String(parseInt(digitos[0]) + 1) + '.0'
        model.referencia = model.codigo
        model.referenciaPai = model.codigo
        await this.ordenarPorGrupo(list, model)
    }

    //organizar grupos
    static async ordenarPorGrupo(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {

        const getAllReferency = function (obj) {

            if (!obj.id)
                return false

            const a0 = parseInt(obj.codigo.split('.')[0])
            const b0 = parseInt(model.codigo.split('.')[0])
            /*
            return (
                obj.codigo >= model.codigo &&
                obj.id &&
                obj.codigo.split('.')[0] >= model.codigo.split('.')[0]*/
            return (a0 >= b0)
        }
        const listItens = list.filter(getAllReferency)

        await this.ordenarGrupoOrSubGrupo(listItens, (model.codigo.split('.').length - 2), false, model)
    }

    //gerar e verificar codigo dos subgrupos
    static async gerarCodigoSubGrupo(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {

        let digitos = model.referenciaPai.split('.')
        let countDigitos = digitos.length

        const digitosReferencia = model.referencia.split('.')
        const countDigitosReferencia = digitosReferencia.length

        let origin = 1 // 1: referenciaPai 2:referencia
        if (countDigitos < countDigitosReferencia) {
            origin = 2
            digitos = digitosReferencia
            countDigitos = countDigitosReferencia
        }

        let codigo = String()
        if (countDigitos >= 3 && model.referencia != model.referenciaPai) {
            digitos[countDigitos - 2] = String(parseFloat(digitos[countDigitos - 2]) + 1)
            digitos[countDigitos - 1] = '0'
            for (let i = 0; i < countDigitos; i++)
                codigo += digitos[i] + (((i + 1) < countDigitos) ? '.' : '')
        } else {
            for (let i = 0; i < countDigitos; i++) {
                if ((i + 1) < countDigitos)
                    codigo += digitos[i] + '.'
                else
                    codigo += (parseFloat(digitos[i]) + 1) + '.0'
            }
        }


        //codigo = await this.verificaSubGrupoCodigo(list, codigo)
        //const referenciaPai = this.getReferenceDad(list, model)

        if (origin === 1)
            model.referencia = model.referenciaPai
        //model.referenciaPai = referenciaPai
        model.codigo = codigo

        await this.ordenarListSubGrupo(list, model)

        const heranca = (codigo.split('.').length > model.referencia.split('.').length)
        const getAllReferency = function (obj) {
            return (heranca && obj.referenciaPai == model.referencia && obj.id && obj.itemTipo !== EnumItemTipo.subgrupo && obj.itemTipo !== EnumItemTipo.grupo)
        }
        const listRefenciada = await list.filter(getAllReferency)

        if (listRefenciada && listRefenciada.length > 0) {
            for (let i = 0; i < listRefenciada.length; i++) {
                const c = listRefenciada[i];
                await this.moverListParaSubGrupo(list, c, model);
            }
        }
    }

    static async ordenarListSubGrupo(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {

        const model_digitos = model.codigo.split('.')
        //const max_digitos = model.codigo.split('.')
        const nivel = model_digitos.length - 2;
        const startLeft = (nivel + 2) - nivel
        const numNivel = parseInt(model_digitos[nivel])
        const getAllReferency = function (obj) {
            if (!obj.id || !obj.codigo || obj.itemTipo === EnumItemTipo.grupo)
                return false

            const obj_digitos = obj.codigo.split('.')
            if (!(obj_digitos.length >= model_digitos.length) || obj_digitos[0] !== model_digitos[0] || obj_digitos[nivel - 1] !== model_digitos[nivel - 1])
                return false

            let result = true
            for (let x = 0; x < nivel + 1; x++) {
                const oA = parseInt(obj_digitos[x])
                const oB = parseInt(model_digitos[x])
                result = (result) ? (oA >= oB) : false
            }

            return result
        }
        const listItens = list.filter(getAllReferency)

        PlanilhaOrcamentaria.ordenarList(listItens)
        if (listItens && listItens.length > 0)
            await this.ordenarGrupoOrSubGrupo(listItens, nivel, false, model)
    }

    static async ordenarGrupoOrSubGrupo(list: RelacaoPlanilhaOrcamentariaItem[], nivel, subtrai = false, model: RelacaoPlanilhaOrcamentariaItem) {

        for (let i = 0; i < list.length; i++) {
            const c = list[i]
            const _digitos = c.codigo.split('.')
            if (subtrai)
                _digitos[nivel] = (parseFloat(_digitos[nivel]) - 1).toString()
            else
                _digitos[nivel] = (parseFloat(_digitos[nivel]) + 1).toString()

            const codigo = await this.concatenarDigitos(_digitos)

            /********* GRUPO *********/
            if (list[i].itemTipo === EnumItemTipo.grupo) {
                list[i].referencia = codigo
                list[i].referenciaPai = codigo
            } else if (i > 0) {
                const ant = list[i - 1]
                /********* SUBGRUPO *********/
                if (c.itemTipo === EnumItemTipo.subgrupo) {
                    if (ant.itemTipo === EnumItemTipo.subgrupo || ant.itemTipo === EnumItemTipo.grupo) {
                        list[i].referenciaPai = ant.codigo
                        list[i].referencia = ant.codigo
                    } else {
                        let tempDigitos = _digitos
                        const countCodigo = _digitos.length
                        tempDigitos[countCodigo - 2] = String(parseFloat(tempDigitos[countCodigo - 2]) - 1)
                        list[i].referencia = await this.concatenarDigitos(tempDigitos)
                    }

                    /********* ITEM *********/
                } else {
                    list[i].referencia = ant.codigo
                    if (ant.itemTipo === EnumItemTipo.subgrupo || ant.itemTipo === EnumItemTipo.grupo)
                        list[i].referenciaPai = ant.codigo
                    else
                        list[i].referenciaPai = ant.referenciaPai
                }

            } else {
                /********* SUBGRUPO *********/
                if (list[i].itemTipo === EnumItemTipo.subgrupo) {
                    list[i].referenciaPai = list[i].referenciaPai
                    list[i].referencia = model.codigo
                }
            }

            list[i].codigo = codigo
            list[i]._state = EnumState.update
        }
    }

    //verifica se existe um subgrupo com codigo 
    static async verificaSubGrupoCodigo(list, codigo): Promise<string> {
        const dataExisting = await this.getCodigo(list, codigo)
        if (dataExisting) {
            const digitos = codigo.split('.')
            digitos[digitos.length - 2] = parseFloat(digitos[digitos.length - 2]) + 1
            codigo = await this.verificaSubGrupoCodigo(list, this.concatenarDigitos(digitos))
        }
        return codigo
    }

    static concatenarDigitos(digitos: Array<string>) {
        let codigo = String()
        for (let i = 0; i < digitos.length; i++)
            codigo += (digitos.length == (i + 1)) ? digitos[i] : digitos[i] + '.'
        return codigo
    }

    //obter por codigo
    static async getCodigo(list: RelacaoPlanilhaOrcamentariaItem[], codigo): Promise<RelacaoPlanilhaOrcamentariaItem> {
        const obterPai = function (obj) { return (obj.codigo === codigo) }
        const data = await list.filter(obterPai)
        return (data && data.length > 0) ? data[0] : null
    }

    //obtem o pai de um model
    static getReferenceDad(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem): string {
        const obterPai = function (obj) { return (obj.codigo === model.referenciaPai) }
        const data = list.filter(obterPai)
        if (data && data.length > 0) {
            if (data[0].referenciaPai && data[0].referenciaPai !== '0')
                return data[0].referenciaPai
            else
                return model.referenciaPai
        } else
            throw ("Referência não encontrada!")
    }

    //reordenar lista dentro do subgrupo
    static moverListParaSubGrupo(list: RelacaoPlanilhaOrcamentariaItem[], referencia: RelacaoPlanilhaOrcamentariaItem, model: RelacaoPlanilhaOrcamentariaItem) {
        for (let x = 0; x < list.length; x++) {
            const c = list[x];
            if (c.codigo === referencia.codigo) {
                const digitosSubgrupo = model.codigo.split('.')
                const antingo = c.codigo.split('.')
                const sequencia = antingo[antingo.length - 1]

                let codigo = String()
                let referencia = String()
                for (let i = 0; i < digitosSubgrupo.length; i++) {
                    codigo += ((i + 1) === digitosSubgrupo.length) ? sequencia : digitosSubgrupo[i] + '.'
                    referencia += ((i + 1) === digitosSubgrupo.length) ? (parseFloat(sequencia) - 1) : digitosSubgrupo[i] + '.'
                }

                list[x].referenciaPai = model.codigo
                list[x].referencia = referencia
                list[x].codigo = codigo
                list[x]._state = EnumState.update
            }
        }
    }

    //Gerar codigo itens
    static async gerarCodigoItem(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {

        const digitos = model.referencia.split('.')
        let codigo = String()
        for (let i = 0; i < digitos.length; i++) {
            codigo += (digitos.length == (i + 1)) ? (parseInt(digitos[i]) + 1) : digitos[i] + '.'
        }
        model.codigo = codigo

        const referenciaArray = model.referencia.split('.')
        const countReferencia = referenciaArray.length - 1
        const lastRef = parseInt(referenciaArray[countReferencia])
        const getAllReferency = function (obj) {

            if (!obj.id || obj.referenciaPai != model.referenciaPai ||
                !(obj.itemTipo === EnumItemTipo.composicao || obj.itemTipo === EnumItemTipo.insumo || obj.itemTipo === EnumItemTipo.maodeobra))
                return false
            const o = obj.referencia.split('.')
            const oReferencia = parseInt(o[countReferencia])
            return (oReferencia >= lastRef)
        }

        const listRefenciada = await list.filter(getAllReferency)
        if (listRefenciada && listRefenciada.length > 0) {
            for (let y = 0; y < listRefenciada.length; y++) {
                const c = listRefenciada[y];
                await this.moverListItens(c, model, y);
            }
        }
    }

    static async deleteItem(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {

        const getAllReferency = function (obj) {
            return (
                obj.referenciaPai == model.referenciaPai &&
                obj.referencia >= model.referencia &&
                obj.id &&
                (obj.itemTipo === EnumItemTipo.composicao || obj.itemTipo === EnumItemTipo.insumo || obj.itemTipo === EnumItemTipo.maodeobra)
            )
        }

        const listRefenciada = await list.filter(getAllReferency)

        if (listRefenciada && listRefenciada.length > 0) {
            for (let y = 0; y < listRefenciada.length; y++) {
                const c = listRefenciada[y];
                await this.moverListItens(c, model, y, true);
            }
        }
    }

    //reordenar lista de itens
    static moverListItens(referencia: RelacaoPlanilhaOrcamentariaItem, model: RelacaoPlanilhaOrcamentariaItem, ultimoDigito, subtrai = false) {

        let digitos = model.codigo.split('.')
        let sequencia = 0
        if (subtrai) {
            digitos = referencia.codigo.split('.')
            sequencia = parseFloat(digitos[digitos.length - 1]) - 1
        } else
            sequencia = parseFloat(digitos[digitos.length - 1]) + (ultimoDigito + 1)
        let codigo = String()
        let _referencia = String()
        for (let i = 0; i < digitos.length; i++) {
            codigo += ((i + 1) === digitos.length) ? sequencia : digitos[i] + '.'
            _referencia += ((i + 1) === digitos.length) ? (sequencia - 1) : digitos[i] + '.'
        }
        referencia.referenciaPai = model.referenciaPai
        referencia.referencia = _referencia
        referencia.codigo = codigo
        referencia._state = EnumState.update
    }


    static async deleteGrupoOrSubgrupo(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {

        const model_digitos = model.codigo.split('.')
        const nivel = model_digitos.length - 2;

        const getAllReferency = function (obj) {
            if (!obj.codigo)
                return false

            const obj_digitos = obj.codigo.split('.')

            return (
                obj.codigo >= model.codigo &&
                obj.id &&
                (obj.referenciaPai !== model.referenciaPai || model.itemTipo === EnumItemTipo.grupo) &&
                obj.itemTipo !== EnumItemTipo.grupo &&
                obj_digitos[0] === model_digitos[0] &&
                obj_digitos[nivel] === model_digitos[nivel]
            )
        }

        const listRemove = await list.filter(getAllReferency)
        for (let r = 0; r < listRemove.length; r++)
            listRemove[r]._state = EnumState.delete
        model._state = EnumState.delete

        const getFilter = function (obj) {
            if (!obj.codigo)
                return false

            const obj_digitos = obj.codigo.split('.')
            return (
                obj.codigo >= model.codigo &&
                obj._state != EnumState.delete &&
                obj.id &&
                obj_digitos.length >= model_digitos.length &&
                (obj_digitos[0] === model_digitos[0] || model.itemTipo === EnumItemTipo.grupo) &&
                (obj.itemTipo !== EnumItemTipo.grupo || model.itemTipo === EnumItemTipo.grupo)
            )
        }

        const listItens = list.filter(getFilter)

        if (listItens && listItens.length > 0)
            await this.ordenarGrupoOrSubGrupo(listItens, nivel, true, model)
    }


    static valida(model: RelacaoPlanilhaOrcamentariaItem): RelacaoPlanilhaOrcamentariaItem {
        return model
    }

    static getActions(): ActionRow {
        return {
            nivel: true,
            grupo: true,
            subgrupo: true,
            itens: true,
            add: true,
            remove: true,
            delete: true
        }
    }

    static actions(list: RelacaoPlanilhaOrcamentariaItem[], row: RelacaoPlanilhaOrcamentariaItem, modelPai: RelacaoPlanilhaOrcamentariaItem) {

        let actions = this.getActions()
        row._actions = actions
        const getAllSubGrupo = function (obj) {
            return (obj.referenciaPai == row.referenciaPai && obj.id && obj.itemTipo === EnumItemTipo.subgrupo)
        }



        /******* ações para o grupo ********/
        if (modelPai.itemTipo === EnumItemTipo.grupo) {
            const dataSubgrupos = list.filter(getAllSubGrupo)
            row._actions.itens = !(dataSubgrupos && dataSubgrupos.length > 0)
            row._actions.subgrupo = false
            row.itemTipo = EnumItemTipo.subgrupo

            /******* ações para o subgrupo ********/
        } else if (row.itemTipo === EnumItemTipo.subgrupo) {
            row._actions.nivel = false
            const dataSubgrupos = list.filter(getAllSubGrupo)
            row._actions.itens = !(dataSubgrupos && dataSubgrupos.length > 0)
            row.itemTipo = EnumItemTipo.subgrupo
            row._itemTipoTemp = EnumItemTipo.subgrupoFilho
        }




        /*** item ***/
        if (modelPai.itemTipo === EnumItemTipo.insumo || modelPai.itemTipo === EnumItemTipo.composicao || modelPai.itemTipo === EnumItemTipo.maodeobra) {
            row._actions.nivel = false
            row._actions.grupo = false
            row._actions.subgrupo = false
        }
    }
    static async obterItensGrupoOrSubGrupo(list: RelacaoPlanilhaOrcamentariaItem[], _codigo): Promise<RelacaoPlanilhaOrcamentariaItem[]> {
        const digitos = _codigo.split('.')
        const count = digitos.length - 1;
        let codigo = String()
        for (let i = 0; i < count; i++) {
            codigo += (count == (i + 1)) ? digitos[i] : digitos[i] + '.'
        }

        const getAllReferency = function (obj) {
            const arrayCodigo = obj.codigo.split('.')
            let codigoItem = String()
            for (let i = 0; i < count; i++)
                codigoItem += (count == (i + 1)) ? arrayCodigo[i] : arrayCodigo[i] + '.'
            return (codigo === codigoItem &&
                (obj.itemTipo === EnumItemTipo.composicao || obj.itemTipo === EnumItemTipo.insumo || obj.itemTipo === EnumItemTipo.maodeobra)
            )
        }

        const data = await list.filter(getAllReferency)
        return data;
    }

}

export interface ActionRow {
    nivel: boolean // na base de dados é grupo
    grupo: boolean // na base e subgrupo
    subgrupo: boolean // simbolico apenas controle de ações dos itens do tipo subgrupo filho de subgrupo
    itens: boolean
    add: boolean
    remove: boolean
    delete: boolean
}

export enum EnumActionRow {
    nivel = 1,
    grupo = 2,
    subgrupo = 3,
    insumo = 4,
    maodeobra = 5,
    composicao = 6
}

export enum EnumOrigem {
    proprio = 0,
    sinapi = 1
}

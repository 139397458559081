import { Component, OnInit } from '@angular/core';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { Estado } from 'src/app/domain/enum/estado';
import { table } from 'src/app/domain/common/table';
import { Router, ActivatedRoute } from '@angular/router';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { Obra } from 'src/app/domain/engenharia/obra';
import { UseFullService } from 'src/app/service/usefull/usefull.service';

declare var $: any
@Component({
  selector: 'app-list-planilha-orcamentaria',
  templateUrl: './list-planilha-orcamentaria.component.html',
  styleUrls: ['./list-planilha-orcamentaria.component.css']
})
export class ListPlanilhaOrcamentariaComponent implements OnInit {

  _code = 'app-list-planilha-orcamentaria'
  _msgParams = { titulo: '', descricao: '' }
  model = new PlanilhaOrcamentaria()
  listPlaninha = new Array<PlanilhaOrcamentaria>()
  tempListPlaninha = new Array<PlanilhaOrcamentaria>()
  listEstado = new Array<Estado>()
  listObra = new Array<Obra>()

  _estado = '';
  _obra = '';

  _actionPage = this.route.snapshot.params['medicao'];

  constructor(
    private context: RepositorioService,
    private router: Router,
    private route: ActivatedRoute,
    public userLoggedService: UserLoggedInService,
    private confirmService: AlertConfirmService) {
    this.userLoggedService.SetCurrentUser();
  }

  ngOnInit() {
    this.getEstados()
    this.getObra()
    
    this.confirmService.confirmEmitter$.subscribe(c => { this.delete(c) })
  } 
  
  getObra() {
    this.context.path = 'obra'
    this.context.getAll().subscribe(d => { this.listObra = d; })
  }
  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => { this.listEstado = d; this.get() })
  }

  onChangeEstado() {
    setTimeout(() => {
      const porEstado = (obj) => { return (this.userLoggedService._obra && obj.obraId === this.userLoggedService._obra.id) }
      this.tempListPlaninha = this.listPlaninha.filter(porEstado)
    }, 10);
  }

  get() {
    this.context.path = 'planilha-orcamentaria'
    this.context.getAll().subscribe(d => {
      this.listPlaninha = d;
      this.tempListPlaninha = d;
      this.onChangeEstado()
      PlanilhaOrcamentaria.set(this.listPlaninha)
      UseFullService.StartTooltip()
    })
  }

  gerenciarPlanilha(model: PlanilhaOrcamentaria) {
    this.router.navigate(['/planilha-orcamentaria/form/', model.id]);
  }

  novaPlanilha() {
    this.newPlanilha = new PlanilhaOrcamentaria()
    this.newPlanilha.obraId = this.userLoggedService._obra.id
    this.newPlanilha._enderecoId = this.userLoggedService._obra.enderecoId
    $('#data-modal').modal('show');
  }
  editPlanilha(planilha: PlanilhaOrcamentaria) {
    this.newPlanilha = planilha
    $('#data-modal').modal('show');
  }

  newPlanilha = new PlanilhaOrcamentaria()
  insertOrUpdatePlanilha() {
    this.context.path = 'planilha-orcamentaria'
    setTimeout(async () => {
      if (this.newPlanilha.id)
        this.context.put(this.newPlanilha).subscribe(d=>{ $('#data-modal').modal('hide'); })
      else {
        this.context.post(this.newPlanilha).subscribe(async d => {
          if (d && d.id) {
            this.ngOnInit()
            $('#data-modal').modal('hide');
          }
        })
      }
    }, 1)
  }

  gerenciarMedicoesPlanilha(model: PlanilhaOrcamentaria) {
    this.router.navigate(['/planilha-orcamentaria/medida/', model.id]);
  }

  confirmDelete(model: PlanilhaOrcamentaria): void {
    this.model = model;
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Planilha Orçamentaria";
    this.confirmService._message = "Deseja excluir a planilha orçamentaria: <b>" + model.descricao + "</b>?";
    this.confirmService.Show();
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return;
    this.context.path = 'planilha-orcamentaria'
    this.context.delete(this.model.id).subscribe(
      d => { },
      e => { },
      () => { this.get() }
    );
  }



}

import { InteractiveTable } from '../../common/interactiveTable'

export class GrupoDeServico extends InteractiveTable {
    public id: number
    public codigo: string
    public descricao: string
    public hierarquia: number
    public usuarioId: number
    public status: number
    public data: Date

    static valida (data: GrupoDeServico): GrupoDeServico {
      const model = new GrupoDeServico()
      model.id = data.id
      model.usuarioId = data.usuarioId
      model.descricao = data.descricao
      model.codigo = data.codigo
      model.status = data.status
      model.hierarquia = data.hierarquia
      model.data = data.data
      return model
    }
}

import { Component, OnInit } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { PlanilhaOrcamentariaFormComponent } from '../engenharia/planilha-orcamentaria/planilha-orcamentaria-form/planilha-orcamentaria-form.component';
//import { DatePipe } from 'node_modules/@angular/common'

declare var html2pdf: any;
declare var $: any
@Component({
  selector: 'app-planilha',
  templateUrl: './planilha.component.html',
  styleUrls: ['./planilha.component.css']
})
export class PlanilhaComponent extends PlanilhaOrcamentariaFormComponent {

  public downloadEXCEL() {

    const title = "Planilha Orçamentária - " + this.model.descricao;

    const header = ["Código", "Descrição", "Unid", "Qtd", "R$ Unitário", "R$ SUBTOTAL", "BDI%", "R$ TOTAL"]
    const rows = []
    this.listRelacaoPlanilha.forEach(c => {
      rows.push([c.codigo, c.descricao, c._unidadeMedida, c.quantidade, c._valor, c._valorSubTotal, c.bdi, c._valorTotal])
    })

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(this.model.descricao);
    //Add Row and formatting
    let titleRow = worksheet.addRow([title]);
    titleRow.font = { name: this.model.descricao, family: 4, size: 16, underline: 'double', bold: true }
    worksheet.addRow([]);

    //worksheet.addImage(logo, 'E1:F3');
    worksheet.mergeCells('A1:H2');
    //Blank Row 
    worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cccccc' },
        bgColor: { argb: 'cccccc' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    rows.forEach(d => {
      let row = worksheet.addRow(d);
      let qty = row.getCell(5);
      let color = 'f1f1f1';
      if (+qty.value < 500) {
        color = 'ffffff'
      }
      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    }
    );
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);
    //Footer Row
    let footerRow = worksheet.addRow(['Planilha Orçamentário']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffff' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:H${footerRow.number}`);
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.model.descricao + '.xlsx');
    })
  }

  public downloadPDF() {
    this.generateExcel();
  }


  generateExcel() {
    //Excel Title, Header, Data
    const header = [
      ["Empresa", "Painel da engenharia", "", "", "CPNJ:", "00.665.045/0001-85", "N° 4568", ""],
      ["Obra", "obra de teste", "", "", "inicio", "05/04/2020", "fim", "06/05/2021"],
      ["Endereço", "Endereçõ de teste", "", "", "", "", "Emissão:", "02/06/2020"],
    ]
    const tableHeader = ["Código", "Descrição", "Unid", "Qtd", "R$ Unitário", "R$ Subtotal", "% BDI", "R$ Total"]


    const data = []
    this.listRelacaoPlanilha.forEach(c => {
      data.push([c.codigo, c.descricao, c._unidadeMedida, c.quantidade, c._valor, c._valorSubTotal, c.bdi, c._valorTotal])
    })
    //Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Teste Planilha');

    //Add Row and formatting

    const title = ['', 'Planilha Orçamentária'];

    let titleRow = worksheet.addRow(title);
    titleRow.font = { family: 4, size: 16, underline: 'none', bold: true }
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' } 
    titleRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    titleRow.getCell(2).border = titleRow.getCell(1).border
    const img64 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAYAAABxLuKEAAAACXBIWXMAAAsSAAALEgHS3X78AAACYUlEQVR42u2c0XGDMAyGGSHvvdx5BEZgBEZgBEbwBh6BERjBIzACI3gECj31juaaxBDJkmx897+0aUk+jPRLtlNV17jGNa6Rybjfv26rmlV21bDKr5pXLf9ogt9vr+u3v8sNhoEPNj0BcFQj/L+bViAdIoxXkBpNQGZiII/artdKBdIyAHnUFpNqSQF1ZAbyKMsNZcswQRiUfVYzXLFkEa6QNDiDt1gUqbugcMBRDIUOjpKYEqMaO/ssmSigZCvwKSEjMD9GEAPMmBmUz00g2PwlY5mzYObMwfgzUCzym3Ang35P/Dg33AHXIiQByzpriDyLRbIONcFNq2MvPkluBRDAGWIvKr5HgjyrQ8wFHRGYAYJptBJnzZbjMfrEvrtEmXN4t9Qh0W+4BDXcrNLpJrqRJpWpIwUD75ne7MHyQ8kzxmoDkyLGPC9bhDaVbEJr4TnAHPUxNUP1zwIG2/n2yQpKLWAIyxa9YMBrhQvM3wzkWTp6UsDstqS1kHkm1lYn8R2xCryT41gq0QDGctRKGsA0HNW1BjC3V0GvVDAzVwdPOhjHYbU1gHnb8zUFggmxBmsqDIyLBdMVBsYcqU3mQsAMR4u2rhAw9ZmKFnPWjE86dZOa2ZLppkTcTYqEa9nc6jF2bea25WyU3mNlqYlQjw1msjM8kBz0ygAO3REdxcE4ydGcTtnjk+4gKcAJCgJt+sOjkK0moVA866F18DlO2KPTV1JGopXCmHrMVBIH00l9r+mrDBriBbwAe25MpXFADOoAUkDIMoPY73FAyGQdrHr6ncLuw//+bITXtWpnRm7jG5/krNRqjtglAAAAAElFTkSuQmCC"


    let logo = workbook.addImage({
      base64: img64,
      extension: 'png',
    });

    worksheet.addImage(logo, 'A1:A4');
    worksheet.mergeCells('A1:A4');
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(5).width = 14;
    worksheet.getColumn(6).width = 18;
    worksheet.getColumn(7).width = 10;
    worksheet.getColumn(8).width = 14;

    worksheet.mergeCells(`B${titleRow.number}:H4`);

    /************ start Cabeçalho ***************/
    let headerRow = []
    for (let i = 0; i < header.length; i++) {
      headerRow.push(worksheet.addRow(header[i]))
      for (let x = 0; x < header[i].length; x++) {
        if (x % 2 == 0) {
          let cell = headerRow[i].getCell(x + 1)
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'dddddd' }, bgColor: { argb: '000000' } }
          cell.font = { bold: true }
        }
        headerRow[i].getCell(x + 1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      }
    }
    headerRow[0].getCell(7).alignment = { vertical: 'middle', horizontal: 'center' }
    worksheet.mergeCells(`B${headerRow[0].number}:D${headerRow[0].number}`);
    worksheet.mergeCells(`G${headerRow[0].number}:H${headerRow[0].number}`);
    worksheet.mergeCells(`B${headerRow[1].number}:D${headerRow[1].number}`);
    worksheet.mergeCells(`B${headerRow[2].number}:F${headerRow[2].number}`);
    worksheet.addRow([]);
    /************** end Cabeçalho **************/

    //start th
    let tableHeaderRow = worksheet.addRow(tableHeader);
    tableHeaderRow.eachCell((cell, number) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'dddddd' }, bgColor: { argb: '000000' } }
      cell.font = { bold: true }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    //end th
    //start td
    data.forEach(d => {
      let row = worksheet.addRow(d);
      for (let i = 0; i < d.length; i++) {
        let cell = row.getCell(i + 1)
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffff' }, bgColor: { argb: '000000' } }
      }
      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'left' }
      row.getCell(3).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(4).alignment = { vertical: 'middle', horizontal: 'center' }
      row.getCell(5).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(6).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(7).alignment = { vertical: 'middle', horizontal: 'right' }
      row.getCell(8).alignment = { vertical: 'middle', horizontal: 'right' }
    });
    //end td

    //start footer
    let footerRow = worksheet.addRow(['Valor Total']);
    footerRow.font = { bold: true }
    footerRow.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CCCCCC' } }
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    footerRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'right' }
    footerRow.getCell(8).fill = footerRow.getCell(1).fill
    footerRow.getCell(8).value = '1.598466'
    footerRow.getCell(8).border = footerRow.getCell(1).border
    footerRow.getCell(8).alignment = { vertical: 'middle', horizontal: 'right' }
    worksheet.mergeCells(`A${footerRow.number}:G${footerRow.number}`);
    // end footer


    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'planilha.xlsx');
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { RelacaoPlanilhaOrcamentariaItem, EnumActionRow } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EnumItemTipo, EnumStatusValorEstado, RelacaoComposicao } from 'src/app/domain/engenharia/composicao/relacaoComposicao';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Estado } from 'src/app/domain/enum/estado';
import { ActivatedRoute, Router } from '@angular/router';
import { table } from 'src/app/domain/common/table';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ComposicaoService } from 'src/app/service/engenharia/composicao.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { PlanejamentoPlanilhaOrcamentaria, EnumStatusPlanejamento } from 'src/app/domain/engenharia/planejamento/planejamento-planilha-orcamentaria';
import { PlanejamentoItemPlanilhaOrcamentaria } from 'src/app/domain/engenharia/planejamento/planejamento-item-planilha-orcamentaria';
import { Cronograma } from 'src/app/domain/engenharia/planejamento/cronograma';
import { Feriado } from 'src/app/domain/engenharia/planejamento/feriado';
import { AlertService } from 'src/app/service/alert.service';
import { NgbDateFRParserFormatter } from 'src/app/core/injectable/NgbDateFRParserFormatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any;
@Component({
  selector: 'app-planejamento-planilha-orcamentaria-list',
  templateUrl: './planejamento-planilha-orcamentaria-list.component.html',
  styleUrls: ['./planejamento-planilha-orcamentaria-list.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class PlanejamentoPlanilhaOrcamentariaListComponent implements OnInit {
  _viewItemDetailed = []
  _code = 'app-lanejamento-planilha-orcamentaria'
  planilha = new PlanilhaOrcamentaria()
  model = new PlanejamentoPlanilhaOrcamentaria()
  listRelacaoPlanilha = new Array<RelacaoPlanilhaOrcamentariaItem>()
  relacaoPlanilha = new RelacaoPlanilhaOrcamentariaItem()
  unidadesDeMedida = new Array<UnidadeMedida>()
  listEstado = new Array<Estado>()
  obra = new Obra()
  id = this.route.snapshot.params['id'];
  widthDays = 40
  constructor(private alert: AlertService, private confirmService: AlertConfirmService, private router: Router, private route: ActivatedRoute, private context: ComposicaoService, private userLoggedIn: UserLoggedInService) { }

  async ngOnInit() {
    this.getUnidadesDeMedida()
    this.getEstados()
    this.getAllRelacoesPlanejamento(this.id)
  }

  /***** enuns ******/
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => { this.unidadesDeMedida = d })
  }

  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => {
      this.listEstado = d
    })
  }

  getObra(id) {
    this.context.path = 'obra'
    this.context.get(id).subscribe(d => {
      this.obra = d;
      this.obra._dataInicio = UseFullService.formatDate(new Date(this.obra.dataInicio))
      this.obra._dataFim = UseFullService.formatDate(new Date(this.obra.dataFim))
      this.getFeriados(this.obra.id)
    })
  }

  getFeriados(obraId: number) {
    this.context.path = 'feriado/obra'
    this.context.get(obraId).subscribe(d => {
      this.feriados = d;
      this.gerarCronograma()
    })
  }

  getPlanilha(id: any) {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.planilha = d;
      this.getObra(this.planilha.obraId)
    })
  }

  get(id: any, all = true) {
    this.context.path = 'planejamento-planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.model = d
      if (all) {
        this.getAllRelacoes(this.model.planilhaOrcamentariaId)
        this.getPlanilha(this.model.planilhaOrcamentariaId)
      }
    })
  }

  _planejamentoItens = new Array<PlanejamentoItemPlanilhaOrcamentaria>()
  getAllRelacoesPlanejamento(id: any) {
    this.context.path = 'planejamento-planilha-orcamentaria/item'
    this.context.getAll(id).subscribe(d => {
      this._planejamentoItens = d
      this.get(id)
    })
  }

  getAllRelacoes(id: number) {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.getAll("planilhaOrcamentariaId=" + id).subscribe(d => {
      this.listRelacaoPlanilha = d;
      this.atualizarPlanilha().then(v => { })
    })
  }

  async atualizarPlanilha() {
    var x = 1
    for (let i = 0; i < this.listRelacaoPlanilha.length; i++) {
      const c = this.listRelacaoPlanilha[i];
      c.planejamento = PlanejamentoItemPlanilhaOrcamentaria.getPorIdItem(this._planejamentoItens, c.id)
      c._index = x++
      if ((c.itemTipo == EnumItemTipo.grupo || c.itemTipo == EnumItemTipo.subgrupo) && c.descricao)
        c.descricao = c.descricao.toUpperCase()
      c._unidadeMedida = UnidadeMedida.getName(this.unidadesDeMedida, c.unidadeMedidaId)
      c._itemTipo = RelacaoComposicao.getTipoItemName(c.itemTipo)
      RelacaoPlanilhaOrcamentariaItem.setProperties(c)
      if (c.itemTipo === EnumItemTipo.subgrupo && c.codigo && c.codigo.split('.').length > 3)
        c._typeRow = EnumActionRow.subgrupo

      await this.setDate(c)
    }

    await PlanilhaOrcamentaria.ordenarList(this.listRelacaoPlanilha)
    await PlanilhaOrcamentaria.setValues(this.listRelacaoPlanilha, this.planilha)
  }

  selectRow(item: RelacaoPlanilhaOrcamentariaItem) {
    this.listRelacaoPlanilha.forEach(c => {
      c._selected = (item == c) ? true : false
      if (!c._selected)
        RelacaoPlanilhaOrcamentariaItem.setProperties(c)
    })
  }

  onChangeDuracao(item: RelacaoPlanilhaOrcamentariaItem) {
    if (!item.planejamento.duracaoDias)
      item.planejamento.duracaoDias = 1

    if (!item.planejamento.dataInicio || !item.planejamento.dataFim)
      return;
    PlanejamentoItemPlanilhaOrcamentaria.AtualizarDuracao(item, this.model, this.feriados)
    this.onChangeSave(item)
  }

  async onChangePredecessora(item: RelacaoPlanilhaOrcamentariaItem) {
    if (item.planejamento.predecessoraId == 0)
      this.changeDateInicio(item)
    else {

        try {
          const obterPorId = function (obj) { return (obj.id == item.planejamento.predecessoraId) }
          const predecessora = this.listRelacaoPlanilha.filter(obterPorId)[0]

          if ((predecessora.itemTipo === EnumItemTipo.grupo
            || predecessora.itemTipo === EnumItemTipo.subgrupo || predecessora.itemTipo === EnumItemTipo.subgrupoFilho)) {
            const listItens = await RelacaoPlanilhaOrcamentariaItem.obterItensGrupoOrSubGrupo(this.listRelacaoPlanilha, predecessora.codigo)

            if (listItens.length < 1)
              return

            PlanejamentoItemPlanilhaOrcamentaria.verificarPredecessoraEPai(item, predecessora)
            PlanejamentoItemPlanilhaOrcamentaria.setDateFim(listItens, predecessora)
          }

          PlanejamentoItemPlanilhaOrcamentaria.AtualizarPredecessora(item, predecessora)
          PlanejamentoItemPlanilhaOrcamentaria.AtualizarDuracao(item, this.model, this.feriados)

          //this.changeDateFim(item, false)
          //this.changeDateInicio(item)
          this.onChangeSave(item)
        } catch (error) {
          this.alert.SetMsgError(error);
        }
    }
  }

  async verificarPredecessora(item: RelacaoPlanilhaOrcamentariaItem) {
    const list = PlanejamentoItemPlanilhaOrcamentaria.getPredecessoras(this.listRelacaoPlanilha, item)
    for (let i = 0; i < list.length; i++)
      await this.onChangePredecessora(list[i])

  }

  feriados = new Array<Feriado>()
  onChangeSave(item: RelacaoPlanilhaOrcamentariaItem) {
    if (!item.planejamento.dataInicio || !item.planejamento.dataFim)
      return;
    PlanejamentoItemPlanilhaOrcamentaria.valida(item.planejamento, this.model, this.feriados)
    this.save(item)
  }

  save(item: RelacaoPlanilhaOrcamentariaItem) {
    this.context.path = 'planejamento-planilha-orcamentaria/item'
    this.context.put(item.planejamento).subscribe(d => {
      this.get(this.model.id, false)
      this.setDaysInterval(item)
      this.verificarPredecessora(item)
    })
  }

  listCronograma = new Array<Cronograma>()
  gerarCronograma() {
    const inicio = new Date(this.obra.dataInicio)
    const fim = new Date(this.obra.dataFim)
    const days = UseFullService.getDaysDates(inicio, fim)

    let width = 0
    let primeiroDiaSemana: String
    for (let i = 0; i < days; i++) {
      const newDate = new Date(this.obra.dataInicio)
      newDate.setDate(inicio.getDate() + i)

      const cronograma = new Cronograma()
      cronograma.data = newDate
      cronograma.dataBr = UseFullService.formatDate(newDate)
      cronograma.dia = newDate.getDate()
      cronograma.diaSemana = newDate.getDay()
      cronograma.diaMes = UseFullService.formatDateDiaMes(newDate)
      cronograma._diaSemana = Cronograma.getSemana(cronograma.diaSemana)
      cronograma.nomeSemaha = Cronograma.getSemana(cronograma.diaSemana, 1)
      cronograma.feriado = Feriado.getFeriadosPlanejamento(this.model.diasNaoUteis, this.feriados, cronograma.data)

      width += this.widthDays
      cronograma.width = width
      if (width == this.widthDays)
        primeiroDiaSemana = UseFullService.formatDate(cronograma.data)

      if (cronograma.diaSemana == 0) {
        cronograma.primeiroDiaSemana = primeiroDiaSemana
        width = 0
      }

      //aparecer qualquer dia se for o ultimo
      if (i == days - 1) {
        cronograma.ultimoDia = true
        cronograma.primeiroDiaSemana = primeiroDiaSemana
      }

      this.listCronograma.push(cronograma)
      this.onResize()
    }

    this.verificarFilaAtualizacao()
  }

  /********************* config data ****************************/
  toggleCalendar() { UseFullService.TraduzirCalendario(); }
  onDateSelection(item) { this.changeDateInicio(item) }
  onDateSelectionFim(item) { this.changeDateFim(item) }
  changeDateInicio(item: RelacaoPlanilhaOrcamentariaItem) {
    item.planejamento.dataInicio = $('input#data-inicio-' + item._index).val()
    const data_formatada = UseFullService.formatDateUS(item.planejamento.dataInicio)
    if (!data_formatada) return
    const date = new Date(data_formatada)
    if (date instanceof Date && !isNaN(date.valueOf()))
      item.planejamento._dataInicio = UseFullService.formatNgbDateBr(item.planejamento.dataInicio)
    else return
    this.verficarInicio(item)
    this.onChangeSave(item)
  }

  verficarInicio(item: RelacaoPlanilhaOrcamentariaItem) {
    const dateInicio = UseFullService.formatNgbDateStructDate(item.planejamento._dataInicio)
    if (dateInicio < new Date(this.obra.dataInicio))
      throw "A data de inicio deve ser maior ou igual o da obra!"
  }

  verficarFim(item: RelacaoPlanilhaOrcamentariaItem) {
    const dataFim = UseFullService.formatNgbDateStructDate(item.planejamento._dataFim)
    if (dataFim > new Date(this.obra.dataFim))
      throw "A data de final deve ser menor ou igual a da obra!"
  }

  changeDateFim(item: RelacaoPlanilhaOrcamentariaItem, save = true) {
    item.planejamento.dataFim = $('input#data-fim-' + item._index).val()
    const data_formatada = UseFullService.formatDateUS(item.planejamento.dataFim)
    if (!data_formatada) return
    const date = new Date(data_formatada)
    if (date instanceof Date && !isNaN(date.valueOf()))
      item.planejamento._dataFim = UseFullService.formatNgbDateBr(item.planejamento.dataFim)
    else return
    this.verficarFim(item)
    if (save) this.onChangeSave(item)
  }

  setDate(item: RelacaoPlanilhaOrcamentariaItem) {
    item.planejamento._dataInicio = UseFullService.formatNgbDate(item.planejamento.dataInicio)
    item.planejamento._dataFim = UseFullService.formatNgbDate(item.planejamento.dataFim)
    
      if (item.planejamento.dataInicio) 
        item.planejamento.dataInicio = UseFullService.formatNgbDateStructStringBr(item.planejamento._dataInicio)
      if (item.planejamento.dataFim) 
        item.planejamento.dataFim = UseFullService.formatNgbDateStructStringBr(item.planejamento._dataFim)
      
      this.setDaysInterval(item)
  }

  setDaysInterval(item: RelacaoPlanilhaOrcamentariaItem) {
    if (item.planejamento.dataFim && item.planejamento.dataInicio) {
      const inicio = UseFullService.formatDateUSTime(item.planejamento.dataInicio)
      const fim = UseFullService.formatDateUSTime(item.planejamento.dataFim)
      const days = UseFullService.getDaysDates(inicio, fim)
      item.planejamento._intervalWidth = this.widthDays * days
    }
  }

  verificarFilaAtualizacao() {
    if (this.model.status == EnumStatusPlanejamento.update || Feriado.haAtualiazacaoPendente(this.model, this.feriados)) {
      setTimeout(() => {
        for (let i = 0; i < this.listRelacaoPlanilha.length; i++) {
          if (this.listRelacaoPlanilha[i].itemTipo == EnumItemTipo.composicao ||
            this.listRelacaoPlanilha[i].itemTipo == EnumItemTipo.insumo ||
            this.listRelacaoPlanilha[i].itemTipo == EnumItemTipo.maodeobra) {
            setTimeout(async () => { this.onChangeDuracao(this.listRelacaoPlanilha[i]) }, 50)
          }
        }
        this.saveModel();
      }, 50);
    }
  }

  saveModel() {
    this.context.path = 'planejamento-planilha-orcamentaria'
    this.model.status = EnumStatusPlanejamento.aberto
    this.context.put(this.model).subscribe(d => { })
  }

  
  uriRelatorio = GlobalVariable.BASE_API_URL+'relatorio?url=planejamento/relatorio/'
  getPDF() {
    window.open(this.uriRelatorio + 'geral&landscape=true&id=' + this.model.id + '&token=' + btoa(localStorage.getItem('currentUser')), '_blank');
  }
  
  /********************* config table ***************************/
  onResize() {
    /*
    setTimeout(() => {
      $('.div-table-fixed').css('height', ($('body').height() - 270) + 'px')
      this.setScroll()
      UseFullService.StartTooltip()
    }, 50);
    */
  }

  setScroll() {
    /*
    const count = $(".item-descricao").length
    const topoIni = $(".div-table-fixed").offset().top
    const topFim = $(".div-table-fixed").height()
    let scrol = 0
    ///aqui ***
    $(".table-recurse").scroll(function (e) {
      //scrol = e.currentTarget.scrollTop
      $(".table-recurse thead").css('margin-left', '-' + e.currentTarget.scrollLeft + 'px')
    });

    $(document).scroll(function (e) {
      var scrol = $(document).scrollTop() + 30
      var tapete = scrol + 35
      $(".table-recurse thead").css('margin-top', '-' + scrol + 'px')
      $(".tapete-right, .tapete-left").css('margin-top', '-' + tapete + 'px')
    });
    
    /*
    function ajustaVertical() {
      for (let i = 0; i < count.length; i++) {    
        const elemento = $($(".item-descricao")[i])
        const altura = elemento.innerHeight() + 2
        const posicao = $(this).offset().top
        const momento = posicao + altura
        if (momento > topoIni && momento < topFim)
          elemento.removeClass("position-absolute-column")
        else
          elemento.addClass("position-absolute-column")
        console.log(momento, topoIni, topFim)
      }
    }
    */
  }
  //$('.div-table-fixed').offset().top
}


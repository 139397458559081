import { Component } from '@angular/core';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { PlanilhaOrcamentariaFormComponent } from '../../engenharia/planilha-orcamentaria/planilha-orcamentaria-form/planilha-orcamentaria-form.component';

@Component({
  selector: 'app-relatorio-orcamento-sintetico',
  templateUrl: './relatorio-orcamento-sintetico.component.html',
  styleUrls: ['./relatorio-curva-abc-insumo.component.css']
})
export class RelatorioOrcamentoSinteticoComponent extends PlanilhaOrcamentariaFormComponent {

  list = new Array<any>()
  _planilha = new PlanilhaOrcamentaria()
  _obra = new Obra()


  ngOnInit() {
    //this.user.SetCurrentUser()   
    this.getUnidadesDeMedida()
    this.getPlanilha()
  }

  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => {
      this.unidadesDeMedida = d;
      this.getAllItens()
    })
  }

  getAllItens() {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.getAll("planilhaOrcamentariaId=" + this.id).subscribe(d => {
      this.listRelacaoPlanilha = d;
      this.atualizarPlanilha();
    })
  }

  getPlanilha() {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(this.id).subscribe(d => {
      this._planilha = d;
      this.getObraPlanlinha(this._planilha.obraId)
    })
  }

  dataRel = ''
  getObraPlanlinha(id) {
    this.context.path = 'obra'
    this.context.get(id).subscribe(d => {
      this._obra = d;
      this._obra._dataInicio = UseFullService.formatDate(new Date(this._obra.dataInicio))
      this._obra._dataFim = UseFullService.formatDate(new Date(this._obra.dataFim))
      this.dataRel = UseFullService.formatDate(new Date())
    })
  }
}


export class Usuario {
    public id: number;
    public status: number;
    public tipoUsuario: number;
    public origemCadastro: number;
    public dataCriacao: Date;
    public dataStatus: Date;
    public dataUpdate: Date;
    public chave: string;
    public tipoConta: number;
    public diasGratis: number;
    public tipoPessoa: number;
    public razaoSocial: number;
    
    public _dataCriacao: string;
    public _dataStatus: string;
    public _dataUpdate: string;

    /// <summary>
    /// dados do cadastrante
    /// </summary>
    public nome: string;
    public email: string;
    public cpf: string;
    public telefone: string;
    public senha: string;
    public repitaSenha: string;

    /// <summary>
    /// dados da empresa
    /// </summary>
    public cnpj: string;

    public _load: boolean = false;
    public _tipoEmail: number;

    public _cpf: string;
}

export enum TipoAssinaturaUsuario {
    gratuita = 0,
    mensal = 1,
    trimestral = 2,
    semestral = 3,
    anual = 4,
    bienal = 5
}

export enum TipoPessoal {
    fisica = 0,
    juridica = 1
}

export interface tempUser{
    id: string
}

export enum TypeUser
{
    all = 0,
    administrator = 1,
    licitacao = 2,
    client = 3,
    unregisteredProvider = 4,
    auditor = 5,
    member = 6,
    reader = 7
}
import { Injectable } from '@angular/core';
import { RepositorioService } from '../repositorio-service';
import { Composicao } from 'src/app/domain/engenharia/composicao/composicao';
import { RelacaoComposicao, EnumItemTipo } from 'src/app/domain/engenharia/composicao/relacaoComposicao';


@Injectable({
  providedIn: 'root'
})
export class ComposicaoService extends RepositorioService {

  timeout (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

}

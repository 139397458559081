import { Component, OnInit } from '@angular/core';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { DiarioObra } from 'src/app/domain/diario-obra/DiarioObra';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from 'src/app/core/injectable/NgbDateFRParserFormatter';
import { ActivatedRoute } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { DiarioObraFormComponent } from './diario-obra-form.component';

declare var $: any;
@Component({
  selector: 'app-diario-obra-list',
  templateUrl: './diario-obra-list.component.html',
  styleUrls: ['./diario-obra-list.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }]
})
export class DiarioObraListComponent extends DiarioObraFormComponent {
  _code = "app-diario-obra-list"
  _listModel = new Array<DiarioObra>()
  _obra = new Obra()

  ngOnInit() {
    this.user.SetCurrentUser()
    if (!this.id && this.user._obra)
      this.id = this.user._obra.id
    this._model.obraId = this.id
    this.getObra()
    this.get()
    this._model._dataRelatorio = UseFullService.formatNgbDate(UseFullService.formatDateUsForm(new Date()))    
    this.confirmService.confirmEmitter$.subscribe(c => this.delete(c))
  }
  get(filter = '') {
    this.context.path = 'diario-obra'
    this.context.getAll('obraId=' + this.id + filter).subscribe(d => {
      if (d && d.length > 0) {
        this._listModel = d
        this.loading()
        //this.router.navigateByUrl('planilha-orcamentaria/form/' + d.id)
      } else
        this._listModel = new Array<DiarioObra>()
    })
  }

  public _dataFiltroInicio: any
  public _dataFiltroFim: any
  filtroChange() {
    const i = this._dataFiltroInicio
    const inicio = (i) ? i.year + "-" + i.month + "-" + i.day : '1900-01-01'
    const f = this._dataFiltroFim
    const fim = (f) ? f.year + "-" + f.month + "-" + f.day : UseFullService.formatDateUsForm(new Date())
    const filter = " and DATE(dataRelatorio) BETWEEN '" + inicio + "' AND '" + fim + "' "
    this.get(filter)
  }


  loading() {
    let x = 1
    this._listModel.forEach(c => {
      c._index = x++
      c._dataRelatorio = UseFullService.formatDate(new Date(c.dataRelatorio))
    })
  }

  viewRelatorio(id) { this.router.navigate(['/diario-obra/view/', id]) }
  editRelatorio(id) { this.router.navigate(['/diario-obra/form/', id]) }
  addRelatorio() { this.insert() }

  tempModel = new DiarioObra()
  confirmDelete(model: DiarioObra): void {
    this.tempModel = model;
    this.confirmService._code = this._code;
    this.confirmService._title = "Excluir Relatório de Obra (RDA)";
    this.confirmService._message = "Deseja excluir relatório: <b>" + model._dataRelatorio + "</b>?";
    this.confirmService.Show();
  }

  delete(confirm: boolean): void {
    if (this.confirmService._code != this._code)
      return;
    this.context.path = 'diario-obra'
    this.context.delete(this.tempModel.id).subscribe(
      d => { },
      e => { },
      () => { this._listModel.splice(this.tempModel._index - 1, 1) }
    );
  }

}

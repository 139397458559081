import { Component, OnInit } from '@angular/core';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { RelacaoPlanilhaOrcamentariaItem, EnumActionRow } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { EnumItemTipo, EnumStatusValorEstado, RelacaoComposicao } from 'src/app/domain/engenharia/composicao/relacaoComposicao';
import { MaoDeObra } from 'src/app/domain/engenharia/composicao/MaoDeObra';
import { Insumo } from 'src/app/domain/engenharia/composicao/Insumo';
import { Composicao } from 'src/app/domain/engenharia/composicao/composicao';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Estado } from 'src/app/domain/enum/estado';
import { ActivatedRoute, Router } from '@angular/router';
import { table } from 'src/app/domain/common/table';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ComposicaoService } from 'src/app/service/engenharia/composicao.service';
import { EnumState } from 'src/app/domain/common/interactiveTable';
import { Obra } from 'src/app/domain/engenharia/obra';
import { ExcelOrcamentoSintetico } from 'src/app/modules/relatorio/relatorioPlanilhaOrcamentaria/excel/ExcelOrcamentoSintetico';
import { ExcelOrcamentoAnalitico } from 'src/app/modules/relatorio/relatorioPlanilhaOrcamentaria/excel/ExcelOrcamentoAnalitico';
import { ExcelOrcamentoSinteticoItem } from 'src/app/modules/relatorio/relatorioPlanilhaOrcamentaria/excel/ExcelOrcamentoSinteticoItem';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any;
@Component({
  selector: 'app-planilha-orcamentaria-form',
  templateUrl: './planilha-orcamentaria-form.component.html',
  styleUrls: ['./planilha-orcamentaria-form.component.css']
})
export class PlanilhaOrcamentariaFormComponent implements OnInit {
  _viewItemDetailed = []
  _code = 'app-planilha-orcamentaria-form'
  model = new PlanilhaOrcamentaria()
  listRelacaoPlanilha = new Array<RelacaoPlanilhaOrcamentariaItem>()
  relacaoPlanilha = new RelacaoPlanilhaOrcamentariaItem()
  unidadesDeMedida = new Array<UnidadeMedida>()
  listEstado = new Array<Estado>()
  listObra = new Array<Obra>()
  id = this.route.snapshot.params['id'];

  constructor(public confirmService: AlertConfirmService, public router: Router, public route: ActivatedRoute, public context: ComposicaoService, public userLoggedIn: UserLoggedInService) {
    this.userLoggedIn.SetCurrentUser();
  }

  ngOnInit() {
    this.getUnidadesDeMedida()
    this.getEstados()
    this.confirmService.confirmEmitter$.subscribe(c => { this.delete(c) })
  }
  /***** enuns ******/
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => { this.unidadesDeMedida = d })
  }


  tempItemRelacaoItem = new RelacaoPlanilhaOrcamentariaItem();
  addMemoriaCalculo(item: RelacaoPlanilhaOrcamentariaItem) {
    this.tempItemRelacaoItem = item
    UseFullService.startButtonPopOver(true)
    $('[data-toggle="popover"].memoria-calculo-' + item.id).popover('show')
    const _this = this
    setTimeout(() => {
      const html = '<textarea class="form-control" row="3" >' + ((item.memoriaCalculo) ? item.memoriaCalculo : String()) + '</textarea>'
      $('.text-area-memoria-calculo').html(html)
      $('.popover a').click(function (e) {
        e.preventDefault();
        if ($(e.target).attr('href') > 0) {
          item.memoriaCalculo = $('.text-area-memoria-calculo textarea').val()
          _this.insertOrUpdateRelacaoPlanilha(item)
        }
        UseFullService.startButtonPopOver(false)
      })
    }, 50);
  }

  getEstados() {
    this.context.path = 'common'
    this.context.getAll(String(), String(table.estado)).subscribe(d => {
      this.listEstado = d
      if (this.id) {
        this.getPlanilha(this.id)
        this.getAllRelacoes(this.id)
      }
    })
  }


  getPlanilha(id: any) {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.model = d;
    })
  }

  async carregarPlanilha(focus = true) {
    await PlanilhaOrcamentaria.ordenarList(this.listRelacaoPlanilha)
    if (this.listRelacaoPlanilha && this.listRelacaoPlanilha.length > 1 && !this.listRelacaoPlanilha[this.listRelacaoPlanilha.length - 1].id) {
      this.listRelacaoPlanilha.splice((this.listRelacaoPlanilha.length - 1), 1)
    }
    let item: RelacaoPlanilhaOrcamentariaItem
    const countList = this.listRelacaoPlanilha.length
    const relacaoPlanilha = new RelacaoPlanilhaOrcamentariaItem()
    if (countList) {
      item = this.listRelacaoPlanilha[countList - 1]
      if (item.itemTipo === EnumItemTipo.grupo || item.itemTipo === EnumItemTipo.subgrupo) {
        relacaoPlanilha.setReferencia(item.codigo, item.codigo)
        relacaoPlanilha.itemTipo = EnumItemTipo.subgrupo
      } else {
        relacaoPlanilha.setReferencia(item.codigo, item.referenciaPai)
        relacaoPlanilha.itemTipo = item.itemTipo
      }
    } else {
      relacaoPlanilha.setReferencia()
      relacaoPlanilha.itemTipo = EnumItemTipo.grupo
    }

    RelacaoPlanilhaOrcamentariaItem.setTextInputItemTipo(relacaoPlanilha)
    relacaoPlanilha.planilhaOrcamentariaId = this.model.id
    this.listRelacaoPlanilha.push(relacaoPlanilha)
    const indexFocus = (focus) ? (countList + 1) : 0
    this.atualizarPlanilha(indexFocus)
    if (relacaoPlanilha.referenciaPai.split('.').length < 3)
      relacaoPlanilha._actions.subgrupo = false
  }

  atualizarPlanilha(index = 0) {
    var x = 1
    this.listRelacaoPlanilha.forEach(c => {
      c._index = x++
      if ((c.itemTipo == EnumItemTipo.grupo || c.itemTipo == EnumItemTipo.subgrupo) && c.descricao)
        c.descricao = c.descricao.toUpperCase()
      c._unidadeMedida = UnidadeMedida.getName(this.unidadesDeMedida, c.unidadeMedidaId)
      c._itemTipo = RelacaoComposicao.getTipoItemName(c.itemTipo)
      c._bdi = (c.itemTipo < 3) ? UseFullService.ToFixedString(c.bdi) : ''
      c._quantidade = (c.itemTipo < 3) ? UseFullService.ToFixedString(c.quantidade) : ''
      RelacaoPlanilhaOrcamentariaItem.setProperties(c)
      if (c.itemTipo === EnumItemTipo.subgrupo && c.codigo && c.codigo.split('.').length > 3)
        c._typeRow = EnumActionRow.subgrupo
    })

    setTimeout(async () => {
      await PlanilhaOrcamentaria.ordenarList(this.listRelacaoPlanilha)
      PlanilhaOrcamentaria.setValues(this.listRelacaoPlanilha, this.model)
      if (index)
        $('input#descricao-' + index).focus()
    }, 100);
  }

  mouseEnter(item: RelacaoPlanilhaOrcamentariaItem) {
    item._selected_hover = true
    UseFullService.StartTooltip()
  }

  mouseLeave(item: RelacaoPlanilhaOrcamentariaItem) {
    item._selected_hover = false
    UseFullService.StartTooltip()
  }

  rowClick(item: RelacaoPlanilhaOrcamentariaItem) {
    this.listRelacaoPlanilha.forEach(c => {
      c._selected = (item == c) ? true : false
      if (!c._selected)
        RelacaoPlanilhaOrcamentariaItem.setProperties(c)
    })
  }

  async SelectItemInsert(data: RelacaoPlanilhaOrcamentariaItem, itemTipo: EnumItemTipo, select = true) {

    if (this.listRelacaoPlanilha.length > 1) {


      const modelPai = this.listRelacaoPlanilha[data._index - 2]
      if (!modelPai)
        throw ("Referênica inválida!")
      //const grupo = (data.itemTipo === 4 || data.itemTipo === 5) && data._actions.nivel)

      if (select)
        data._itemTipoTemp = 0

      /*subgrupo filho de um subgrupo*/
      if ((itemTipo === EnumItemTipo.subgrupoFilho || data._itemTipoTemp === EnumItemTipo.subgrupoFilho) && data._actions.subgrupo) {
        data._itemTipoTemp = EnumItemTipo.subgrupoFilho
        itemTipo = EnumItemTipo.subgrupo
        data.referenciaPai = data.referencia
        if (modelPai.itemTipo === EnumItemTipo.grupo || modelPai.itemTipo === EnumItemTipo.subgrupo) {
          data.referenciaPai = modelPai.codigo
          data.referencia = modelPai.codigo
        } else {
          data.referenciaPai = modelPai.referenciaPai
          data.referencia = modelPai.referenciaPai
        }
      } else if (itemTipo === EnumItemTipo.subgrupoFilho && !data._actions.subgrupo) {
        return

        /** definições do pai para Subgrupo **/
      } else if (itemTipo === EnumItemTipo.subgrupo) {
        const tempModel = await RelacaoPlanilhaOrcamentariaItem.getCodigo(this.listRelacaoPlanilha, modelPai.referenciaPai)
        data.referenciaPai = tempModel.referenciaPai
        if (modelPai.itemTipo === EnumItemTipo.grupo || modelPai.itemTipo === EnumItemTipo.subgrupo)
          data.referencia = modelPai.codigo
        else
          data.referencia = tempModel.codigo
        itemTipo = EnumItemTipo.subgrupo

        /** definições do pai para itens **/
      } else if (itemTipo === EnumItemTipo.composicao || itemTipo === EnumItemTipo.insumo || itemTipo === EnumItemTipo.maodeobra) {
        if (modelPai.itemTipo === EnumItemTipo.grupo || modelPai.itemTipo === EnumItemTipo.subgrupo)
          data.referenciaPai = modelPai.codigo
        else
          data.referenciaPai = modelPai.referenciaPai
        data.referencia = modelPai.codigo
      }

      $('input#descricao-' + data._index).focus()
      data.itemTipo = itemTipo
      RelacaoPlanilhaOrcamentariaItem.setTextInputItemTipo(data)
      this.buscarItem(data)
    }
  }

  onChange(data: RelacaoPlanilhaOrcamentariaItem) {
    setTimeout(() => {
      if (!data.id && data.descricao.length >= 2) {
        if (!data.itemTipo)
          data.itemTipo = 1
        data._active_buscar = true
        this.buscarItem(data)
      } else if (data.id) {
        this.insertRelacaoPlanilha(data)
      }
    }, 1)
  }

  onChangeSave(data: RelacaoPlanilhaOrcamentariaItem, active = true) {
    const time = active ? 1 : 1000
    const type = data.itemTipo
    if (active || (data.itemTipo === EnumItemTipo.grupo || data.itemTipo === EnumItemTipo.subgrupo)) {
      setTimeout(() => {
        if (type == data.itemTipo)
          this.insertRelacaoPlanilha(data)
      }, time)
    }
  }

  buscarItem(data: RelacaoPlanilhaOrcamentariaItem) {
    if (data.itemTipo == EnumItemTipo.insumo)
      this.getAllInsumo(data.descricao)
    else if (data.itemTipo == EnumItemTipo.maodeobra)
      this.getAllMaoDeObra(data.descricao)
    else if (data.itemTipo == EnumItemTipo.composicao)
      this.getAllComposicao(data.descricao)
    data._selected_hover = false
  }

  /*** Insumo ***/
  listInsumo = new Array<Insumo>()
  getAllInsumo(busca: string) {
    this.context.path = 'insumo'
    var like = " and descricao LIKE '%" + busca + "%'"
    this.context.getAll('empresaId=' + this.userLoggedIn.id + like).subscribe(
      d => { this.listInsumo = d },
      e => { },
      () => {
        UnidadeMedida.setModel(this.listInsumo, this.unidadesDeMedida)
        this.getValorPorEstado(this.listInsumo)
      })
  }

  /*** mão de obra ***/
  listMaoDeObra = new Array<MaoDeObra>()
  getAllMaoDeObra(busca: string) {
    this.context.path = 'maodeobra'
    var like = " and descricao LIKE '%" + busca + "%'"
    this.context.getAll('empresaId=' + this.userLoggedIn.id + like).subscribe(
      d => { this.listMaoDeObra = d },
      e => { },
      () => {
        UnidadeMedida.setModel(this.listMaoDeObra, this.unidadesDeMedida)
        this.getValorPorEstado(this.listMaoDeObra, 'maodeobra/estado/valor', 'maoDeObraId')
      })
  }

  /*** Composicao ***/
  listComposicao = new Array<Composicao>()
  getAllComposicao(busca: string) {
    this.context.path = 'composicao'
    const like = { descricao: busca, codigo: busca }
    this.context.getAll(JSON.stringify(like)).subscribe(
      d => { this.listComposicao = d },
      e => { },
      () => {
        UnidadeMedida.setModel(this.listComposicao, this.unidadesDeMedida)
        this.getValorComposicaoPorEstado(this.listComposicao)
      })
  }

  getValorComposicaoPorEstado(list: any[]) {
    this.context.path = 'composicao-valor'
    list.forEach(async c => {
      if (c.id > 0) {
        this.context.get(c.id, this.model.estado).subscribe(
          d => { c._preco = (d) ? UseFullService.ToFixed(d.valor) : '0,00'; }
        );
      }
    })
  }

  getValorPorEstado(list: any[], path = 'insumo/estado/valor', column = 'insumoId') {
    this.context.path = path
    list.forEach(async c => {
      if (c.id > 0) {
        this.context.getAll(column + "=" + c.id + " and estado='" + this.model.estado + "'").subscribe(
          d => { c._preco = (d && d.length > 0) ? UseFullService.ToFixed(d[0].valor) : '0,00'; }
        );
      }
    })
  }

  insertItem(item: RelacaoPlanilhaOrcamentariaItem, data: any, tipo: EnumItemTipo) {
    item.codigoItem = data.codigo
    item.descricao = data.descricao
    item.unidadeMedidaId = data.unidadeMedidaId
    item._unidadeMedida = data._unidadeMedida
    item.itemId = data.id
    item.valor = UseFullService.ToDecimalString(data._preco)
    item.planilhaOrcamentariaId = this.model.id
    $('input#quantidade-' + item._index).focus()
    item._active_buscar = false
    item._selected_hover = false
    this.insertRelacaoPlanilha(item)
  }

  async insertRelacaoPlanilha(data) {
    let tempData = RelacaoPlanilhaOrcamentariaItem.valida(data)
    if (!data.id)
      await RelacaoPlanilhaOrcamentariaItem.gerarCodigo(this.listRelacaoPlanilha, tempData)

    this.insertOrUpdateRelacaoPlanilha(tempData)

    setTimeout(async () => {
      await PlanilhaOrcamentaria.somarSubgroupOrGroup(this.listRelacaoPlanilha)
      PlanilhaOrcamentaria.setValues(this.listRelacaoPlanilha, this.model)
    }, 1000)

  }

  getAllRelacoes(id: number) {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.getAll("planilhaOrcamentariaId=" + id).subscribe(d => {
      this.listRelacaoPlanilha = d;
      this.carregarPlanilha();
    })
  }

  getAllRelacoesComposicao(item: RelacaoPlanilhaOrcamentariaItem) {
    if (!item._detailed) {
      this.context.path = 'planilha-orcamentaria/relacao/all/composicao'
      this.context.get(item.id).subscribe(d => {
        item._listComposicao = [d]
        item._detailed = true
      })
    } else
      item._detailed = false
  }

  verItem(item: RelacaoPlanilhaOrcamentariaItem) {
    if (item.itemTipo === EnumItemTipo.composicao)
      this.getAllRelacoesComposicao(item)
    else if (item.itemTipo === EnumItemTipo.maodeobra)
      this.router.navigateByUrl('/mao-de-obra/form')
    else if (item.itemTipo === EnumItemTipo.insumo)
      this.router.navigateByUrl('/insumo/form')

  }

  async insertOrUpdateRelacaoPlanilha(item: RelacaoPlanilhaOrcamentariaItem) {
    this.context.path = 'planilha-orcamentaria/relacao'
    await setTimeout(async () => {
      if (item.id)
        this.context.put(item).subscribe(d => {
          item._state = EnumState.none
          RelacaoPlanilhaOrcamentariaItem.setProperties(item)
        })
      else
        await this.context.post(item).subscribe(async d => {
          if (d)
            if (d) item.id = d.id;
          await this.verificarPendencias()
        })
    }, 1)
  }

  async verificarPendencias() {
    for (let i = 0; i < this.listRelacaoPlanilha.length; i++) {
      const c = this.listRelacaoPlanilha[i];
      c._toSon = false
      if (c._state === EnumState.update) {
        await this.insertOrUpdateRelacaoPlanilha(this.listRelacaoPlanilha[i])
      }
    }    
    this.carregarPlanilha(false)
  }

  confirmDelete(model: RelacaoPlanilhaOrcamentariaItem): void {
    this.relacaoPlanilha = model
    this.confirmService._code = this._code
    this.confirmService._title = "Excluir Item"
    this.confirmService._message = "Deseja excluir o item <b>" + model.descricao + "</b>?"
    this.confirmService.Show()
  }

  async delete(confirm: boolean): Promise<void> {
    if (this.confirmService._code != this._code)
      return

    if (this.relacaoPlanilha.itemTipo === EnumItemTipo.composicao ||
      this.relacaoPlanilha.itemTipo === EnumItemTipo.maodeobra ||
      this.relacaoPlanilha.itemTipo === EnumItemTipo.insumo) {
      await RelacaoPlanilhaOrcamentariaItem.deleteItem(this.listRelacaoPlanilha, this.relacaoPlanilha)
      await this.delatarItem()
    } else {
      await RelacaoPlanilhaOrcamentariaItem.deleteGrupoOrSubgrupo(this.listRelacaoPlanilha, this.relacaoPlanilha)
      this.delatarAllItem()
    }
  }

  delatarItem() {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.delete(this.relacaoPlanilha.id).subscribe(
      d => {
        this.listRelacaoPlanilha.splice(this.relacaoPlanilha._index - 1, 1)
        this.verificarPendencias()
      },
      e => { },
      () => { }
    )
  }

  async delatarAllItem() {
    this.context.path = 'planilha-orcamentaria/relacao/all'
    const obterDelete = function (o) { return (o._state === EnumState.delete) }
    const listDelete = await this.listRelacaoPlanilha.filter(obterDelete)
    let ids = []
    listDelete.forEach(c => ids.push(c.id))
    this.context.deleteAll(ids as []).subscribe(
      d => {
        this.listRelacaoPlanilha.splice(listDelete[0]._index - 1, listDelete.length)
        setTimeout(() => { this.verificarPendencias() }, 10);
      },
      e => { },
      () => { }
    )
  }

  focusInputDescricao(i) { setTimeout(() => { $('input#descricao-' + i).focus() }, 1); }

  removeRow(item: RelacaoPlanilhaOrcamentariaItem) {
    item._toSon = false
    this.listRelacaoPlanilha.splice(item._index, 1)
    this.atualizarPlanilha()
  }

  addRow(item: RelacaoPlanilhaOrcamentariaItem) {
    item._toSon = true

    const tempList = new Array<RelacaoPlanilhaOrcamentariaItem>()
    const data = new RelacaoPlanilhaOrcamentariaItem()
    data.referencia = item.codigo
    if (item.itemTipo === EnumItemTipo.grupo || item.itemTipo === EnumItemTipo.subgrupo) {
      data.referenciaPai = item.codigo
      data.itemTipo = EnumItemTipo.subgrupo
    } else {
      data.referenciaPai = item.referenciaPai
      data.itemTipo = item.itemTipo
    }
    RelacaoPlanilhaOrcamentariaItem.setTextInputItemTipo(data)

    data.planilhaOrcamentariaId = this.model.id

    RelacaoPlanilhaOrcamentariaItem.actions(this.listRelacaoPlanilha, data, item)

    for (let i = 0; i < item._index; i++)
      tempList.push(this.listRelacaoPlanilha[i])

    tempList.push(data)

    const countList = this.listRelacaoPlanilha.length
    for (let i = item._index; i < countList; i++) {
      tempList.push(this.listRelacaoPlanilha[i])

      if (i == countList - 1) {
        this.listRelacaoPlanilha = tempList
        this.atualizarPlanilha(item._index + 1)
      }
    }
  }

  uriRelatorio = GlobalVariable.BASE_API_URL + 'relatorio?url=planilhaorcamentaria/relatorio/'
  getPDFCurvaABCInsumo() {
    window.open(this.uriRelatorio + 'curva/abc-servico&id=' + this.model.id + '&token=' + btoa(localStorage.getItem('currentUser')), '_blank');
  }

  getPDFCurvaABCServico() {
    window.open(this.uriRelatorio + 'curva/abc-insumo&id=' + this.model.id + '&token=' + btoa(localStorage.getItem('currentUser')), '_blank');
  }

  getPDFSintetico() {
    window.open(this.uriRelatorio + 'sintetico&id=' + this.model.id + '&token=' + btoa(localStorage.getItem('currentUser')), '_blank');
  }

  getPDFAnalitico() {
    window.open(this.uriRelatorio + 'analitico&id=' + this.model.id + '&token=' + btoa(localStorage.getItem('currentUser')), '_blank');
  }

  getPDFMemoriaCalculo() {
    window.open(this.uriRelatorio + 'memoria-calculo&id=' + this.model.id + '&token=' + btoa(localStorage.getItem('currentUser')), '_blank');
  }

  getExcelSintetico() {
    const cabecalho = { planilha: this.model, obra: this.listObra.filter((o) => { return (o.id === this.model.obraId) })[0] }
    new ExcelOrcamentoSintetico().generateExcel(cabecalho, this.listRelacaoPlanilha)
  }

  getExcelAnalitico() {
    const cabecalho = { planilha: this.model, obra: this.listObra.filter((o) => { return (o.id === this.model.obraId) })[0] }
    const list = this.listRelacaoPlanilha.filter(c => { return (c.itemTipo === EnumItemTipo.composicao) })
    for (let i = list.length - 1; i >= 0; i--) {
      this.getDetalhesComposicao(list[i], i, cabecalho)
    }
  }

  getDetalhesComposicao(c: RelacaoPlanilhaOrcamentariaItem, totalRow, cabecalho, relatorio = 0) {
    this.context.path = 'planilha-orcamentaria/relacao/all/composicao'
    this.context.get(c.id).subscribe(d => {
      c._detalheComposicao = d
      if (totalRow === 0) {
        if (relatorio === 1)
          new ExcelOrcamentoSinteticoItem().generateExcel(cabecalho, this.listRelacaoPlanilha)
        else
          new ExcelOrcamentoAnalitico().generateExcel(cabecalho, this.listRelacaoPlanilha)
      }
    })
  }

  getExcelSinteticoItem() {
    const cabecalho = { planilha: this.model, obra: this.listObra.filter((o) => { return (o.id === this.model.obraId) })[0] }
    const list = this.listRelacaoPlanilha.filter(c => { return (c.itemTipo === EnumItemTipo.composicao) })
    for (let i = list.length - 1; i >= 0; i--) {
      this.getDetalhesComposicao(list[i], i, cabecalho, 1)
    }
  }
}
import { UseFullService } from 'src/app/service/usefull/usefull.service'
import { InteractiveTable } from '../common/interactiveTable'
import { GlobalVariable } from 'src/app/core/global-variable'

export class HistoricoFinanceiro extends InteractiveTable {
    public id: number
    public usuarioId: number
    public empresaId: number
    public dataCriacao: Date
    public dataVencimento: any
    public valor: string
    public status: number
    public dataUpdate: Date
    public anexo: string
    public assinaturaId: number

    public _boleto: any
    public _statusPagamento: string
    public _data: string
    public _vencimento: string
    public _valor: string
    public _file: File
    public _href: string
    public _haBoleto: boolean
    public _updateFile: number

    
    public _inicio: Date
    public _fim: Date
    public _status: boolean
    public _meses: number
    public _diasBloqueio: number
    public _assinatura: string

    static format(model: HistoricoFinanceiro) {
        model._data = UseFullService.formatDate(new Date(model.dataUpdate))
        model._vencimento = UseFullService.formatDate(new Date(model.dataVencimento))
        model._statusPagamento = StatusItemFinanceiro[model.status]
        model._valor = UseFullService.ToFixedString(model.valor)

        if(model.anexo){
            model._boleto = JSON.parse(model.anexo)
            model._href = GlobalVariable.BASE_API_URL+"common/file?id="+model._boleto.id+"&name=boleto.pdf"
            console.log(model._boleto) 
            model._haBoleto = true
        }
    }

    static valida(data: HistoricoFinanceiro): HistoricoFinanceiro {
        const model = new HistoricoFinanceiro()
        model.empresaId = data.empresaId
        model.usuarioId = data.usuarioId
        model.dataCriacao = data.dataCriacao
        model.dataVencimento = data.dataVencimento
        model.status = data.status
        model.valor = data.valor
        model.id = data.id
        return model
    }
}

export enum StatusItemFinanceiro {
    Aberto = 0,
    Pago = 1,
    Vencido = 2,
    Cancelado = 3
}

import { Component, OnInit } from '@angular/core';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { RelacaoPlanilhaOrcamentariaItem, EnumActionRow } from 'src/app/domain/engenharia/orcamento/relacao-planilha-orcamentaria-item';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { Estado } from 'src/app/domain/enum/estado';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { ComposicaoService } from 'src/app/service/engenharia/composicao.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { table } from 'src/app/domain/common/table';
import { PlanejamentoPlanilhaOrcamentaria, EnumStatusPlanejamento } from 'src/app/domain/engenharia/planejamento/planejamento-planilha-orcamentaria';
import { PlanejamentoPlanilhaOrcamentariaComponent } from './planejamento-planilha-orcamentaria.component';

declare var $: any;
@Component({
  selector: 'app-planejamento-planilha-orcamentaria-form',
  templateUrl: './planejamento-planilha-orcamentaria-form.component.html',
  styleUrls: ['./planejamento-planilha-orcamentaria.component.css']
})

export class PlanejamentoPlanilhaOrcamentariaFormComponent extends PlanejamentoPlanilhaOrcamentariaComponent {  
  _code = 'app-planejamento-planilha-orcamentaria-form'
}
import { InteractiveTable } from '../../common/interactiveTable'
import { Data } from '@angular/router';
import { ValorInsumoEstado } from './valorInsumoEstado';

export class Insumo extends InteractiveTable {

    constructor() {
        super();
    }

    public id: number
    public codigo: string
    public empresaId: number
    public descricao: string
    public unidadeMedidaId: number
    public data: Data

    _unidadeMedida: string
    _preco: string

    _listValorInsumoEstado: Array<ValorInsumoEstado>

    static validar(model: Insumo) {
        if (!model.descricao || model.descricao.length < 4)
            throw ('Descrição deve ter no minímo 4 digitos!')
        if (!model.unidadeMedidaId || model.unidadeMedidaId < 0)
            throw ('Informa unidade de medida!')
    }
}
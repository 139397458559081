import { InteractiveTable } from '../../common/interactiveTable'
import { RelacaoPlanilhaOrcamentariaItem } from './relacao-planilha-orcamentaria-item'
import { EnumItemTipo } from '../composicao/relacaoComposicao'
import { UseFullService } from 'src/app/service/usefull/usefull.service'

export class PlanilhaOrcamentaria extends InteractiveTable {
    public id: number
    public empresaId: number
    public obraId: number
    public status: number
    public descricao: string
    public valor: string
    public valorDBI: string
    public usuarioId: string
    public estado: string
    public dataCadastro: Date
    public _enderecoId: number
    private _valorSubtotal: string
    private _valorBDI: string

    static async setValues(list: RelacaoPlanilhaOrcamentariaItem[], model: PlanilhaOrcamentaria) {
        const getAllItens = (obj) => { return (obj.id && (obj.itemTipo === EnumItemTipo.composicao || obj.itemTipo === EnumItemTipo.maodeobra || obj.itemTipo === EnumItemTipo.insumo)) }

        /**** Obter valores dos grupos ****/
        const listItens = await list.filter(getAllItens)

        let totalSemBDI = 0
        let valorBDI = 0
        let total = 0
        for (let i = 0; i < listItens.length; i++) {
            const c = listItens[i]
            const _subtotal = UseFullService.ConvertFloat(c._valorSubTotal)
            const _total = UseFullService.ConvertFloat(c._valorTotal)
            total += _total
            totalSemBDI += _subtotal
            valorBDI += (parseFloat(c.bdi) > 0) ? (_total - _subtotal) : 0
        }
        model._valorSubtotal = UseFullService.ToFixedString(totalSemBDI)
        model._valorBDI = UseFullService.ToFixedString(valorBDI)
        model.valor = UseFullService.ToFixedString(total)
    }

    //ordenar list por codigo referencial
    static ordenarList(list: RelacaoPlanilhaOrcamentariaItem[]) {
        list.sort(function (a, b) {
            const acodigo = a.codigo || ''
            const bcodigo = b.codigo || ''
            const _a = acodigo.split('.')
            const _b = bcodigo.split('.')

            let aA = []
            let bA = []
            let resultAB = 1
            let resultA = []
            let resultB = []
            const a0 = parseInt(_a[0])
            const b0 = parseInt(_b[0])

            for (let i = 1; i < _a.length; i++) {
                aA[i] = parseInt(_a[i])
                bA[i] = parseInt(_b[i])
                if (resultAB === i && aA[i] === bA[i])
                    resultAB++;
            }

            for (let x = 0; x < resultAB; x++) {
                resultA.push(aA[x + 1] > bA[x + 1])
                resultB.push(aA[x + 1] < bA[x + 1])
            }

            if (a0 > b0 || resultA.includes(true)) {
                return 1;
            }
            if (a0 < b0 || resultB.includes(true)) {
                return -1;
            }
            return 0;
        });

        this.somarSubgroupOrGroup(list)
    }

    //Atualizar Valores dos grupos
    static async somarSubgroupOrGroup(list: RelacaoPlanilhaOrcamentariaItem[]) {

        const getAllGroup = (obj) => { return (obj.itemTipo === EnumItemTipo.grupo && obj.id) }
        const getAllSubgroup = (obj) => { return (obj.itemTipo === EnumItemTipo.subgrupo && obj.id) }

        /**** Obter valores dos grupos ****/
        const listGroup = await list.filter(getAllGroup)
        for (let i = 0; i < listGroup.length; i++)
            this.somarModel(list, listGroup[i])

        /**** Obter valores dos subgrupos ****/
        const listSubgroup = await list.filter(getAllSubgroup)
        for (let i = 0; i < listSubgroup.length; i++)
            this.somarModel(list, listSubgroup[i])

    }

    static somarList(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {
        let total = String()
        for (let i = 0; i < list.length; i++) {
            const c = list[i];
            let tempTotal = (total !== String()) ? parseFloat(total) : 0
            total = String(tempTotal + parseFloat(UseFullService.ToDecimalString(c._valorTotal)))
        }
        model._valorTotal = UseFullService.ToFixed(total)
    }

    static async somarModel(list: RelacaoPlanilhaOrcamentariaItem[], model: RelacaoPlanilhaOrcamentariaItem) {
        let codigo = model.codigo.split('.')
        let nivel = codigo.length - 2;
        const getAllItens = (obj) => {

            if (!obj.id || !obj.codigo || !(obj.itemTipo === EnumItemTipo.composicao || obj.itemTipo === EnumItemTipo.insumo || obj.itemTipo === EnumItemTipo.maodeobra))
                return false

            const obj_digitos = obj.codigo.split('.')
            if (obj_digitos[nivel] !== codigo[nivel])
                return false


            let result = true
            for (let x = 0; x < (nivel + 1); x++) {
                const oA = parseInt(obj_digitos[x])
                const oB = parseInt(codigo[x])
                result = (result) ? (oA == oB) : false
            }
            return (result)
        }
        const listItens = await list.filter(getAllItens)
        this.somarList(listItens, model)
    }

}
import { Component, OnInit } from '@angular/core';
import { ObraComponent } from '../engenharia/obra/obra.component';

declare var $: any;
@Component({
  selector: 'app-diario-obra',
  templateUrl: './diario-obra.component.html',
  styleUrls: ['./diario-obra.component.css']
})
export class DiarioObraComponent extends ObraComponent {
  gerenciar(model: any) { this.router.navigate(['/diario-obra/list/', model.id]) }
}
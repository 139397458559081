import { InteractiveTable } from '../../common/interactiveTable'
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Composicao } from './composicao';

export class RelacaoComposicao extends InteractiveTable {

    static getItemList(list: ValorItemComposicaoEstado[], id: number): ValorItemComposicaoEstado {
        function getIdentity(data: ValorItemComposicaoEstado) { return (data.relacaoComposicaoId === id) }
        const data = list.filter(getIdentity)[0]
        return (data) ? data : new ValorItemComposicaoEstado();
    }

    static recalcularRelacaoComposicao(list: RelacaoComposicao[], valoresEstado: ValorItemComposicaoEstado[]) {
        list.forEach(c => {
            c._paramsEstado = RelacaoComposicao.getItemList(valoresEstado, c.id)
            c._paramsEstado._valor = (c._paramsEstado.valor)? UseFullService.ToFixed(c._paramsEstado.valor):'0,00'
            RelacaoComposicao.recalcularSubTotal(c)
        })
    }

    static recalcularSubTotal(model: RelacaoComposicao){        
        const valorTotal = parseFloat(model._paramsEstado.valor) * parseFloat(model.quantidade)
        model._paramsEstado.valorTotal =  (valorTotal)? UseFullService.ToFixed(valorTotal):'0,00'
    }

    public id: number
    public codigo: string
    public composicaoId: number
    public itemTipo: number
    public itemId: number
    public descricao: string
    public quantidade: string
    public status: number
    public unidadeMedidaId: number
    public valorTotal: string
    public data: Date
    
    _valor: string
    _paramsEstado: ValorItemComposicaoEstado
    _unidadeMedida: string
    _itemTipo: string
    _composicao: Composicao

    setTextInputItemTipo() {
        switch (this.itemTipo) {
            case EnumItemTipo.composicao:
                this._textInputItemTipo = 'Pesquisar composição'
                break;
            case EnumItemTipo.maodeobra:
                this._textInputItemTipo = 'Pesquisar mão de obra'
                break;
            default:
                this._textInputItemTipo = 'Pesquisar insumo'
                break;
        }
    }

    static getTipoItemName(itemTipo: number) {
        switch (itemTipo) {
            case EnumItemTipo.composicao: return 'Composição'; break;
            case EnumItemTipo.maodeobra: return 'Mão de Obra'; break;
            case EnumItemTipo.insumo: return 'Insumo'; break;
            case EnumItemTipo.grupo: return 'Grupo'; break;
            case EnumItemTipo.subgrupo: return 'Subgrupo'; break;
        }
    }

    static valida(model: RelacaoComposicao): RelacaoComposicao {
        return model
    }
}

export class ValorItemComposicaoEstado {
    public id: number
    public relacaoComposicaoId: number
    public itemId: number
    public itemTipo: number
    public estado: string
    public status: number
    public valor: string
    public _valor: string
    valorTotal: string;
}

export enum EnumStatusValorEstado {
    Aberto = 0,
    Bloqueado = 1
}

export enum EnumItemTipo {
    composicao = 3,
    insumo = 1,
    maodeobra = 2,
    grupo = 4,
    subgrupo = 5,
    subgrupoFilho = 6
}
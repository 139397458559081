import { InteractiveTable } from '../common/interactiveTable'

export class MedicaoPlanilhaOrcamentaria extends InteractiveTable {
  public id: number
  public planilhaOrcamentariaId: number
  public medicao: string
  public status: number
  public observacao: string
  public usuarioId: string
  public dataMedicao: string
  public data: string
  public _dataMedicao: any
  public _vlrTotal: string
  public _vlrTotalMedido: string

  static valida(data: MedicaoPlanilhaOrcamentaria) {
    if (!data.planilhaOrcamentariaId) throw String('Planilha Orcamentária é inválida!')
    if (!data.dataMedicao) throw String('dataMedição é inválida!')
  }
}

export class MedicaoItemPlanilhaOrcamentaria extends InteractiveTable {
  public id: number
  public medicaoId: number
  public relacaoPlanilhaOrcamentariaItemId: number
  public medicao: string
  public observacao: string
  public data: Date
  
  public _medido: string
  public _haMedir: string
  public _vlrAMedir: string
  public _vlrMedido: string
  public _statusVlr: number

  static valida(data: MedicaoItemPlanilhaOrcamentaria) {
    if (!data.relacaoPlanilhaOrcamentariaItemId)
      throw String('Medição é inválida!')
  }

  static getPorItemPlanilha(_medicaoItens: MedicaoItemPlanilhaOrcamentaria[], id: number): MedicaoItemPlanilhaOrcamentaria {
    const porItemId = (o) => { return (o.relacaoPlanilhaOrcamentariaItemId === id) }
    return _medicaoItens.filter(porItemId)[0]
  }
}

export enum EnumStatusVlrMedicao{
  vlrMenorIgual = 0,
  vlrMaior = 1
}
export class LicitacaoFavorita {
  public id: number
  public empresaId: number
  public usuarioId: number
  public licitacaoId: number

  static valida(data: LicitacaoFavorita): LicitacaoFavorita {
    const model = new LicitacaoFavorita()
    model.empresaId = data.empresaId
    model.usuarioId = data.usuarioId
    model.licitacaoId = data.licitacaoId
    model.id = data.id
    return model
  }
}

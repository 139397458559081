import { Component } from '@angular/core';
import { RepositorioService } from 'src/app/service/repositorio-service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { UnidadeMedida } from 'src/app/domain/common/unidadeDeMedida';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Obra } from 'src/app/domain/engenharia/obra';
import { PlanilhaOrcamentaria } from 'src/app/domain/engenharia/orcamento/planilha-orcamentaria';
import { PlanejamentoItem } from 'src/app/domain/engenharia/planejamento/planejamento-extend-item';
import { PlanejamentoPlanilhaOrcamentaria } from 'src/app/domain/engenharia/planejamento/planejamento-planilha-orcamentaria';
import { PlanejamentoItemPlanilhaOrcamentaria } from 'src/app/domain/engenharia/planejamento/planejamento-item-planilha-orcamentaria';
import { Cronograma } from 'src/app/domain/engenharia/planejamento/cronograma';
import { Feriado } from 'src/app/domain/engenharia/planejamento/feriado';

@Component({
  selector: 'app-relatorio-cronograma',
  templateUrl: './relatorio-cronograma.component.html',
  styleUrls: ['./relatorio-cronograma.component.css']
})
export class RelatorioCronogramaComponent {

  list = new Array<any>()
  _planilha = new PlanilhaOrcamentaria()
  _obra = new Obra()
  model = new PlanejamentoPlanilhaOrcamentaria()

  constructor(
    public context: RepositorioService,
    public route: ActivatedRoute,
    public router: Router,
    public user: UserLoggedInService) {
  }

  id = this.route.snapshot.params['id']

  ngOnInit() {
    //this.user.SetCurrentUser()
    if (this.id) {
      this.getUnidadesDeMedida()
      this.getAllRelacoesPlanejamento(this.id)
    }
  }


  get(id: any, all = true) {
    this.context.path = 'planejamento-planilha-orcamentaria'
    this.context.get(id).subscribe(d => {
      this.model = d
      this.getAllItens(this.model.planilhaOrcamentariaId)
    })
  }

  unidadeDeMedida = new Array<UnidadeMedida>()
  getUnidadesDeMedida() {
    this.context.path = 'unidade/medida'
    this.context.getAll().subscribe(d => {
      this.unidadeDeMedida = d;
    })
  }

  getAllItens(id: number) {
    this.context.path = 'planilha-orcamentaria/relacao'
    this.context.getAll("planilhaOrcamentariaId=" + id).subscribe(d => {
      this.list = d;
      this.getPlanilha(id)
    })
  }

  getPlanilha(id: number) {
    this.context.path = 'planilha-orcamentaria'
    this.context.get(this.id).subscribe(d => {
      this._planilha = d;
      this.getObra(this._planilha.obraId)
    })
  }

  dataRel = ''
  getObra(id) {
    this.context.path = 'obra'
    this.context.get(id).subscribe(d => {
      this._obra = d;
      this._obra._dataInicio = UseFullService.formatDate(new Date(this._obra.dataInicio))
      this._obra._dataFim = UseFullService.formatDate(new Date(this._obra.dataFim))
      this.dataRel = UseFullService.formatDate(new Date())
      this.getFeriados(id)
    })
  }

  feriados = new Array<Feriado>()
  getFeriados(obraId: number) {
    this.context.path = 'feriado/obra'
    this.context.get(obraId).subscribe(d => {
      this.feriados = d;
      this.gerarCronograma()
    })
  }



  /***** montar cronograma *****/

  _planejamentoItens = new Array<PlanejamentoItemPlanilhaOrcamentaria>()
  getAllRelacoesPlanejamento(id: any) {
    this.context.path = 'planejamento-planilha-orcamentaria/item'
    this.context.getAll(id).subscribe(d => {
      this._planejamentoItens = d
      this.get(id)
    })
  }



  listCronograma = new Array<Cronograma>()
  gerarCronograma() {
    const inicio = new Date(this._obra.dataInicio)
    const fim = new Date(this._obra.dataFim)
    const month = UseFullService.getMonthsDates(inicio, fim)

    let width = 0
    let primeiroDiaSemana: String
    for (let i = 0; i <= month; i++) {


      const newDate = new Date(this._obra.dataInicio)
      newDate.setMonth(inicio.getMonth() + i)

      const cronograma = new Cronograma()
      cronograma.data = UseFullService.getLastDayMonth(newDate)
      cronograma.dataBr = UseFullService.formatDate(newDate)
      cronograma.dia = newDate.getDate()
      cronograma.diaSemana = newDate.getDay()
      cronograma.diaMes = UseFullService.formatDateDiaMes(newDate)
      cronograma._diaSemana = Cronograma.getSemana(cronograma.diaSemana)
      cronograma.nomeSemaha = Cronograma.getSemana(cronograma.diaSemana, 1)
      cronograma._dias = ((1 + i) * 30)

      width += 40
      cronograma.width = width
      if (width == 40)
        primeiroDiaSemana = UseFullService.formatDate(cronograma.data)

      if (cronograma.diaSemana == 0) {
        cronograma.primeiroDiaSemana = primeiroDiaSemana
        width = 0
      }

      //aparecer qualquer dia se for o ultimo
      //if (i == days - 1) {
      //cronograma.ultimoDia = true
      //cronograma.primeiroDiaSemana = primeiroDiaSemana
      //}

      this.listCronograma.push(cronograma)
    }

    this.dividirLista()
  }

  listLinhaTempo = []
  dividirLista() {
    const limit = 10
    let qt = (this.listCronograma.length / 10)
    qt = (parseInt(String(qt)) < qt) ? Math.round(qt) + 1 : qt
    for (let i = 0; i < qt; i++) {
      this.listLinhaTempo[i] = new Array<Cronograma>()
      for (let x = 0; x < 10; x++) {
        const element = this.listCronograma[x + (i * 10)];
        if (element) {
          this.listLinhaTempo[i].push(element)
        }
      }
    }
    this.unirListaItensTempo()
    this.setAllPorcentagemCusto(this.listItensTempo)
  }

  setAllPorcentagemCusto(listItensTempo: any) {
    for (let i = 0; i < this.listItensTempo.length; i++) {
      for (let x = 0; x < this.listItensTempo[i].length; x++) {
        this.setPorcentagemCusto(this.listItensTempo[i][x])
      }
    }
  }

  listItensTempo = []
  unirListaItensTempo() {
    for (let i = 0; i < this.listLinhaTempo.length; i++) {
      this.listItensTempo.push(this.getItens())
      this.listItensTempo[i].forEach(c => { c._linhaTempo = this.getLinhaTempo(i); });
    }
  }

  getLinhaTempo(x): any {
    let data = []
    for (let i = 0; i < this.listLinhaTempo[x].length; i++) {
      const e = this.listLinhaTempo[x][i];
      const model = Object.create({})
      model.data = e.data
      model.dataBr = e.dataBr
      model.diaSemana = e.diaSemana
      model.diasMes = e.diaMes
      model.nomeSemaha = e.nomeSemaha
      model.primeiroDiaSemana = e.primeiroDiaSemana
      const year = String(e.data.getFullYear())
      model.monthYear = UseFullService.getMonthString(e.data.getMonth()) + '/' + year[2] + year[3]
      data.push(model)
    }
    return data
  }

  getItens(): any {
    const newList = []
    for (let x = 0; x < this.list.length; x++) {
      const e = this.list[x];
      const item = Object.create({})
      item.id = e.id
      item.codigo = e.codigo
      item.descricao = e.descricao
      item.valor = e.valor
      item.quantidade = e.quantidade
      item.valorTotal = e.valor * e.quantidade
      item._valorTotal = UseFullService.ToFixedString(item.valorTotal)
      item.planejamento = this._planejamentoItens.filter((o) => { return (o.relacaoPlanilhaOrcamentariaItemId === e.id) })[0]
      newList.push(item)
    }
    return newList
  }

  setPorcentagemCusto(item: any) {
    if (!item.planejamento)
      return
    if (item.planejamento.dataFim && item.planejamento.dataInicio) {
      const inicio = new Date(item.planejamento.dataInicio)
      const fim = new Date(item.planejamento.dataFim)
      for (let i = 0; i < item._linhaTempo.length; i++) {
        const e = item._linhaTempo[i];
        //mes inicio deve ser igual mes corrente linha do tempo.
        if (UseFullService.monthIsYearIsEqual(inicio, e.data)) {
          if (UseFullService.monthIsYearIsEqual(fim, e.data)) {

            item._linhaTempo[i].diasMes = item.planejamento.duracaoDias
            item._linhaTempo[i].porcetagem = "100"
          } else {
            const diasNoMes = UseFullService.getDaysDates(inicio, e.data)
            let total = 0
            for (let x = 0; x < diasNoMes; x++) {
              const tempDate = new Date(item.planejamento.dataInicio)
              tempDate.setDate(tempDate.getDate() + x)
              if (this.model.diasNaoUteis && !this.model.diasNaoUteis.match(String(tempDate.getDay())) && !Feriado.getPorData(this.feriados, tempDate))
                total++
            }
            item._linhaTempo[i].totalDiasMes = total
            const restante = item.planejamento.duracaoDias - total
            const porcentagem = (((item.planejamento.duracaoDias - restante) / item.planejamento.duracaoDias) * 100)
            item.porcentagemInicial = UseFullService.ToFixed(porcentagem)
            item._linhaTempo[i].porcetagem = item.porcentagemInicial
          }
        } else if (UseFullService.monthIsYearIsEqual(fim, e.data)) {
          const tempMesAnterior = new Date(e.data.getFullYear(), e.data.getMonth(), 1);
          const diasTotal = UseFullService.getDaysDates(tempMesAnterior, fim)
          let total = 0
          for (let x = 0; x < diasTotal; x++) {
            const tempDate = new Date(tempMesAnterior.toDateString())
            tempDate.setDate(tempMesAnterior.getDate() + (x + 1))
            if (this.model.diasNaoUteis && !this.model.diasNaoUteis.match(String(tempDate.getDay())) && !Feriado.getPorData(this.feriados, tempDate))
              total++
          }
          item._linhaTempo[i].totalDiasMes = total

          const restante = item.planejamento.duracaoDias - total
          const porcentagem = (((item.planejamento.duracaoDias - restante) / item.planejamento.duracaoDias) * 100)
          item.porcentagemFinal = UseFullService.ToFixed(porcentagem)
          item._linhaTempo[i].porcetagem = item.porcentagemFinal
        } else if (UseFullService.monthInBetween(inicio, fim, e.data)) {
          item.haPorcentagem = true
          item._linhaTempo[i].haPorcentagem = true
        }
      }

      if (item.haPorcentagem) {
        for (let i = 0; i < item._linhaTempo.length; i++) {
          if (item._linhaTempo[i].haPorcentagem) {
            const e = item._linhaTempo[i]
            const tempMesAnterior = new Date(e.data.getFullYear(), e.data.getMonth(), 1);
            const diasTotal = UseFullService.getDaysDates(tempMesAnterior, e.data)

            let total = 0
            for (let x = 0; x < diasTotal; x++) {
              const newDate = new Date(tempMesAnterior.toDateString())
              newDate.setDate(tempMesAnterior.getDate() + (x + 1))
              if (this.model.diasNaoUteis && !this.model.diasNaoUteis.match(String(newDate.getDay())))
                total++
            }
            item._linhaTempo[i].totalDiasMes = total

            const restante = item.planejamento.duracaoDias - total
            const porcentagem = (((item.planejamento.duracaoDias - restante) / item.planejamento.duracaoDias) * 100)
            item.porcentagemFinal = UseFullService.ToFixed(porcentagem)
            item._linhaTempo[i].porcetagem = item.porcentagemFinal
          }
        }
      }
    }
  }

}